import theAxios from 'axios';

/**
 * Wraps axios to add the auth token and handle sessions timing out
 * automagically
 */
export class AxiosWrapper {
  constructor() {
    this._instance = null;
    this.config = {
      // baseURL: '',
      headers: {
        'Authorization': '',
      },
    };
    this.token = localStorage.getItem('token');
  }

  set token(token) {
    this.config.headers.Authorization = `Bearer ${token}`;
    this._instance = theAxios.create(this.config);
  }

  get instance() {
    return this._instance;
  }

  get() {
    return this._instance.get.apply(this._instance, arguments)
      .catch(this.checkSessionExpired);
  }

  post() {
    return this._instance.post.apply(this._instance, arguments)
      .catch(this.checkSessionExpired);
  }

  delete() {
    return this._instance.delete.apply(this._instance, arguments)
      .catch(this.checkSessionExpired);
  }

  put() {
    return this._instance.put.apply(this._instance, arguments)
      .catch(this.checkSessionExpired);
  }

  patch() {
    return this._instance.patch.apply(this._instance, arguments)
      .catch(this.checkSessionExpired);
  }

  checkSessionExpired(error) {
    if (error.response && error.response.status === 403) {
      delete localStorage.token;
      alert('Your session has expired, please log in again.');
      window.location.href = "/password-login";
    }

    // Throwing the error again to fire other Catch handlers in the chain
    throw error;
  }
}

export const axios = new AxiosWrapper();

import React from 'react';
import SVGIcon from './SVGIcon'

class FilterBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      showOptions: false,
      isDesktop: false,
    };

    this.updatePredicate = this.updatePredicate.bind(this);
  }

  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 768 });
  }

  handleChange = e => {
    this.setState({ value: e.target.value });
  };

  handleSubmit = () => {
    this.props.handleSubmit(this.state.value, this.props.filter);
    this.setState({
      value: '',
      showOptions: false,
    });
  };

  handleOptionSelect = (opt) => {
    this.props.handleSubmit(opt.name, this.props.filter);
    this.setState({
      value: '',
      showOptions: false,
    });
  }

  render() {
    const { value, showOptions } = this.state;
    return (
      <div className="filter-box">
        <span className="filter-label base-label-large">{this.props.label.toUpperCase()}</span>
        <div className="form-input">
        {this.props.label == 'When' ?
          (<input
            value={value}
            onChange={this.handleChange}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                this.handleSubmit();
              }
            }}
            placeholder={ (!!this.state.isDesktop ? 'Select Years(?)' : this.props.label)}
            type="date"
            />
          ):(
          <div>
          <input
            value={value}
            onChange={this.handleChange}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                this.handleSubmit();
              }
            }}
            onClick={() => this.setState(prevState => ({ showOptions: !prevState.showOptions }))}
            placeholder={ (!!this.state.isDesktop ? 'Filter by ' : '') + this.props.label}
          />
          <a onClick={this.handleSubmit}>
            <SVGIcon id="plus" color="dark-grey"/>
          </a>
          </div>
          )}
        </div>
        {this.props.options && this.props.options.length > 1 && showOptions && (
          <div className="form-dropdown">
            <div className="dropdown-menu no-hide scroll-overflow">
              {this.props.options.map((option) => {
                if (option.name.toLowerCase().includes(value.toLowerCase()) || !value) {
                  return (
                    <button
                      key={option.id}
                      onClick={() => this.handleOptionSelect(option)}
                      className="dropdown-item"
                    >
                      {option.name}
                    </button>
                  );
                }
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default FilterBox;

import { axios } from '../lib/axios';
import {
  FETCH_MARKIVE_BEGIN,
  FETCH_MARKIVE_SUCCESS,
  FETCH_MARKIVE_FAILURE,
} from "../constants/action-types";

// FETCH MARKIVE DATA
export function fetchMarkiveData(id) {
  return dispatch => {
    dispatch(fetchMarkiveDataBegin());

    axios
      .get("/api/markive/" + id)
      .then(res => {
        if(res && res.data && res.data.data) {
          dispatch(fetchMarkiveDataSuccess(res.data.data));
        }
      })
      .catch(err => {
        dispatch(fetchMarkiveDataFailure(err));
      });
  };
}

export function fetchMarkiveDataBegin() {
  return {
    type: FETCH_MARKIVE_BEGIN
  };
}

export function fetchMarkiveDataSuccess(markive) {
  return {
    type: FETCH_MARKIVE_SUCCESS,
    markive: markive
  };
}

export function fetchMarkiveDataFailure(error) {
  return {
    type: FETCH_MARKIVE_FAILURE,
    error: error
  };
}

export default {
  fetchMarkiveData,
};

import { axios } from '../lib/axios';

export async function deleteMarkive(id, showAlert = true) {
  return await axios
    .delete('/api/markives/'+ id)
    .then(async resp => {
      if(resp && (resp.data.status == 200)) {
        if (showAlert) {
          alert('This Markive has been deleted, you are now being redirected back to the search page');
        }
      } else {
        delete localStorage.token;
        alert('Your session has expired, please log in again.');
        window.location.href = "/password-login";
      }
    })
    .catch(err => {
      console.error(err)
    })
}

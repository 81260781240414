import {
  FETCH_CATEGORIES_BEGIN,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAILURE,

  FETCH_YEARS_BEGIN,
  FETCH_YEARS_SUCCESS,
  FETCH_YEARS_FAILURE,

  FETCH_PEOPLE_BEGIN,
  FETCH_PEOPLE_SUCCESS,
  FETCH_PEOPLE_FAILURE,

  FETCH_TAGS_BEGIN,
  FETCH_TAGS_SUCCESS,
  FETCH_TAGS_FAILURE,

  FETCH_SUPERTAGS_BEGIN,
  FETCH_SUPERTAGS_SUCCESS,
  FETCH_SUPERTAGS_FAILURE,

  FETCH_LOCATIONS_BEGIN,
  FETCH_LOCATIONS_SUCCESS,
  FETCH_LOCATIONS_FAILURE,
} from '../constants/action-types';

const initialState = {
  categories: [],
  years: [],
  tags: [],
  people: [],
  superTags: [],
  locations: [],
  loadingCategories: false,
  loadingPeople: false,
  loadingTags: false,
  loadingSuperTags: false,
  loadingLocations: false,
};

// Reducer will comprise of the auto complete stuff here since they share the same store
export default function suggestionTagData(state = initialState, action) {
  switch (action.type) {
    // CATEGORIES REDUCER
    case FETCH_CATEGORIES_BEGIN:
      return {
        ...state,
        loadingCategories: true,
        error: null,
      };
    case FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.categories
          .map(i => ({id: i.id.toString(), name: i.name}))
          .sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase())),
      };
    case FETCH_CATEGORIES_FAILURE:
      return {
        ...state,
        loadingCategories: false,
        categories: action.error,
      };

    // YEARS REDUCER
    case FETCH_YEARS_BEGIN:
      return {
        ...state,
        loadingYears: true,
        error: null,
      };
    case FETCH_YEARS_SUCCESS:
      return {
        ...state,
        years: action.years
          .map(i => ({period: i.period, count: i.count})),
      };
    case FETCH_YEARS_FAILURE:
      return {
        ...state,
        loadingYears: false,
        years: action.error,
      };

    // PEOPLE REDUCER
    case FETCH_PEOPLE_BEGIN:
      return {
        ...state,
        loadingPeople: true,
        error: null,
      };
    case FETCH_PEOPLE_SUCCESS:
      return {
        ...state,
        people: action.people.map(i => ({id: i.id.toString(), name: i.name})),
      };
    case FETCH_PEOPLE_FAILURE:
      return {
        ...state,
        loadingPeople: false,
        people: action.error,
      };
    case FETCH_TAGS_BEGIN:
      return {
        ...state,
        loadingTags: true,
        error: null,
      };
    case FETCH_TAGS_SUCCESS:
      return {
        ...state,
        tags: action.tags.map(i => ({id: i.id.toString(), name: i.name})),
      };
    case FETCH_TAGS_FAILURE:
      return {
        ...state,
        loadingTags: false,
        tags: action.error,
      };
    case FETCH_SUPERTAGS_BEGIN:
      return {
        ...state,
        loadingSuperTags: true,
        error: null,
      };
    case FETCH_SUPERTAGS_SUCCESS:
      return {
        ...state,
        superTags: action.superTags.map(i => ({id: i.id.toString(), name: i.name})),
      };
    case FETCH_SUPERTAGS_FAILURE:
      return {
        ...state,
        loadingSuperTags: false,
        superTags: action.error,
      };
    case FETCH_LOCATIONS_BEGIN:
      return {
        ...state,
        loadingLocations: true,
        error: null,
      };
    case FETCH_LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: action.locations.map(i => ({id: i.id.toString(), name: i.location})),
      };
    case FETCH_LOCATIONS_FAILURE:
      return {
        ...state,
        loadingLocations: false,
        locations: action.error,
      };
    default:
      return state;
  }
}

import React from 'react';
import SVGIcon from './SVGIcon';

var TagSingle = props => {
  var className = 'tag-single';
  if (props.name === '+') {
    className += ' add';
  }
  if (props.withClose === true) {
    className += ' with-close';
  }
  return (
    <a onClick={props.handleClick}>
      <div className={className}>
      {props.name === '+' ?
        <span style={{width: '14px', height: '14px'}}><SVGIcon id="plus" color="dark-grey"/></span> :
        <span>{props.name}</span>
      }
        {props.withClose && <SVGIcon id="close" color="dark-grey"/>}
      </div>
    </a>
  );
};

export default TagSingle;

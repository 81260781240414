import {
  AUTO_COMPLETE_CHANGE,
  AUTO_COMPLETE_LNG,
  AUTO_COMPLETE_LAT,
} from "../constants/action-types";

// This is to handle address state change
export function autoCompleteChange(address) {
  return {
    type: AUTO_COMPLETE_CHANGE,
    address
  };
}

export function autoCompleteLng(lng) {
  return {
    type: AUTO_COMPLETE_LNG,
    lng
  };
}

export function autoCompleteLat(lat) {
  return {
    type: AUTO_COMPLETE_LAT,
    lat
  };
}

export default {
  autoCompleteChange,
  autoCompleteLng,
  autoCompleteLat,
};

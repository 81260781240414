import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router";
import Modal from "react-modal";
import { loadApp } from "../actions/app";
import { CloudinaryContext } from 'cloudinary-react';

import LoadState from "../components/LoadState";

import Home from "./pages/Home";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import GoogleAuth from "./pages/GoogleAuth";
import GoogleAuthSignUp from "./pages/GoogleAuthSignUp";
import Dashboard from "./pages/Dashboard";
import CreateMarkive from "./pages/CreateMarkive";
import DeleteMarkive from "./pages/DeleteMarkive";
import EditMarkive from "./pages/EditMarkive";
import ShareMarkive from "./pages/ShareMarkive";
import LinkMarkive from "./pages/LinkMarkive";
import LinkMultipleMarkives from "./pages/LinkMultipleMarkives";
import QuickSearch from "./pages/QuickSearch";
import Markive from "./pages/Markive";
/* import PublicMarkive from "./pages/PublicMarkive"; */
import MultipleShare from "./pages/MultipleShare";
import Search from "./pages/Search";
import Terms from './pages/Terms';
import PrivacyPolicy from "./pages/PrivacyPolicy";

import styles from "../styles/styles.scss";
import 'react-image-lightbox/style.css';
/* import PublicSingleMarkive from "./pages/PublicSingleMarkive"; */

const FourOhFour = () => <h1> 404 Not Found </h1>;

Modal.setAppElement("#react");

const modalStyle = {
  content: {
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    padding: "0"
  }
};

export class AppContainer extends Component {
  componentDidMount() {
    this.props.dispatch(loadApp());
  }

  render() {
    if (!this.props.loaded) {
      return null;
    }

    return (
      <CloudinaryContext
        cloudName={CLOUD_NAME}
        uploadPreset={UPLOAD_PRESET}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/password-login" component={Login} />
          <Route path="/google-auth/:token?" component={GoogleAuth} />
          <Route path="/google-auth-sign-up" component={GoogleAuthSignUp} />
          <Route path="/sign-up" component={SignUp} />
          {/* <Route path="/dashboard" component={Dashboard} /> */}
          <Route path="/search" component={Search} />
          <Route path="/terms" component={Terms} />
          <Route path="/privacy" component={PrivacyPolicy} />
          <Route path="/create-markive" component={CreateMarkive} />
          <Route path="/markives/create" component={Dashboard} />
          <Route path="/markives/search" component={Dashboard} />
          <Route path="/markive/:id" component={Markive} />
          <Route path="/share/:token/:id" component={Markive} />
          <Route path="/share/:token" component={Markive} />
          <Route path="/ms/:token" component={MultipleShare} />
          <Route path="/mshare/:token/:id" component={Markive} />
          <Route component={FourOhFour} />
        </Switch>
        <LoadState />
        {this.props.isModalVisible && (
          <Modal
            isOpen={this.props.isModalVisible !== false}
            style={modalStyle}
            contentLabel=""
          >
            {this.props.modalType === "createMarkiveModal" ? (
              <CreateMarkive {...this.props} />
            ) : this.props.modalType === "findMarkiveModal" ? (
              <QuickSearch {...this.props} />
            ) : this.props.modalType === "deleteMarkiveModal" ? (
              <DeleteMarkive {...this.props} />
            ) : this.props.modalType === "editMarkiveModal" ? (
              <EditMarkive {...this.props} />
            ) : this.props.modalType === "shareMarkiveModal" ? (
              <ShareMarkive {...this.props} />
            ) : this.props.modalType === "shareLinkModal" ? (
              <LinkMarkive {...this.props} />
            ) : this.props.modalType === "multipleShareLinkModal" ? (
              <LinkMultipleMarkives {...this.props} />
            ) : null}
          </Modal>
        )}
      </CloudinaryContext>
    );
  }
}

function mapStateToProperties(state) {
  return {
    loaded: state.app.loaded,
    isModalVisible: state.app.isModalVisible,
    modalType: state.app.modalType
  };
}

export default connect(mapStateToProperties)(AppContainer);

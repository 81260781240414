import React, { Component } from 'react';
import { connect } from 'react-redux';
import { datePlaceholder } from '../actions/app';
import IconButton from './IconButton';

class SearchBox extends Component {
  state = {
    clickOnWhat: false,
    mouseOver: false,
  }
  onFocusDate = (date) => {
    this.props.dispatch(datePlaceholder(date));
  };

  render() {
    return (
      <form onSubmit={this.props.handleSubmit} className="search-form">
        <div className="search-box dash">
          <div className="search-box-container">
            <div>
              <p className="search-label">Find</p>
              <input
                className="search-input-field"
                autoFocus={false}
                autoComplete="off"
                value={this.props.category}
                name="category"
                onChange={this.props.handleChange}
                onBlur={() => this.setState({ clickOnWhat: this.state.mouseOver })}
                onClick={() => this.setState({ clickOnWhat: true })}
                placeholder="What" />
              { (this.state.clickOnWhat) && (
                <div
                  className="search-recommendations"
                  onMouseEnter={() => this.setState({ mouseOver: true })}
                  onMouseLeave={() => this.setState({ mouseOver: false })}
                >
                  <div className="autofill-box">
                    {this.props.categoriesSuggestions.map((cat, index) => {
                      if (this.props.category === '' || cat.name.toLowerCase().includes(this.props.category.toLowerCase())) {
                        return (
                          <button
                            onClick={() => {
                              this.setState({ clickOnWhat: false, mouseOver: false });
                              this.props.handleChange(cat.name, true);
                            }}
                            className="search-autofill"
                            key={`recommended-${index}`}
                            type="button"
                          >
                            {cat.name}
                          </button>
                        )
                      }
                    })}
                    <div className="autofill-no-results">
                      {this.props.categoriesSuggestions.length > 0
                        ? 'No Results Found. Please choose an existing category.'
                        : 'You haven\'t created any categories yet!'
                      }
                    </div>
                  </div>

                </div>
              )}
            </div>
            <div>
              <p className="search-label">Or</p>
              <input
                className="search-input-field"
                value={this.props.term}
                name="term"
                onChange={this.props.handleChange}
                placeholder="Title or Notes"
              />
            </div>
          </div>
        </div>
        {/* <div className="search-actions">
          <IconButton icon="find" iconColor="white" onClick={this.props.handleSubmit}>
            FIND
          </IconButton>
        </div> */}
      </form>
    );
  }
};

function mapStateToProperties(state) { return {
  categoriesSuggestions: state.suggestionTagData.categories,
}; }

export default connect(mapStateToProperties)(SearchBox);

import React from 'react';

export const CheckBox = props => {
  return(
    <div className="row">
      <div className="col-md-1 col-sm-2 col-xs-3">
        <input
          key={props.id}
          name={props.name}
          type="checkbox"
          onClick={props.handleCheckChieldElement}
          checked={props.isChecked}
          value={props.value}
        />
      </div>
      <div className="col-md-10 col-sm-9 col-xs-8">
        <p className="form-label">{props.value}</p>
      </div>
    </div>
  )
};

export default CheckBox;

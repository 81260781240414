import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {locate as locateAction, showModal, updateModalMarkive} from "../actions/app";

import {connect} from "react-redux";
import {deleteSharedMarkive, addSharedMarkive} from "../actions/shareMultipleMarkivesAction";

class SearchEntryCard extends React.Component {
  state = {
    value: '',
    showMenu: false,
  };

  showMenu = () => {
    this.setState(prevState => ({
      showMenu: !prevState.showMenu
    }))
  };

  checkMultipleShare = () => {
    var url = window.location.href;
    if (url.indexOf("/ms/") > -1) {
      var arr = url.split("/");
      return arr[arr.length - 1];
    }
    return false;
  }

  toggleCheckbox = (id) => {
    if (this.isChecked(id)) {
      this.props.dispatch(deleteSharedMarkive(id));
    } else {
      this.props.dispatch(addSharedMarkive(id));
    }
  }
  isChecked = (id) => {
    return this.props.cbArray.includes(id);
  }

  showModal = (type, id) => {
    this.setState({
      showMenu: false
    });
    this.props.dispatch(showModal(type));
    this.props.dispatch(updateModalMarkive(id));
  };

  render(){
    const { date, id, title, images, thumbnail_url, row, hideCardMenu, hideLinks, openLinkedMarkive, shareToken } = this.props;
    var splitDate = date ? date.split('T')[0] : new Date();
    var dateString = moment(splitDate).utc().format('MMMM D YYYY')
    var dateArr = dateString.split(' ');

    var imageUrl = '';
    if(images && images[0] && images[0].url) {
      imageUrl = thumbnail_url;
    } else {
      imageUrl = null;
    }

    return (
      <div className="search-result-single">
        <div className={` ${ row ? "col-xs-6" : "col-xs-12"} col-sm-6 col-md-4 col-lg-3 search-result-col-card`}>
          <div className="search-result-box what" style={{backgroundImage: `url('${imageUrl}')`}}>
            {(this.props.showMarkivesCheckboxes) &&
            <div onClick={() => this.toggleCheckbox(id)}
                 className={`search-result-card-checkbox ${this.isChecked(id) ? "checked" : ""}`} />
            }
            { (!hideCardMenu) &&
              <div className="search-result-card-menu" onClick={() => this.showMenu()} />
            }
            
            { (this.state.showMenu) && (
              <div className="search-result-card-menu-list">
                <a onClick={() => this.showModal('editMarkiveModal',id)}>Edit</a>
                <a onClick={() => this.showModal('shareMarkiveModal', id)}>Share</a>
                <a onClick={() => this.showModal('deleteMarkiveModal', id)} style={{color: `#F03D3E`}}>Delete</a>
              </div>
            )}
            {!this.checkMultipleShare() ?
              (
                hideLinks ?
                  (openLinkedMarkive ? <Link to={{pathname: `/share/${shareToken}/${id}`,}}>
                    <div className="search-result-card-button" />
                  </Link> : <div className="search-result-card-button" />)
                :
                <Link to={{pathname: '/markive/' + id,}}>
                  <div className="search-result-card-button" />
                </Link>
              ) : (
                <Link to={{pathname: '/mshare/' + this.checkMultipleShare() + '/' + id,}}>
                  <div className="search-result-card-button" />
                </Link>
              )
            }
            
            <div className="search-result-card-content">
              <h2 className="search-result-card-text">{title}</h2>
              <div className="search-result-card-date">{dateArr[0]} {dateArr[2]}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

function mapStateToProperties(state) {
  return {
    showMarkivesCheckboxes: state.shareMultipleMarkives.showMarkivesCheckboxes,
    cbArray: state.shareMultipleMarkives.cbArray
  };
}

function mapDispatchToProps(dispatch) {
  return {
    locate: () => dispatch(locateAction()),
    dispatch
  };
}

export default connect(
  mapStateToProperties,
  mapDispatchToProps,
)(SearchEntryCard);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import backgroundimage from '../../styles/assets/images/hero-image.jpg';
import Modal from 'react-modal';
import GoogleAuth from '../../containers/pages/GoogleAuth';
import SignUp from '../../containers/pages/SignUp';
import GetStarted from '../../components/GetStarted';

Modal.setAppElement('#react');

const modalStyle = {
  content: {
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    padding: '0'
  }
};

class Home extends Component {
  state = {
    showModal: false,
    status: window.localStorage.token
  };

  componentDidMount() {
    var token = window.localStorage.token;

    if(token) {
      this.props.history.push('/search?orderby=date&order=ASC');
    } else {
      return;
    }
  }

  UNSAFE_componentWillMount() {
    // The if condition might be needing to go to google-auth?
    if (this.props.location.pathname === '/google-auth') {
      this.setState({
        showModal: 'login'
      });
    }
  }

  showModal(type) {
    this.setState({
      showModal: type
    });
  }

  render() {
    return (
      <div>
        <img className="home-hero-image" src={backgroundimage} />
        <Header {...this.props} />
          <div className="home-title">
            <h1>
              Your personal archive
            </h1>

            <GetStarted handleClick={this.showModal.bind(this, 'login')} />
            <Modal
              isOpen={this.state.showModal !== false}
              onRequestClose={this.showModal.bind(this, false)}
              style={modalStyle}
              contentLabel=""
            >
              {this.state.showModal === 'login' ? (
                <GoogleAuth {...this.props} onRequestClose={this.showModal.bind(this, false)} />
              ) : this.state.showModal === 'signup' ? (
                <SignUp {...this.props} onRequestClose={this.showModal.bind(this, false)} />
              ) : null}
            </Modal>
          </div>

        <div className="home-features">
          <div className="home-features-content">
            <div className="row">
              <div className="col-md-3">
                <h2>Add</h2>
              </div>
              <div className="col-md-3">
                <h2>Find</h2>
              </div>
              <div className="col-md-3">
                <h2>Sort</h2>
              </div>
              <div className="col-md-3">
                <h2>Share</h2>
              </div>
            </div>
          </div>
        </div>
        <footer className="footer">
          <Link to="/terms">Terms of use</Link>
          <Link to="/privacy">privacy policy</Link>
        </footer>
      </div>
    );
  }
}

function mapStateToProperties(state) {
  return {};
}

export default connect(mapStateToProperties)(Home);

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { authLogout, showModal } from "../actions/app";
import Logo from "../styles/assets/images/logoBlack";
import { axios } from "../lib/axios";
import IconButton from "../components/IconButton";

class Header extends Component {
  state = {
    token: window.localStorage.token,
    menuState: 'closed'
  };

  // Action
  showModal = type => {
    this.props.dispatch(showModal(type));
  };

  handleLogout = () => {
    axios
      .post("/api/logout")
      .then(resp => {
        if (resp.data.status !== 200) {
          return;
        }
        this.props.dispatch(authLogout());
        alert("You have successfully logged out!");
      })
      .catch(err => {
        console.log(err);
      });

    // Depending on what Mark wants here... I'm just gonna make this easier for myself and leave it as Google Auth...
  };

  openMenu = () => {
    this.setState({
      menuState: 'open'
    });
  }

  render() {
    return (
      <div>
        <nav className="header-wrapper">
          {!!this.state.token && (
            <button onClick={this.showModal.bind(this, 'createMarkiveModal')} className="header-mobile-add">
              <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.99973 0.773438V9.50071M9.99973 18.228V9.50071M9.99973 9.50071H18.727M9.99973 9.50071H1.27246" stroke="#4294F4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </button>
          )}
          <div className="header-logo">
            {this.state.token ? (
              <Link to="/search?orderby=date&order=ASC">
                <Logo />
                <p className="logo-subtitle">archive your life</p>
              </Link>
            ) : (
              <a href="/">
                <Logo />
                <p className="logo-subtitle">archive your life</p>
              </a>
            )}
          </div>
          
          {
            this.state.token ? (
              <ul className={`header-actions ${this.state.menuState}`}>
                {/* <li>
                  <IconButton onClick={this.showModal.bind(this, "createMarkiveModal")} icon="add" tooltip="Add" tooltipPos="bottom"  hoverColor="blue"/>
                </li>
                <li>
                  <IconButton onClick={()=> {this.props.history.push('/search?orderby=date&order=ASC')}} icon="find" tooltip="Find" tooltipPos="bottom"  hoverColor="blue"/>
                </li> */}
                <li>
                  <IconButton onClick={this.handleLogout} icon="exit" tooltip="Exit" tooltipPos="bottom"  hoverColor="blue"/>
                  <IconButton onClick={this.openMenu} icon="mobile-menu" btnColor="white" iconColor="grey" />
                </li>
              </ul>
            ) : <div />
          }
        </nav>
      </div>
    );
  }
}

function mapStateToProperties(state) {
  return {
    isModalVisible: state.app.isModalVisible,
    modalType: state.app.modalType
  };
}

export default connect(mapStateToProperties)(Header);

import React from 'react';

var logoWhite = () => {
  return (
    <svg width="462px" height="65px" viewBox="0 0 462 65" version="1.1">
      <defs>
        <polygon id="path-1" points="0 65 461.243031 65 461.243031 0.333357761 0 0.333357761" />
      </defs>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Search-Page-(From-Home)-Copy" transform="translate(-489.000000, -328.000000)">
          <g id="Markive-Logo_White-+-Light-Blue" transform="translate(489.000000, 328.000000)">
            <g id="Markive-Logo">
              <polygon
                id="Fill-1"
                fill="#FFFFFF"
                points="51.3226381 0 31.254902 43.6705352 11.8787879 0 11.8787879 0 0 60.4125634 8.80926916 60.4125634 14.8770053 26.46 31.1051693 63 47.8146168 26.495493 53.258467 60.4125634 62 60.4125634 51.3262032 0"
              />
              <g id="Group-11">
                <path
                  d="M182.266463,30.3741752 L184.963832,30.3741752 C193.002632,30.3741752 197.025586,27.3025896 197.025586,21.1594184 C197.025586,15.4068534 193.112802,12.5341219 185.290786,12.5341219 L182.266463,12.5341219 L182.266463,30.3741752 Z M192.835602,36.8156276 L210.253002,60.7775463 L199.865109,60.7775463 L183.798171,37.7637355 L182.266463,37.7637355 L182.266463,60.7775463 L173.772771,60.7775463 L173.772771,4.55510127 L183.72354,4.55510127 C191.158186,4.55510127 196.528048,5.95418188 199.829571,8.74524117 C203.475817,11.8523364 205.295386,15.9537022 205.295386,21.0528894 C205.295386,25.0335223 204.154602,28.4566536 201.873032,31.3222831 C199.587909,34.1843618 196.574248,36.0166602 192.835602,36.8156276 Z"
                  id="Fill-2"
                  fill="#FFFFFF"
                />
                <polygon
                  id="Fill-4"
                  fill="#FFFFFF"
                  points="249.588392 26.9861985 271.490746 4.55829714 282.823962 4.55829714 257.385531 30.0116216 282.969669 60.7771912 271.597362 60.7771912 251.336885 35.7641865 249.588392 37.5112618 249.588392 60.7771912 241.0947 60.7771912 241.0947 4.55829714 249.588392 4.55829714"
                />
                <g id="Clip-7" />
                <polygon
                  id="Fill-6"
                  fill="#FFFFFF"
                  points="312.933923 60.7743504 321.427615 60.7743504 321.427615 4.55545636 312.933923 4.55545636"
                />
                <polygon
                  id="Fill-8"
                  fill="#FFFFFF"
                  points="360.180886 4.55652165 375.739625 43.5177209 391.518702 4.55652165 400.812009 4.55652165 375.554825 64.9975143 350.887578 4.55652165"
                />
                <polygon
                  id="Fill-9"
                  fill="#4294F4"
                  points="133.456518 60.7757708 117.89778 21.8145716 102.118703 60.7757708 92.8253954 60.7757708 118.08258 0.334778148 142.749826 60.7757708"
                />
                <polygon
                  id="Fill-10"
                  fill="#FFFFFF"
                  points="461.243386 12.5323464 438.687125 12.5323464 438.687125 26.0402212 460.589478 26.0402212 460.589478 34.0156909 438.687125 34.0156909 438.687125 52.8038521 461.243386 52.8038521 461.243386 60.7757708 430.196986 60.7757708 430.196986 4.55687675 461.243386 4.55687675"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default logoWhite;

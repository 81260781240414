import { axios } from '../lib/axios';

export async function postNewTag(tag) {
  return await axios
    .post('/api/tags', tag)
    .then(async resp => {
      return resp.data.data.id;
    })
}

export async function linkTagToMarkive(tagData) {
  await axios.post('/api/tags/update', tagData);
}

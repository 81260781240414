import React, { Component, useEffect, useRef } from 'react';
import ReactTags from 'react-tag-autocomplete';

// root: 'react-tags',
// rootFocused: 'is-focused',
// selected: 'react-tags__selected',
// selectedTag: 'react-tags__selected-tag',
// selectedTagName: 'react-tags__selected-tag-name',
// search: 'react-tags__search',
// searchWrapper: 'react-tags__search-wrapper',
// searchInput: 'react-tags__search-input',
// suggestions: 'react-tags__suggestions',
// suggestionActive: 'is-active',
// suggestionDisabled: 'is-disabled',
// suggestionPrefix: 'react-tags__suggestion-prefix'

const Tags = (props) => {

  const onValidate = (tag) => {
    return tag.name.length > 0;
  }

  return (
    <div>
      <ReactTags
        classNames={{
          root: 'base-suggestion-tag-item',
          searchInput: 'base-suggestion-tag-input-field',
          search: 'base-suggestion-tag-input-wrapper',
          selectedTag: 'ReactTags__tag',
          selected: 'react-tags__selected',
          suggestions: 'base-suggestion-tag-suggestion',
          suggestionPrefix: 'base-suggestion-new'
        }}
        placeholderText={''}
        onBlur={props.tagAddition}
        onValidate={onValidate}
        labelField={'name'}
        addOnBlur
        {...props}
      />
    </div>
  );
};

function mapStateToProperties(state) {
  return {};
}

export default Tags;

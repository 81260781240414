import React, { Component } from 'react';
import LogoWhite from '../../styles/assets/images/logoWhite';
import IconButton from '../../components/IconButton';
import Checkbox from '../../components/Checkbox';

// REDUX STUFF HERE
import { connect } from 'react-redux';
import { showModal } from '../../actions/app';
import { fetchMarkiveToken, postCheckbox } from '../../actions/shareMarkiveAction';

class ShareMarkive extends Component {
  state = {
    cbShare: [
      {id: 1, name: 'cbDate', isChecked: true, value: 'SHARE DATE?'},
      {id: 2, name: 'cbTitle', isChecked: true, value: 'SHARE TITLE?'},
      {id: 3, name: 'cbAddress', isChecked: true, value: 'SHARE ADDRESS?'},
      {id: 4, name: 'cbUrl', isChecked: true, value: 'SHARE URL?'},
      {id: 5, name: 'cbCategories', isChecked: true, value: 'SHARE CATEGORIES?'},
      {id: 6, name: 'cbPeople', isChecked: true, value: 'SHARE PEOPLE?'},
      {id: 6, name: 'cbSuperTags', isChecked: true, value: 'SHARE SUPERTAG?'},
      {id: 7, name: 'cbImages', isChecked: true, value: 'SHARE IMAGES?'},
      {id: 8, name: 'cbNotes', isChecked: true, value: 'SHARE NOTES?'},
    ],
    token: window.localStorage.token,
  };

  // NOT SURE WHAT THIS DOES...
  componentDidMount() {
    this.refs.header.scrollIntoView();
    this.handleAllTrue();
  }

  handleAllTrue = () => {
    let cbShare = this.state.cbShare;
    cbShare.forEach(cb => {
      this.props.dispatch(postCheckbox(cb.name, cb.isChecked));
    })
  }
  // SHARE LINK MODAL POP UP
  handleShareLink = (type) => {
    this.props.dispatch(showModal(type));
  }

  // HANDLER FOR THE CHECKBOXES
  handleCheckChieldElement = (event) => {
    let cbShare = this.state.cbShare
    cbShare.forEach(cb => {
      if (cb.value === event.target.value)
        cb.isChecked =  event.target.checked
        this.props.dispatch(postCheckbox(event.target.name, event.target.checked));
    })
    this.setState({cbShare: cbShare})
  }

  // TOGGLE SELECT ALL / CLEAR ALL
  handleAllChecked = (event) => {
    let cbShare = this.state.cbShare;
    cbShare.forEach(cb => {
      cb.isChecked = event.target.checked
      this.props.dispatch(postCheckbox(cb.name, event.target.checked));
    });
    this.setState({cbShare: cbShare});
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const {
      modalMarkive,
    } = this.props;
    var data = {
      // Reason for the lat and lng having the same value is because they are tied to the address...
      address: this.props.address,
      latitude: this.props.address,
      longitude: this.props.address,
      title: this.props.title,
      date: this.props.date,
      url: this.props.url,
      notes: this.props.notes,
      categories: this.props.categories,
      tags: this.props.tags,
      people: this.props.people,
      supertag: this.props.superTags,
      images: this.props.images,
    };

    // TOO LAZY TO LEARN REG EX ATM...
    if (modalMarkive) {
      var markiveId = modalMarkive;
    } else {
      var url = window.location.href;
      var arr = url.split("/");
      var markiveId = arr[arr.length - 1];
    }

    this.props.dispatch(fetchMarkiveToken(data, markiveId))
    this.props.dispatch(showModal(false));

    this.handleShareLink('shareLinkModal');
  };

  render() {
    return (
      <div className="page-create-markive">
        <div className="header" ref="header">
        {this.state.token ? (
          <a className="header-home-link" href="/search?orderby=date&order=ASC" >
            <LogoWhite />
          </a>
        ) : (
          <a className="header" href="/" >
            <LogoWhite />
          </a>
        )}
          <IconButton 
            onClick={this.props.dispatch.bind(this, showModal(false))} 
            className="modal-close"
            icon="close"
            iconColor="dark-grey" 
            btnColor="transparent"
          />
        </div>

        <div className="create-markive-wrapper">
          <div className="container content">
            <h1 className="form-label edit-headline share-title">SHARE MARKIVE</h1>
            <div className="share-list-wrapper">
              <form autoComplete="off">
                <div className="row">
                  <div className="col-md-1 col-sm-2 col-xs-3">
                      <input type="checkbox" onClick={this.handleAllChecked} value="checkedall" />
                  </div>
                  <div className="col-md-10 col-sm-9 col-xs-8">
                    <p className="form-label share-markive-all">SELECT ALL / CLEAR ALL</p>
                  </div>
                </div>
                {this.state.cbShare.map((share, i) =>
                  (<Checkbox key={i} handleCheckChieldElement={this.handleCheckChieldElement} {...share} />)
                )}
                <button className="pull-right submit-button save-button" type="button" onClick={this.handleSubmit}>
                  Share
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProperties(state) {
  return {
    modalMarkive: state.app.modalMarkive,
    address: state.shareMarkive.cbAddress,
    title: state.shareMarkive.cbTitle,
    date: state.shareMarkive.cbDate,
    url: state.shareMarkive.cbUrl,
    notes: state.shareMarkive.cbNotes,
    images: state.shareMarkive.cbImages,
    categories: state.shareMarkive.cbCategories,
    people: state.shareMarkive.cbPeople,
    superTags: state.shareMarkive.cbSuperTags
  };
}

export default connect(mapStateToProperties)(ShareMarkive);

import React from 'react';
import Header from '../../components/Header';

const Terms = () => {
  return (
    <div>
      <Header />
      <div className="terms text-page">
        <h2>WEBSITE TERMS OF SERVICE</h2>
        <p>
          Markive.com (the “Website”) is owned and operated by MX2 Ventures, LLC (“Company”, “we”, “our” or “us”). The
          following terms and conditions, together with any documents they expressly incorporate by reference, govern
          your access to and use of the Website, including any content, functionality and services it offers (the
          “Services”), whether as a guest or registered user.
        </p>
        <strong>1. Acceptance of Terms of Service</strong>
        <p>
          Please read these Terms of Service carefully before using the Website and using the Services. These Terms of
          Service set forth the legally binding terms and conditions for your use of the Website. By using the Website
          in any manner, you accept and agree to be bound and abide by these Terms of Service and our Privacy Policy,
          which is incorporated herein by reference. If you do not agree to these Terms of Service or the Privacy
          Policy, you are not permitted to access or use the Website.
        </p>
        <strong>2. Changes and Modifications</strong>
        <p>
          The Company reserves the right, at our sole discretion, to modify or replace these Terms of Service by posting
          updated terms on the Website. Your continued use of the Website after any such changes constitutes your
          acceptance of the new Terms of Service. Please review the Website periodically for changes. If you do not
          agree to any or all posted changes, you must discontinue using the Website.
        </p>
        <strong>3. Accessing the Website and Account Security</strong>
        <p>
          We will do our best to ensure that the Website is operational at all times, except for periodic down times
          relating to updates and maintenance. We will not be liable if for any reason the Website is unavailable at any
          time or for any period. You may contact us at the e-mail listed below for customer support issues and will
          respond within a reasonable time period to all service and help requests. We cannot be held responsible for
          Internet outages, faulty connections, or other connectivity issues beyond our control.
        </p>
        <p>
          To access the Website or the Services it offers, you may be asked to provide certain registration details or
          other information. It is a condition of your use of the Website that all information you provide is correct,
          current, and complete. You agree that all information you provide to register with the Company is governed by
          our Privacy Policy and you consent to all actions we take with respect to your information consistent with our
          Privacy Policy.
        </p>
        <p>
          We have the right to disable any e-mail account, user name, password or other identifier at any time in our
          sole discretion for any or no reason, including if, in our opinion, you have violated any provision of these
          Terms of Service.
        </p>
        <strong>4. External Links and Content</strong>
        <p>
          The Website may contain links to other sites and resources provided by third parties. These links are provided
          for your convenience only and include links contained in advertisements, including banner advertisements and
          sponsored links. We have no control over the contents of those sites or resources, and accept no
          responsibility for them or for any loss or damage that may arise from your use of them. If you decide to
          access any of the third-party websites linked to the Website, you do so entirely at your own risk and subject
          to the terms and conditions of use for such websites.
        </p>
        <strong>5. User Age Requirement</strong>
        <p>
          In compliance with the requirements of Children’s Online Privacy Protection Act (COPPA), we do not collect any
          information from any person under <strong>13 years of age. If you are aged 13 years or younger, you may not
          use the Website. If you are 13 or older but under the age of 18, you must review these Terms of Service with
          your parent or guardian to make sure that you and your parent or guardian understand it. While persons under
          the age of 18 may use our Services, only adults may make purchases on the Website. If you are under 18, you
          may only use the Services with the supervision of a parent or guardian.</strong>
        </p>
        <strong>6. Copyrights, Trademarks, and Service Marks</strong>
        <p>
          The Website and its original content, features and functionality are owned by the Company and are protected by
          international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights
          laws. None of the content may be downloaded, copied, reproduced, transmitted, stored, sold or distributed
          without the prior written consent of the Company.
        </p>
        <strong>7. User Conduct</strong>
        <p>
          You agree to use the Website for lawful purposes only. You shall not use the Website or Services to violate or
          infringe the rights of others, including posting statements, opinions or advice that are defamatory, vulgar,
          obscene, profane, invasive of privacy or otherwise objectionable in Company’s sole discretion. You are not
          permitted to use the Website to solicit business or advertise. You further agree not to use the Website to
          deter business from a competitor or otherwise engage in unfair business practices or misleading advertising.
        </p>
        <p>
          You acknowledge that Company may, from time to time, monitor the statements, opinions and advice users post,
          but that it has no obligation to do so. Users shall be solely liable for all statements, opinions and advice
          they post. No statement, opinion or advice posted by users shall be attributed to Company, and Company shall
          not be held liable as a result.
        </p>
        <strong>8. Warranties</strong>
        <p>
          THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE,
          INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR PARTICULAR
          PURPOSE.
        </p>
        <strong>9. Indemnification and Limitation of Liability</strong>
        <p>
          You agree to indemnify and hold harmless the Company from and against any and all losses, expenses, damages,
          and costs, including without limitation reasonable attorneys’ fees, resulting, whether directly or indirectly,
          from your violation of the Terms of Service. You also agree to indemnify and hold harmless the Company from
          and against any and all claims brought by third parties arising out of your use of the Website.
        </p>
        <p>
          In no event will the Company, its employees, contractors, officers, directors, affiliates or agents, be liable
          to you on any legal theory for any incidental, direct, indirect, punitive, actual, consequential, special,
          exemplary or other damages including without limitation, loss of revenue or income, lost profits, pain and
          suffering, emotional distress, cost of substitute goods or services, or similar damages suffered or incurred
          by you or any third party that arise in connection with the Website (or the termination thereof for any
          reason), even if foreseeable or if any form of notice has been provided.
        </p>
        <strong>10. Violation of this Agreement</strong>
        <p>
          Any user who violates this agreement may be blocked from using the Website. We may terminate your access to
          the Website without cause or notice, which may result in the forfeiture and destruction of all information
          associated with you. All provisions of these Terms of Service shall by their nature survive termination,
          including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of
          liability.
        </p>
        <strong>11. Governing Law & Dispute Resolution</strong>
        <p>
          All matters relating to the Website, the Privacy Policy, these Terms of Service and any dispute or claim
          related thereto, shall be governed by and construed in accordance with the laws of the State of California.
          Any controversy or claim arising out of or relating to this agreement, or a breach thereof, shall be settled
          by the American Arbitration Association under its Commercial Arbitration Rules. The number of arbitrators
          shall be three. The arbitration shall take place in Los Angeles, California and the arbitral decision may be
          enforced in any court.
        </p>
        <strong>12. Contact Us</strong>
        <p>
          For questions regarding these Terms of Service, please contact us at info@markive.com.
        </p>
      </div>
    </div>
  );
};

export default Terms;

import { axios } from '../lib/axios';

export async function deleteMarkive(id) {
  return await axios
    .delete('/api/markives/'+ id)
    .then(async resp => {
      if(resp && (resp.data.status == 200)) {
        alert('This Markive has been deleted, you are now being redirected back to the dashboard');
      } else {
        delete localStorage.token;
        alert('Your session has expired, please log in again.');
        window.location.href = "/password-login";
      }
    })
    .catch(err => {
      console.error(err)
    })
}

// Delete Image Action
export async function submitUpdateImage(image, id) {
  return await axios
  .patch('/api/update-image/' + image.image_id, {
    is_thumbnail: (image.is_thumbnail) ? 1 : 0,
    sort_order: id,
  });
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showModal } from '../../actions/app';
import {
  fetchCategoriesData, fetchPeopleData,
  fetchTagsData, fetchYearsData, fetchLocationsData,
} from '../../actions/suggestionTagsAction';
import { quickFindChange } from "../../actions/quickFindAction";
import IconButton from '../../components/IconButton';
import LogoWhite from '../../styles/assets/images/logoWhite';
import FindMarkiveButton from '../../components/FindMarkiveButton';
import Tags from "../../components/Tags";
import FilterBox from '../../components/FilterBox';
import Dropdown from '../../components/Dropdown';
import FilterSingle from '../../components/FilterSingle';
import {autoCompleteChange} from "../../actions/autoCompleteAction";

class QuickSearch extends Component {
  state = {
    term: '',
    category: '',
    typeFrom: '',
    typeTo: '',
    clickOnWhat: false,
    showFilters: false,
    showQuickFind: false,
    activeFilters: [],
    tag: [],
    year: [],
    people: [],
    location:'',
    date_from:'',
    date_to:'',
  };

  componentDidMount = () => {
    // TODO: This is handled in 3 places right now.. handle in only 1!
    if (this.props.categoriesSuggestions && !this.props.categoriesSuggestions.length) {
      this.props.dispatch(fetchCategoriesData());
    }
    if (this.props.peopleSuggestions && !this.props.peopleSuggestions.length) {
      this.props.dispatch(fetchPeopleData());
    }
    if (this.props.tagsSuggestions && !this.props.tagsSuggestions.length) {
      this.props.dispatch(fetchTagsData());
    }
    if (this.props.locationsSuggestions && !this.props.locationsSuggestions.length) {
      this.props.dispatch(fetchLocationsData());
    }
    if (this.props.yearsSuggestions && !this.props.yearsSuggestions.length) {
      this.props.dispatch(fetchYearsData());
    }
  }

  handleChange(event, showRecommendations=false) {
    this.setState({
      [event.target.name]: event.target.value,
      clickOnWhat: showRecommendations,
    });
  };

  handleSubmit = event => {
    event.preventDefault();

    // var queryString = '';
    // var isFirst = true;
    //
    // if(this.state.term && isFirst) {
    //   queryString += ('?term=' + this.state.term);
    //   isFirst = false;
    // } else if(this.state.term && !isFirst){
    //   queryString += ('&term=' + this.state.term);
    // }
    //
    // if(this.state.category && isFirst) {
    //   queryString += ('?category=' + this.state.category);
    //   isFirst = false;
    // } else if (this.state.location && !isFirst){
    //   queryString += ('&category=' + this.state.category);
    // }

    var query = {
      term: this.state.term,
      location: this.state.location,
      date_from: this.state.date_from,
      date_to: this.state.date_to,
      category: this.state.category ? [this.state.category] : '',
      tag: this.state.tag,
      year: this.state.year,
      people: this.state.people,
    }

    const urlParams = new URLSearchParams();

    for (const [key, value] of Object.entries(query)) {
      if (value.length) {
        if (Array.isArray(value)) {
          value.forEach((val, i) => {
            if (i === 0) {
              urlParams.set(key, val);
            } else {
              urlParams.append(key, val);
            }
          });
        } else {
          urlParams.set(key, value);
        }
      }
    }

    let urlString = urlParams.toString();
    if (urlString) {
      urlString = `?${urlString}`;
    }

    // this is here because the modal has to close
    // before redirecting ther user to the search page
    this.props.dispatch(showModal(false));
    this.props.history.push({
      pathname: '/search',
      search: urlString,
    });

    // if(window.location.pathname == '/search') {
    //   window.location.reload();
    // }
  };
  showQuickFind() {
    this.setState({showQuickFind: !this.state.showQuickFind})
  }

  updateQuickFind = quickFind => {
    this.props.dispatch(quickFindChange(quickFind));
    // this.showQuickFind();
  }

  showFilters() {
    this.setState({ showFilters: !this.state.showFilters})
  }

  addFilter(value, category, callSubmit=true) {
    if (value == '') {
      return;
    }

    var data = {
      tag: [...this.state.tag],
      year: [...this.state.year],
      people: [...this.state.people],
      location: this.state.location,
      date_from: this.state.date_from,
      date_to: this.state.date_to,
    };

    // Array -> [nameOfState, overwrite (True=overwrite, False=append)]
    const valueKeys = {
      Tags: ['tag', false],
      Year: ['year', false],
      People: ['people', false],
      Location: ['location', true],
      Date: ['date', true]
    }

    const valueKey = valueKeys[category][0];
    const valueOverwrite = valueKeys[category][1];

    const newFilters = [...this.state.activeFilters];
    for(var i = 0; i < this.state.activeFilters.length; i++) {
      var result = this.state.activeFilters[i].split(': ');
      if (valueOverwrite && result[0] === category) {
        newFilters.splice(i, 1);
        break;
      }
    }

    let activeFilters;
    if (valueOverwrite) {
      data[valueKey] = value;
      activeFilters = [...newFilters, category + ': ' + value];
    } else {
      const idx = data[valueKey].indexOf(value);
      if (idx < 0) {
        data[valueKey].push(value);
        activeFilters = [...newFilters, category + ': ' + value];
      } else {
        if (valueKey === 'year') {
          data[valueKey].splice(idx, 1);
          var index = newFilters.indexOf('Year: '+value);
          if (index !== -1) newFilters.splice(index, 1)
        }
        activeFilters = newFilters;
      }
    }

    this.setState({
      ...data,
      activeFilters: activeFilters
    }, () => {
      // if (callSubmit) {
      //   this.handleSubmit();
      // }
    })
  };

  removeFilter =  async (filter) => {
    var arrFilter = filter.split(': ');
    var activeFilters = [...this.state.activeFilters];
    var index = activeFilters.indexOf(filter);
    if (index !== -1) activeFilters.splice(index, 1);
    this.setState({ activeFilters });

    var data = {
      tag: [...this.state.tag],
      year: [...this.state.year],
      people: [...this.state.people],
      location: this.state.location,
      date_form: this.state.date_from,
      date_to: this.state.date_to,
      category: this.state.category
    }

    if(arrFilter[0] === 'Tags') {
      var indexFilter = data['tag'].indexOf(arrFilter[1]);
      if (indexFilter > -1) {
        data['tag'].splice(indexFilter, 1);
      }
    } else if(arrFilter[0] === 'People') {
      var indexFilter = data['people'].indexOf(arrFilter[1]);
      if (indexFilter > -1) {
        data['people'].splice(indexFilter, 1);
      }
    } else if(arrFilter[0] === 'Year') {
      var indexFilter = data['year'].indexOf(arrFilter[1]);
      if (indexFilter > -1) {
        data['year'].splice(indexFilter, 1);
      }
    } else if(arrFilter[0] === 'Location') {
      data['location'] = '';
    } else if(arrFilter[0] === 'Date') {
      data['date_from'] = '';
      data['date_to'] = '';
    }

    this.setState({
      ...data
    })
  }

  render() {
    return (
      <div className="page-quick-search">
        <IconButton 
          onClick={this.props.dispatch.bind(this, showModal(false))}
          className="modal-close"
          icon="close"
          iconColor="dark-grey" 
          btnColor="transparent"
        />
        <div className="search-content-container">
          <div className="search-heading">
            <LogoWhite />
            <p className="logo-subtitle">archive your life</p>
          </div>
          <div className="container">
            <form onSubmit={this.handleSubmit}>
              <div className="search-box">
                <div className="search-area">
                  <div>
                    <p>Find</p>
                    <input
                      autoFocus={true}
                      autoComplete="off"
                      value={this.state.category}
                      name="category"
                      onChange={(e) => this.handleChange(e, true)}
                      onClick={() => this.setState({ clickOnWhat: true })}
                      placeholder="What"
                    />
                    {(this.state.clickOnWhat) && (
                      <div className="search-recommendations">
                        <div className="autofill-box">
                          {this.props.categoriesSuggestions.map((cat, index) => {
                            if (this.state.category === '' || cat.name.toLowerCase().includes(this.state.category.toLowerCase())) {
                              return (
                                <button
                                  onClick={() => this.setState({ category: cat.name, clickOnWhat: false })}
                                  className="search-autofill"
                                  key={`recommended-${index}`}
                                  type="button"
                                >
                                  {cat.name}
                                </button>
                              )
                            }
                          })}
                          <div className="autofill-no-results">
                            {this.props.categoriesSuggestions.length > 0
                              ? 'No Results Found. Please choose an existing category.'
                              : 'You haven\'t created any categories yet!'
                            }
                          </div>
                        </div>

                      </div>
                    )}
                    <p>Or</p>
                    <input
                      value={this.state.term}
                      name="term"
                      onChange={(e) => this.handleChange(e)}
                      placeholder="Title or Notes"
                      onFocus={() => this.setState({ clickOnWhat: false })}
                    />
                  </div>
                </div>
              </div>

              <div className={`filters-container filters-container-${this.state.showFilters ? "show" : "hide"}`}>
                <div className="row">
                  <div className="col-xs-6">
                    <Dropdown handleSubmit={(v, l) => this.addFilter(v, l)} label="When" filter="Year"
                              list={this.props.yearsSuggestions} activeList={this.state.year} />
                  </div>
                  <div className="col-xs-6">
                    <FilterBox
                      handleSubmit={(v, l) => this.addFilter(v, l)}
                      label="Where"
                      filter="Location"
                      options={this.props.locationsSuggestions}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-6">
                    <FilterBox
                      handleSubmit={(v, l) => this.addFilter(v, l)}
                      label="Who"
                      filter="People"
                      options={this.props.peopleSuggestions}
                    />
                  </div>
                  <div className="col-xs-6">
                    <FilterBox
                      handleSubmit={(v, l) => this.addFilter(v, l)}
                      label="Tags"
                      filter="Tags"
                      options={this.props.tagsSuggestions}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xs-6">
                  <div
                    className={`quick-find-btn ${this.state.showFilters ? "quick-find-hide" : "quick-find-show"} base-label-large`}
                    onClick={() => this.showQuickFind()}
                  >
                    QUICK FIND
                  </div>
                  <div className={`quick-find-container ${this.state.showQuickFind ? "show" : "hide"}`}>
                    <div className={this.props.quickFind == 'recent' ? 'selected' : ''}
                         onClick={() => this.updateQuickFind('recent')}>Recent</div>
                    <div className={this.props.quickFind == 'nearby' ? 'selected' : ''}
                         onClick={() => this.updateQuickFind('nearby')}>Nearby</div>
                  </div>
                </div>
                <div className="col-xs-6">
                  <div
                    className={`filters-btn ${this.state.showFilters ? "filters-show" : "filters-hide"} base-label-large`}
                    onClick={() => this.showFilters()}
                  >
                    {this.state.showFilters ? 'CLEAR FILTERS' : 'FILTERS' }
                  </div>
                </div>
              </div>

              <div className={`filters-container-${this.state.showFilters ? "show" : "hide"}`}>
                <div>
                  <p className="base-label-large search-label-large">
                    {this.state.activeFilters.length || 'NO'} ACTIVE FILTERS
                  </p>
                </div>

                <div>
                  {this.state.activeFilters.map((tag, idx) => {
                    return (
                      <FilterSingle
                        key={'tag-' + idx}
                        name={tag}
                        withClose={true}
                        handleClick={() => this.removeFilter(tag)}
                      />
                    );
                  })}
                </div>

              </div>

              <div className="search-action-button-container">
                <FindMarkiveButton
                  className={"search-action-button"}
                  handleClick={this.handleSubmit}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProperties(state) { return {
  categoriesSuggestions: state.suggestionTagData.categories,
  peopleSuggestions: state.suggestionTagData.people,
  tagsSuggestions: state.suggestionTagData.tags,
  locationsSuggestions: state.suggestionTagData.locations,
  yearsSuggestions: state.suggestionTagData.years,
  quickFind: state.markiveData.quickFind
}; }

export default connect(mapStateToProperties)(QuickSearch);

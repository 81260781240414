import React from 'react';
import Header from '../../components/Header';

const PrivacyPolicy = () => {
  return (
    <div>
      <Header/>
      <div className="privacy-policy text-page">
        <h2>MARKIVE PRIVACY POLICY</h2>
        <p>
          Markive.COM (the “Website”) is owned and operated by MX2 Ventures, LLC (“Company”, “we”, “our” or “us”).
          Company respects your privacy and is committed to protecting your data by complying with this policy.
        </p>
        <p>
          You must read this policy carefully before using the Website. By accessing or using the Website, you agree to
          this privacy policy.
        </p>
        <strong>1. Scope of Policy</strong>
        <p>
          This policy applies to information we collect through the Website, e-mail and other electronic messages
          between you and Company. It does not apply to information collected by us offline or through any other means,
          including on any other website operated by Company or any third party, or by any third party, including
          through any application or content (including advertising) that may link to or be accessible from the Website.
        </p>
        <strong>2. Children Under the Age of 13</strong>
        <p>
          The Website is not intended for children under 13 years of age. If you are under 13, you may not create an
          account on the Website, make any purchases through the Website, or provide any information about yourself,
          including your name, address, telephone number, e-mail address or any screen name or user name you may use. If
          we learn we have collected or received Personal Information from a child under 13 without verification of
          parental consent, we will delete that information. If you believe we might have any information about a child
          under 13, please contact us at the address provided below.
        </p>
        <strong>3. Information We Collect and How We Collect It</strong>
        <p>
          We collect several types of information about our users, including information:
        </p>
        <p>
          a) by which you may be personally identified. Examples include but are not limited to your name, phone number,
          address, or e-mail address (“Personal Information”);
        </p>
        <p>
          b) that is about you but individually does not identify you, such as information required to use certain
          features, automatically recorded times and dates of visits to the Website;
        </p>
        <p>
          c) about your Internet connection, the equipment you use to access the Website, and usage details.
        </p>
        <p>
          We collect this information:
        </p>
        <p>
          a) directly from you when you provide it to us, when you fill out a form, when you correspond with us, and
          when you respond to requests for comments on your experiences with the Website or to surveys that we might ask
          you to complete for research purposes;
        </p>
        <p>
          b) automatically as you navigate through the Website, including but not limited to usage details, traffic and
          location data, IP addresses and information collected through cookies and other tracking technologies.
        </p>
        <p>
          The information we collect automatically is statistical data and does not generally include Personal
          Information, but we may maintain it or associate it with Personal Information we collect from other sources,
          information we receive from you or information we receive from third parties. We use the information to help
          us improve the Website and deliver a better and more personalized service, including estimating our audience
          size and usage patterns, storing information about your preferences, speeding up your usage, and recognizing
          you when you return to the Website. We also use your information to process transactions, send periodic
          e-mails, and/or to administer a contest, promotion, survey or other feature.
        </p>
        <strong>5. Disclosure of Your Information</strong>
        <p>
          We will never sell your personally identifiable information without your consent. We do retain the right to
          disclose aggregated information about our users and information that does not personally identify any
          individual, without restriction. We may also disclose your Personal Information to comply with any law or
          legal process, including responding to any government or regulatory request; to enforce or apply our Terms of
          Service and other agreements, including for billing and collection purpose; or if we believe disclosure is
          necessary or appropriate to protect the rights, property, or safety of the Company, our customers or others.
          This includes exchanging information with other companies and organizations for the purposes of integrity
          protection, fraud protection and credit risk reduction.
        </p>
        <strong>6. Accessing and Correcting Your Information,</strong>
        <p>
          You may send us an e-mail at the address below to request access to, correct, or delete any Personal
          Information that you have provided to us. We may not accommodate a request to change information if we believe
          the change would violate any law or legal requirement or cause the information to be incorrect.
        </p>
        <strong>7. Protection of Information</strong>
        <p>
          We have implemented a variety of measures designed to secure your Personal Information from accidental loss
          and from unauthorized access, use, alteration and disclosure. Encryption protects Personal Information you
          send when you transmit information or fill in forms through the Website. Secure Socket Layer (SSL) is the
          leading security protocol for data transfer on the Internet and helps to protect the safety and
          confidentiality of your information.
        </p>
        <p>
          Unfortunately, the transmission of information via the Internet is not completely secure. Although we do our
          best to protect your Personal Information, we cannot guarantee the security of your Personal Information
          transmitted to us. Any transmission of Personal Information is at your own risk. We are not responsible for
          circumvention of any privacy settings or security measures contained on the Website.
        </p>
        <strong>8. Third Party Links</strong>
        <p>
          At our discretion, we may include or offer third party links, products, or services. These third-party sites
          have separate and independent privacy policies. We have no responsibility or liability for the content and
          activities of these linked sites.
        </p>
        <strong>9. Contacting Us</strong>
        <p>
          If there are any questions regarding this privacy policy, you may contact us at info@markive.com.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

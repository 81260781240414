import { axios } from '../lib/axios';

export async function postNewSuperTag(supertag) {
  return await axios
    .post('/api/supertags', supertag)
    .then(async resp => {
      return resp.data.data.id;
    });
}

export async function linkSuperTagToMarkive(supertagData) {
  await axios.post('/api/supertags/update', supertagData);
}

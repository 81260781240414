import React, { Component } from 'react';
import LogoWhite from '../../styles/assets/images/logoWhite';
import IconButton from '../../components/IconButton';
import {CopyToClipboard} from 'react-copy-to-clipboard';

// REDUX STUFF HERE
import { connect } from 'react-redux';
import { showModal } from '../../actions/app';

class LinkMultipleMarkives extends Component {
  state = {
    copied: false,
    token: window.localStorage.token,
  };

  componentDidMount() {
    this.refs.header.scrollIntoView();
  }

  render() {
    var link = window.location.host + '/ms/' + this.props.token;
    return (
      <div className="page-create-markive">
        <div className="header" ref="header">
        {this.state.token ? (
          <a className="header-home-link" href="/search?orderby=date&order=ASC" >
            <LogoWhite />
          </a>
        ) : (
          <a className="header" href="/" >
            <LogoWhite />
          </a>
        )}
          <IconButton 
            onClick={this.props.dispatch.bind(this, showModal(false))}
            className="modal-close"
            icon="close"
            iconColor="dark-grey" 
            btnColor="transparent"
          />
        </div>

        <div className="create-markive-wrapper">
        <div className="container content share-link-wrapper">
          <h1 className="form-label edit-headline">HERE IS YOUR SHARE LINK:</h1>
          <h1 className="form-label edit-headline share-link-text">{window.location.host}/ms/{this.props.token}</h1>
        </div>

          <a href={`mailto:?subject=Check out this Markive!&body=https://` + window.location.host + `/ms/` + this.props.token}>
            <button className="submit-button mail-button">Mail</button>
          </a>
          <a className="desktop-hide" href={`sms:?&body=Check out this Markive!%0ahttps://${window.location.host}/ms/${this.props.token}`}>
            <button className="submit-button text-button">SMS</button>
          </a>

          <CopyToClipboard
            text={link}
            onCopy={() => {
              this.setState({copied: true})
              alert('Your link has been copied!');
            }}
          >

          <button className="pull-right submit-button save-button" type="button">Copy</button>
          </CopyToClipboard>

        </div>
      </div>
    );
  }
}

function mapStateToProperties(state) {
  return {
    token: state.shareMultipleMarkives.token,
  };
}

export default connect(mapStateToProperties)(LinkMultipleMarkives);

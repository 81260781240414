import React, { useState, useRef, useEffect } from 'react';
import MarkiveUrl from './MarkiveUrl';
import TagSingle from './TagSingle';
import SVGIcon from './SVGIcon';
import IconButton from "../components/IconButton";
import { useSwipeable } from 'react-swipeable';

/* 
  props: {
    url,
    notes,
    showTags,
    categories,
    tags,
    people,
    infoHandler,
    addHandler,
    isSharePage,
    hideNotes,
    hideTags,
    videos,
    onToggleVideoLightBox
  }
*/

const MarkiveNotesAndTags = (props) => {
  const [disableSwipe, setDisableSwipe] = useState(false);
  const dragDivRef = useRef();
  const [drag, setDrag] = useState(false);
  const [dragX, setDragX] = useState('0px');
  const swipeHandlers = useSwipeable({
    onTap: (e) => {
      if(e.event.target.tagName === 'A' || e.event.target.tagName === 'BUTTON' || e.event.target.onclick) e.event.target.click();
      e.event.preventDefault();
    },
    onSwiping: (e) => {
      if (disableSwipe) return;
      if (!e.event.path.includes(dragDivRef.current)) return;
      if(!drag) setDrag(true);
      setDragX(`${e.deltaX}px`);
    },
    onSwiped: (e) => {
      if (disableSwipe) return;
      if (e.dir === 'Left' && !props.showTags) props.infoHandler();
      else if (e.dir === 'Right' && props.showTags) props.infoHandler();
      setDragX('0px'); 
      setDrag(false);
    },
    delta: 20,
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
    trackMouse: true
  });

  useEffect(() => {
    setDisableSwipe(props.hideNotes || props.hideTags);
  }, [props]);
  

  function urlify(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, '<a href="$1">$1</a>')
  }

  return (
    <div className="notes-tags-outer-wrap" data-show-tags={props.showTags} {...swipeHandlers} data-swipe-disabled={disableSwipe}>
      <div className="drag-div" data-drag={drag} style={{transform: `translateX(${dragX})`}} ref={dragDivRef}>
        {
          props.hideNotes ? null :
          <div className="notes-wrap">
            <div className="row">
              <div className="col-xs-10">
                <MarkiveUrl url={props.url} />
              </div>
              <div className="col-xs-2 video__wrapper">
                {
                  props.videos.length > 0 &&
                  <IconButton onClick={props.onToggleVideoLightBox} icon="play" tooltip="Play" btnColor="blue" hoverColor="grey" outline={window.innerWidth < 768} outlineColor="blue"/>
                }
                
              </div>
            </div>
            <p className="notes-text" dangerouslySetInnerHTML={{__html: urlify(props.notes ? props.notes : '')}} />
          </div>
        }
        
        {
          props.hideTags ? null :
          <div className="all-tags-wrap">
            {
              props.isSharePage && (!props.categories || !props.categories.length) ? null :
              <div className="tags-info-wrap">
                <p className="base-label-large base-label-large-p">WHAT</p>
                <div className="tags-wrap">
                  {props.categories
                    ? props.categories.map(category => {
                        return (
                          <TagSingle
                            key={'category-' + category.id}
                            handleClick={() => {
                              console.log(category.id + ' clicked');
                            }}
                            name={category.name}
                          />
                        );
                      })
                    : null}
                  {
                    !props.isSharePage ?
                    <TagSingle
                      handleClick={props.addHandler}
                      name="+"
                    /> : null
                  }
                </div>
              </div>
            }
            
            {
              props.isSharePage && (!props.tags || !props.tags.length) ? null :
              <div className="tags-info-wrap">
                <p className="base-label-large base-label-large-p">TAGS</p>
                <div className="tags-wrap">
                  {props.tags
                    ? props.tags.map(tag => {
                        return (
                          <TagSingle
                            key={'tag-' + tag.id}
                            handleClick={() => {
                              console.log(tag.id + ' clicked');
                            }}
                            name={tag.name}
                          />
                        );
                      })
                    : null}
                  {
                    !props.isSharePage ?
                    <TagSingle
                      handleClick={props.addHandler}
                      name="+"
                    /> : null
                  }
                </div>
              </div>
            }

            {
              props.isSharePage && (!props.people || !props.people.length) ? null :
              <div className="tags-info-wrap">
                <p className="base-label-large base-label-large-p">WHO</p>
                <div className="tags-wrap">
                  {props.people
                    ? props.people.map(person => {
                        return (
                          <TagSingle
                            key={'person-' + person.id}
                            handleClick={() => {
                              console.log(person.id + ' clicked');
                            }}
                            name={person.name}
                          />
                        );
                      })
                    : null}
                  {
                    !props.isSharePage ?
                    <TagSingle
                      handleClick={props.addHandler}
                      name="+"
                    /> : null
                  }
                </div>
              </div>
            }
            
          </div>
        }
        
      </div>
      
  </div>
  );
}

export default MarkiveNotesAndTags;
import React, { useEffect, useState } from 'react';
import ResizedImage from './ResizedImage';
import IconButton from './IconButton';
import useWindowDimensions from '../lib/useWindowDimensions';
import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/swiper.min.css'

// import 'swiper/css';
// import "swiper/css";
/* 
  props: {
    images,
    title,
    onToggleLightBox,
    featureBlock,
    numOfVideos
  }
*/

export const MarkiveSlider = (props) => {
  const [images, setImages] = useState();
  const [title, setTitle] = useState();
  const [length, setLength] = useState(0);
  const { width } = useWindowDimensions();
  const [index, setIndex] = useState(0);
  const [swiper, setSwiper] = useState(null);

  useEffect(() => {
    if (props.images) {
      setLength(props.images.length);
      setImages(props.images);
    }
  }, [props.images])

  useEffect(() => {
    if (props.title) {
      setTitle(props.title);
    }
  }, [props.title])

  const forward = () => {
    swiper.slideTo(swiper.activeIndex + 1);
  }

  const backward = () => {
    swiper.slideTo(swiper.activeIndex - 1);
  }
  
  return (
    <div className={`slider-outer-wrap${props.featureBlock ? ' slider-outer-wrap-100' : ''}`}>
    <Swiper onSwiper={setSwiper} onSlideChange={(e) => setIndex(e.activeIndex)}>
      {
        images && length ?
        images.map((img, i) => {
          return (
            <SwiperSlide key={i}>
              <ResizedImage id={i} className={`${props.featureBlock ? 'featured-image' : 'img-div'}`} url={img.url} alt={`${title}-${i}`}>
                {
                  props.featureBlock && 
                  <div className="num-expand-wrap">
                    <div className="num-images">{i+1+'/'+images.length}</div>
                    <IconButton icon="expand" btnColor="transparent" tooltip="View all photos" onClick={props.onToggleLightBox}/>
                  </div>
                }
              </ResizedImage>
            </SwiperSlide>
          )
        }) : null
      }
    </Swiper>
    {
     (!props.featureBlock && swiper) && 
      <div className="slider-controls" data-length={length} >
        <button onClick={backward} disabled={!length || index === 0}>prev</button>
        <button onClick={forward} disabled={!length || index === length - 1}>next</button>
      </div>
    }
    {
      (!props.featureBlock && swiper) && 
      <div className="slider-pagination">{swiper.activeIndex + 1}/{length}</div>
    }
  </div>
  )
}

export default MarkiveSlider;
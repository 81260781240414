import React from 'react';

var logoBlack = () => {
  return (
    <svg width="299px" height="42px" viewBox="0 0 299 42" version="1.1">
      <defs>
        <polygon id="path-1" points="0 42 298.5101 42 298.5101 0.2154004 0 0.2154004" />
      </defs>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="1c-View-Markive_v2.0-Copy-4" transform="translate(-26.000000, -23.000000)">
          <g id="Markive-Logo-Dark_Blue" transform="translate(26.000000, 23.000000)">
            <g id="Markive-Logo">
              <polygon
                id="Fill-1"
                fill="#4A4A4A"
                points="33.2153004 0 20.2277396 28.2178843 7.68778696 0 7.68778696 0 0 39.0358102 5.70123697 39.0358102 9.62819177 17.0972308 20.1308347 40.7076923 30.9449576 17.1201647 34.4681421 39.0358102 40.1255411 39.0358102 33.2176077 0"
              />
              <g id="Group-11">
                <path
                  d="M117.96033,19.6263901 L119.70603,19.6263901 C124.90863,19.6263901 127.51223,17.6416733 127.51223,13.6722396 C127.51223,9.9551976 124.97993,8.09897109 119.91763,8.09897109 L117.96033,8.09897109 L117.96033,19.6263901 Z M124.80053,23.7885594 L136.07283,39.2716453 L129.34993,39.2716453 L118.95163,24.4011829 L117.96033,24.4011829 L117.96033,39.2716453 L112.46333,39.2716453 L112.46333,2.9432962 L118.90333,2.9432962 C123.71493,2.9432962 127.19023,3.84731752 129.32693,5.65077122 C131.68673,7.65843278 132.86433,10.308546 132.86433,13.6034055 C132.86433,16.1755067 132.12603,18.3873761 130.64943,20.2390137 C129.17053,22.0883568 127.22013,23.2723035 124.80053,23.7885594 Z"
                  id="Fill-2"
                  fill="#4A4A4A"
                />
                <polygon
                  id="Fill-4"
                  fill="#4A4A4A"
                  points="161.53015 17.437236 175.70505 2.94536123 183.03975 2.94536123 166.57635 19.3921247 183.13405 39.2714159 175.77405 39.2714159 162.66175 23.1091667 161.53015 24.2380461 161.53015 39.2714159 156.03315 39.2714159 156.03315 2.94536123 161.53015 2.94536123"
                />
                {/* <mask id="mask-2" fill="white">
                                <use xlink:href="#path-1"></use>
                            </mask> */}
                <g id="Clip-7" />
                <polygon
                  id="Fill-6"
                  fill="#4A4A4A"
                  mask="url(#mask-2)"
                  points="202.5265 39.2695803 208.0235 39.2695803 208.0235 2.94352565 202.5265 2.94352565"
                />
                <polygon
                  id="Fill-8"
                  fill="#4A4A4A"
                  mask="url(#mask-2)"
                  points="233.10408 2.94421399 243.17348 28.1191427 253.38548 2.94421399 259.39998 2.94421399 243.05388 41.9983939 227.08958 2.94421399"
                />
                <polygon
                  id="Fill-9"
                  fill="#227BE4"
                  mask="url(#mask-2)"
                  points="86.37121 39.2704981 76.30181 14.0955694 66.08981 39.2704981 60.07531 39.2704981 76.42141 0.216318188 92.38571 39.2704981"
                />
                <polygon
                  id="Fill-10"
                  fill="#4A4A4A"
                  mask="url(#mask-2)"
                  points="298.51033 8.09782385 283.91223 8.09782385 283.91223 16.8259891 298.08713 16.8259891 298.08713 21.9793695 283.91223 21.9793695 283.91223 34.1194121 298.51033 34.1194121 298.51033 39.2704981 278.41753 39.2704981 278.41753 2.94444344 298.51033 2.94444344"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default logoBlack;

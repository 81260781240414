import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showModal, showLoad, hideLoad } from "../../actions/app";
import { axios } from '../../lib/axios';
import { images as imgHelper } from '../../lib/images';
import {CloudinaryContext, Video} from "cloudinary-react";
import Header from '../../components/Header';
import MarkiveActionMenu from '../../components/MarkiveActionMenu';
import IconButton from '../../components/IconButton';
import MarkiveNotesAndTags from '../../components/MarkiveNotesAndTags';
import MapComponent from '../../components/MapComponent';
import moment from 'moment';
import MarkiveSlider from '../../components/MarkiveSlider';
import MarkiveVideoSlider from '../../components/MarkiveVideoSlider';
import LinkedMarkiveSlider from '../../components/LinkedMarkiveSlider';
import SearchEntryCard from '../../components/SearchEntryCard';

class Markive extends Component {
  state = {
    address: '',
    date: '',
    title: '',
    longitude: 0,
    latitude: 0,
    notes: '',
    id: '',
    created: '',
    updated: '',
    images: [],
    videos: [],
    resized_images: [],
    linked_markives: [],
    // TODO: figure out and refactor
    // images: [{ caption: '', file: '', preview: '' }],
    categories: [],
    tags: [],
    people: [],
    disabled: true,
    isOpen: false,
    photoIndex: 0,
    showTags: false,
    showMap: false,
    showLightBox: false,
    showVideoLightBox: false,
    showLinkedMarkiveLightBox: false,
    isSharePage: false,
    hideNotes: false,
    hideTags: false,
    token: window.localStorage.token,
    currentSlide: 0,
  };

  // LifeCycle hooks
  UNSAFE_componentWillMount() {
    this.fetchMarkive();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if(this.props.location.pathname !== newProps.location.pathname) {
      this.fetchMarkive(newProps.match.params.id);
    }
  }

  // After proper Markive Data is fetched, this handles its state changes
  handleFetchStateChange = (data) => {
    if(data.resized_images) {
      data.resized_images
        .sort((a,b) => b.is_thumbnail * 1 - a.is_thumbnail * 1)
        .map(i => {
          imgHelper.getObjectURL(i['url']);
        });
    }

    for(var key in data) {
      this.setState({
        [key]: data[key],
      })
    }
    if (data.created_at) this.setState({created: data.created_at.slice(0, 10)});
    if (data.updated_at) this.setState({updated: data.updated_at.slice(0, 10)});
    if((!data.url || !data.url.length) && (!data.notes || !data.notes.length)) this.setState({hideNotes: true});
    if(this.state.isSharePage && 
      (!data.categories || !data.categories.length) && 
      (!data.tags || !data.tags.length) && 
      (!data.people || !data.people.length)) this.setState({hideTags: true});
  }

  fetchMarkive = (markiveId = this.props.match.params.id) => {
    let path;
    let multiShareSingle = false;
    let linkedSingle = false;
    const id = markiveId;
    switch (this.props.match.path) {
      case '/share/:token':
        path = ['/api/share', this.props.match.params.token].join('/');
        this.setState({ isSharePage: true });
        break;

      case '/share/:token/:id':
        path = ['/api/share', this.props.match.params.token].join('/');
        this.setState({ isSharePage: true });
        linkedSingle = this.props.match.params.token;  
        break;

      case '/mshare/:token/:id':
        path = ['/api/mshare', this.props.match.params.token].join('/');
        this.setState({ isSharePage: true });
        multiShareSingle = true;
        break;
    
      default:
        path = ['/api/markive', id].join('/');
        break;
    }

    // We already have shared markive data, so we're avoiding an extra call here
    if (this.state.linked_markives.length > 0 && linkedSingle) {
      const data = this.state.linked_markives.find(obj => obj.id === parseInt(id))
      this.handleFetchStateChange(data);
      return data;
    }

    axios
      .get(path)
        .then(resp => {
          // TODO: multiShareSingle (when a single markive is pressed from the share list)
          //       can be handled without an extra API call
          const data = multiShareSingle ? resp.data.data.find(obj => obj.id === parseInt(id)) : resp.data.data;
          this.handleFetchStateChange(data);
        })
      .catch(err => {
        console.log(err);
      });
  };

  // Action
  showEditModal = type => {
    this.props.dispatch(showModal(type));
  };

  handleShare = (type) =>
    this.props.dispatch(showModal(type));

  handleAdd = async () => {
    // event.preventDefault();
    var data = {
      markives: [parseInt(this.state.id)],
    };
    this.props.dispatch(showLoad(true));
    if (window.localStorage.token) {
      axios
        .post(`/api/madd/${this.props.match.params.token}`, data)
        .then(async res => {
          if(res && res.data && res.data.data) {
            this.props.dispatch(hideLoad());
            setTimeout(() => {
              alert("The selected Markives have been added to your feed!");
              this.props.history.push('/search?tag=Shared+with+me');
            }, 500);
          }
        })
        .catch(err => {
          console.log(err);
          alert("There was an issue adding these Markives at this time.")
        });
    } else {
      this.props.history.push(`/google-auth/${this.props.match.params.token ? this.props.match.params.token : ''}`);
    }
  };
  
  toggleInfo = () => {
    this.setState({
      showTags: !this.state.showTags
    })
  }

  toggleMap = () => {
    this.setState({
      showMap: !this.state.showMap
    })
  }

  toggleLightBox = () => {
    this.setState({
      showLightBox: !this.state.showLightBox
    })
  }

  showVideoLightBox = () => {
    this.setState({
      showVideoLightBox: true
    })
  }

  hideVideoLightBox = () => {
    this.setState({
      showVideoLightBox: false
    })
  }

  showLinkedMarkiveLightBox = (id) => {
    this.setState({
      showLinkedMarkiveLightBox: true,
      currentSlide: id,
    })

  }

  hideLinkedMarkiveLightBox = () => {
    this.setState({
      showLinkedMarkiveLightBox: false
    })
  }

  static contextType = CloudinaryContext.contextType;

  render() {
    var dateStuff = this.state.date;
    var dateResultArr = dateStuff.split('T');

    const {
      images, resized_images
    } = this.state;

    return (
      <div className="page-markive-single">
        <Header {...this.props} />

        <div className="content">
          <div className="top-content">
            <h1 className="heading" id="heading">{this.state.title}</h1>
            <div className="subhead-date-wrap">
              <a onClick={this.toggleMap} className="subheading" title="Open map" data-title="Open map"><h2>{this.state.address}</h2></a>
              {this.state.date ?
                (
                  <>
                    <p className="markive-date markive-date--m">{moment(dateResultArr[0]).format('MM/D/YY')}</p>
                    <p className="markive-date markive-date--d">{moment(dateResultArr[0]).format('MMMM Do, YYYY')}</p>
                  </>
                ) :
                <div></div>
              }
            </div>
            
          </div>
          <MarkiveSlider images={resized_images} featureBlock onToggleLightBox={this.toggleLightBox} numOfVideos={this.state.videos.length} title={this.state.title}/>

          <div className="bottom-content">
            <MarkiveNotesAndTags
              url={this.state.url}
              notes={this.state.notes}
              showTags={this.state.showTags}
              categories={this.state.categories}
              tags={this.state.tags}
              people={this.state.people}
              infoHandler={this.toggleInfo}
              addHandler={this.showEditModal.bind(this, 'editMarkiveModal')}
              isSharePage={this.state.isSharePage}
              hideNotes={this.state.hideNotes}
              hideTags={this.state.hideTags}
              videos={this.state.videos}
              onToggleVideoLightBox={this.showVideoLightBox}
            />
          </div>
        </div>

        <MarkiveActionMenu 
          infoHandler={this.toggleInfo} 
          deleteHandler={this.showEditModal.bind(this, 'deleteMarkiveModal')} 
          editHandler={this.showEditModal.bind(this, 'editMarkiveModal')} 
          shareHandler={this.handleShare.bind(this, 'shareMarkiveModal')}
          addHandler={this.handleAdd}
          infoIndex={this.state.showTags ? 1 : 0} 
          isSharePage={this.state.isSharePage}
          hideNotes={this.state.hideNotes}
          hideTags={this.state.hideTags}
        />


        <div className="results">
          {this.state.linked_markives.length > 0 &&
            <div className="results-title">Linked Markives</div>       
          }
          {this.state.linked_markives.length > 0 &&
            (this.state.linked_markives.map((result, id) => {
              return <SearchEntryCard
                      key={'search-result-' + result.id}
                      {...result}
                      row={(window.innerWidth < 768 && this.state.activeView !== 'Cards')}
                      hideCardMenu
                      hideLinks={this.state.isSharePage}
                      openLinkedMarkive={true}
                      shareToken={this.props.match.params.token}
                      onShowModal={() => this.showLinkedMarkiveLightBox(id)}
                    />
            }))            
          }
        </div>

        <div className={`map-container${this.state.showMap ? ' show' : ''}`}>
          <MapComponent mapLat={this.state.latitude} mapLng={this.state.longitude} />
          <IconButton onClick={this.toggleMap} icon="close" iconColor="grey" btnColor="transparent" />
        </div>

        <div className={`light-box${this.state.showLightBox ? ' show' : ''}`}>
          <MarkiveSlider images={resized_images} title={this.state.title} />
          <IconButton onClick={this.toggleLightBox} icon="close" btnColor="transparent" />
        </div>

        <div className={`light-box${this.state.showVideoLightBox ? ' show' : ''}`}>
          <MarkiveVideoSlider videos={this.state.videos} cloudName={this.context.cloudName} showVideoLightBox={this.state.showVideoLightBox} />
          <IconButton onClick={this.hideVideoLightBox} icon="close" btnColor="transparent" />
        </div>

        <div className={`light-box${this.state.showLinkedMarkiveLightBox ? ' show' : ''}`}>
          <LinkedMarkiveSlider
            token={this.props.match.params.token}
            markives={this.state.linked_markives}
            showLinkedMarkiveLightBox={this.state.showLinkedMarkiveLightBox}
            hideLinkedMarkiveLightBox={this.hideLinkedMarkiveLightBox}
            currentSlide={this.state.currentSlide}
          />
          <IconButton onClick={this.hideLinkedMarkiveLightBox} icon="close" btnColor="transparent" />
        </div>

        {this.state.token && (
          <img
            className="base-tracking-pixel"
            src={`${API_URL}/users/cookie.gif?token=` + this.state.token}
          />
        )}
      </div>
    );
  }
}

function mapStateToProperties(state) {
  return {
    isModalVisible: state.app.isModalVisible,
    modalType: state.app.modalType,
    shareUrl: state.shareMarkive.url,
    cbArray: state.shareMultipleMarkives.cbArray,
  };
}

export default connect(mapStateToProperties)(Markive);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import pluralize from 'pluralize';
import request from 'superagent';
import {CloudinaryContext, Video} from "cloudinary-react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import Tags from '../../components/Tags';
import LogoWhite from '../../styles/assets/images/logoWhite';
import IconButton from '../../components/IconButton';
import { postNewMarkive } from '../../async/postNewMarkive';
import { postNewCategory, linkCategoryToMarkive } from '../../async/postNewCategories';
import { postNewTag, linkTagToMarkive } from '../../async/postNewTags';
import { postNewPerson, linkPersonToMarkive } from '../../async/postNewPeople';
import { postNewSuperTag, linkSuperTagToMarkive } from '../../async/postNewSuperTag';
import { postNewImages } from '../../async/postNewImages';
import TextareaAutosize from 'react-textarea-autosize';
import { postNewVideo } from '../../async/postNewVideo';

import { showModal, showLoad, hideLoad } from '../../actions/app';
import { fetchCategoriesData, fetchPeopleData, fetchTagsData, fetchSuperTagsData} from '../../actions/suggestionTagsAction';

const Keys = {
  tab: '9',
  comma: '188',
  enter: '13',
};

const delimiters = [Keys.comma, Keys.enter, Keys.tab];

class CreateMarkive extends Component {
  state = {
    date: '',
    title: '',
    address: '',
    longitude: '',
    latitude: '',
    url: '',
    categories: [],
    tags: [],
    superTags: [],
    people: [],
    images: [],
    notes: '',
    markiveId: '',
    token: window.localStorage.token,
    requiredComplete: true,
    boxHeight: 30,
    showTags: false,
    progress: 0,
    showProgressBar: false,
    cloud_key: '',
  };

  componentDidMount() {
    this.refs.header.scrollIntoView();
    this.props.dispatch(fetchCategoriesData());
    this.props.dispatch(fetchPeopleData());
    this.props.dispatch(fetchSuperTagsData());
    this.props.dispatch(fetchTagsData());
    this.state.date = this.formatDate();
  }

  AutoCompleteAddressChange = address => this.setState({ address: address });
  AutoCompleteTitleChange = title => this.setState({ title: title });
  handleChange = event => this.setState({ [event.target.name]: event.target.value });

  handleKeyUp(e) {
    // Max: 150px Min: 40px
    let newHeight = Math.max(Math.min(e.target.scrollHeight + 2, 150), 30);
    if (newHeight !== this.state.boxHeight) {
      this.setState({
        boxHeight: newHeight
      });
    }
  }

  handleSelect(address) {
    this.setState({ address });
    if (address) {
      this.autofill(address);
    }
  };
  
  autofill(address) {
    geocodeByAddress(address)
      .then(results => {
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();
        const placeId = results[0].place_id;

        const map = new google.maps.Map(document.getElementById("google-map-hide"), {
          center: { lat: lat, lng: lng },
          zoom: 15,
        });
        var request = {
          placeId: placeId
        };
        var infowindow = new google.maps.InfoWindow();
        var service = new google.maps.places.PlacesService(map);
        service.getDetails(request, (place, status) => {
          if (status == google.maps.places.PlacesServiceStatus.OK) {
            let excludedCat = ['subpremise', 'street_address', 'premise', 'postal_code', 'street_number'];
            if (excludedCat.includes(place.types[0])) {
              this.setState({
                longitude: lng,
                latitude: lat,
                address: (place.formatted_address) ? place.formatted_address : ''
              });
            } else {
              const existCategories = this.findExistCategories(this.props.categories, place.types);
              if (existCategories.length > 0) {
                this.setState({
                  longitude: lng,
                  latitude: lat,
                  title: (place.name) ? place.name : '',
                  url: (place.website) ? place.website : '',
                  address: (place.formatted_address) ? place.formatted_address : '',
                  categories: [...this.state.categories, ...existCategories]
                });
              } else {
                this.setState({
                  longitude: lng,
                  latitude: lat,
                  title: (place.name) ? place.name : '',
                  url: (place.website) ? place.website : '',
                  title: (place.name) ? place.name : '',
                  address: (place.formatted_address) ? place.formatted_address : ''
                });
              }
            }
          } else {
            this.setState({
              longitude: lng,
              latitude: lat
            });
          }
        });
      })
      .catch(error => console.error('Error', error));
  }

  findExistCategories(categories, currentGoogleCategories) {
    const singularArr = currentGoogleCategories.map(cat => cat.replaceAll('_', ' ').toLowerCase());
    const pluralArr = singularArr.map(cat => pluralize.plural(cat));
    let allCats = [...singularArr,...pluralArr];
    return categories.filter(element => {
      return allCats.includes(element.name.toLowerCase())
    });
  }

  handleDelete(name, i) {
    let tags = this.state[name];
    tags.splice(i, 1);
    this.setState({
      [name]: tags,
      showTags: false,
    });
  };

  handleAddition(name, tag) {

    let tags = this.state[name];
    if(tag) {
      new Promise((resolve) => {
        let id = parseInt(tag.id, 10);
        if (id === 0 || isNaN(id)) {
          switch (name) {
            case 'categories':
              id = postNewCategory(tag);
              break;
            case 'people':
              id = postNewPerson(tag);
              break;
            case 'tags':
              id = postNewTag(tag);
            case 'superTags':
              id = postNewSuperTag(tag);
          }
        }
        resolve(id);

      }).then(id => {
        tag.id = id.toString();
        tags.push(tag);
        if (name === 'superTags') {
          this.setState({
            [name]: [tag],
            showTags: false,
          });
        } else {
          this.setState({
            [name]: tags,
            showTags: false,
          });
        }
      })
    }
  };

  handleDrag(name, tag, currPos, newPos) {
    let tags = this.state[name];
    tags.splice(currPos, 1);
    tags.splice(newPos, 0, tag);

    this.setState({ [name]: tags });
  };

  handleCaptionChange(event) {
    event.preventDefault();
    var images = this.state.images;
    var idx = Number(event.target.attributes.idx.value);
    images[idx].caption = event.target.value;
    this.setState({
      images
    });
  };

  handleImageUpload(e) {
    const files = e.target.files;
    if (e && e.target && e.target.files && e.target.files.length > 0) {
      var images = [...this.state.images];

      for (var i = 0; i < files.length; i++) {
        var idx = images.length;
        let reader = new FileReader();
        let file = e.target.files[i];

        reader.onloadend = () => {
          images.push({
            file: file,
            caption: '',
            preview: reader.result
          });
          this.setState({ images });
        };
        reader.readAsDataURL(file);
      }
    }
  };

  handleVideoUpload(event) {
    this.setState({
      showProgressBar: true,
    });
    
    const url = `https://api.cloudinary.com/v1_1/${
        this.context.cloudName
        }/upload`;

    const file = event.target.files[0];

    const title = event.currentTarget.files[0].name;

    request.post(url)
      .field('upload_preset', this.context.uploadPreset)
      .field('file', file)
      .field('multiple', false)
      .field('tags', title ? `myphotoalbum,${title}` : 'myphotoalbum')
      .field('context', title ? `photo=${title}` : '')
      .on('progress', (progress) => this.onPhotoUploadProgress(progress.percent))
      .end((error, response) => {
        this.onPhotoUploaded(response.body.public_id);
    });
  };

  onPhotoUploadProgress(progress) {
    this.setState({
        progress: progress,
    });
  }

  onPhotoUploaded(cloud_key) {
    this.setState({
      cloud_key: cloud_key,
    });
  }

  static contextType = CloudinaryContext.contextType;
  
  formatDate() {
    var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  async handleSubmit(event) {
    event.preventDefault();

    var errors = [];

    var momentDate = moment(this.state.date).format();
    var dateArr = momentDate.split('T');
    var dateFormat = dateArr[0];

    var data = {
      address: this.state.address,
      longitude: this.state.longitude,
      latitude: this.state.latitude,
      title: this.state.title,
      date: dateFormat,
      url: this.state.url,
      notes: this.state.notes,
    };

    if (!data.title) {
      this.setState({requiredComplete:false});
      return;
    }

    /*
    MARKIVE ID AFTER POST
    */
    this.props.dispatch(showLoad());

    var markiveId = await postNewMarkive(data);
    this.props.dispatch(showModal(false));
    this.props.dispatch(showLoad(true));

    /*
    CATEGORY
    */
    let categoryData = {
      categoryArray: this.state.categories.map(i => parseInt(i.id, 10)),
      markiveId: markiveId
    };

    try {
      await linkCategoryToMarkive(categoryData);
    } catch {
      errors.push('There was an issue with some category uploads')
    }

    /*
    TAGS
    */
    let tagData = {
      tagArray: this.state.tags.map(i => parseInt(i.id, 10)),
      markiveId: markiveId
    };

    try {
      await linkTagToMarkive(tagData);
    } catch {
      errors.push('There was an issue with some tag uploads')
    }

    /*
    PEOPLE
    */
    let peopleData = {
      peopleArray: this.state.people.map(i => parseInt(i.id, 10)),
      markiveId: markiveId
    };

    try {
      await linkPersonToMarkive(peopleData);
    } catch {
      errors.push('There was an issue with linked a person to the Markive.')
    }

    /*
    SUPERTAG
    */
    let supertagData = {
      superTagsArray: this.state.superTags.map(i => parseInt(i.id, 10)),
      markiveId: markiveId
    };

    try {
      await linkSuperTagToMarkive(supertagData);
    } catch {
      errors.push('There was an issue with linked a supertag to the Markive.')
    }


    // WHAT IS THIS FOR AGAIN?
    if (token) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    } else {
      axios.defaults.headers.common['Authorization'] = null;
      delete axios.defaults.headers.common['Authorization'];
    }

    /*
    IMAGES
    */

    for(var image of this.state.images) {
      const files = new FormData();
      files.append('markive_id', markiveId);
      if (image.file) {
        files.append("image", image.file);
        files.append('caption', image.caption);
        try {
          await postNewImages(files);
        } catch {
          errors.push(`${image.file.name} failed to upload.`)
        }

      }
    }


    /*
    VIDEO
    */
    if (this.state.cloud_key) {
      const videoFile = new FormData();
      videoFile.append('markive_id', markiveId);
      videoFile.append("cloud_key", this.state.cloud_key);
      try {
        await postNewVideo(videoFile);
      } catch {
        errors.push(`${this.state.cloud_key} failed to upload.`)
      }
    }

  // })
    if (errors.length) {
      alert(`
        Your Markive has been saved, but there were some issues: \n
        ${errors.join('\n')}
      `);
    } else {
      alert('Your Markive has been successfully saved!');
    }
    this.props.dispatch(hideLoad());
    this.props.history.push(`/markive/${markiveId}`);
  };

  render() {
    let expandedStyle = {
      height: this.state.boxHeight,
      marginTop: 20,
    };

    const percent = Math.floor(this.state.progress);

    return (
      <div className="page-create-markive">

        <div className="header" ref="header">
        {this.state.token ? (
          <a className="header-home-link" href="/search?orderby=date&order=ASC" >
            <LogoWhite />
          </a>
        ) : (
          <a className="header" href="/" >
            <LogoWhite />
          </a>
        )}
        {/* <LogoWhite />
        THIS IS THE REASON WHY ITS NOT CLOSING ON CERTAIN COMPONENTS...
        NEEDS TO CONNECT TO THE MODAL WGERE THE ROUTES ARE
        */}
          <IconButton 
            onClick={this.props.dispatch.bind(this, showModal(false))} 
            className="modal-close"
            icon="close"
            iconColor="dark-grey" 
            btnColor="transparent" 
          />
        </div>

        <div className="create-markive-wrapper">
        <div className="container content">
          <form autoComplete="off" onSubmit={(e) => this.handleSubmit(e)}>
            <div className="row">
              <div className="col-md-2 col-sm-3 col-xs-4">
                <p className="form-label">WHERE</p>
              </div>
              <div className="col-md-10 col-sm-9 col-xs-8">
                <PlacesAutocomplete
                  className="create-places-autocomplete"
                  inputProps={{
                    id: 'address',
                    type: 'search',
                    value: this.state.address,
                    onChange: this.AutoCompleteAddressChange,
                    className: 'create-places-autocomplete',
                    autoFocus: false,
                  }}
                  onSelect={(address) => this.handleSelect(address)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-2 col-sm-3 col-xs-4">
                <p className="form-label">WHAT</p>
              </div>
              <div className="col-md-10 col-sm-9 col-xs-8">
                <Tags
                  name="categories"
                  allowNew
                  newTagPrefix="Add New"
                  suggestions={this.props.categories}
                  onDelete={(i) => this.handleDelete('categories', i)}
                  onAddition={(tag) => this.handleAddition('categories', tag)}
                  tags={this.state.categories}
                  delimiters={delimiters}
                  autocomplete={true}
                  minQueryLength={this.state.showTags ? 0 : 1}
                  inputAttributes={{
                    onClick: () => this.setState({ showTags: true }),
                  }}
                  onInput={(e) => {
                    this.setState({ showTags: !e })
                  }}
                  maxSuggestionsLength={100}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-2 col-sm-3 col-xs-4">
                <p className="form-label">WHEN</p>
              </div>
              <div className="col-md-10 col-sm-9 col-xs-8">
                <input
                  name="date"
                  type="date"
                  onChange={this.handleChange}
                  value={this.state.date}
                  placeholder=""
                  />
              </div>
            </div>
            <div className="row">
              <div className="col-md-2 col-sm-3 col-xs-4">
                <p className="form-label">WHO</p>
              </div>
              <div className="col-md-10 col-sm-9 col-xs-8">
                <Tags
                  name="people"
                  allowNew
                  newTagPrefix="Add New"
                  autocomplete={true}
                  suggestions={this.props.people}
                  onDelete={(i) => this.handleDelete('people', i)}
                  onAddition={(tag) => this.handleAddition('people', tag)}
                  tags={this.state.people}
                  minQueryLength={this.state.showTags ? 0 : 1}
                  inputAttributes={{
                    onClick: () => this.setState({ showTags: true }),
                  }}
                  onInput={(e) => {
                    this.setState({ showTags: !e })
                  }}
                  delimiters={delimiters}
                  maxSuggestionsLength={100}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-2 col-sm-3 col-xs-4">
                <p className="form-label">TITLE</p>
              </div>
              <div className="col-md-10 col-sm-9 col-xs-8">
                <PlacesAutocomplete
                  className="create-places-autocomplete"
                  inputProps={{
                    id: 'title',
                    type: 'search',
                    value: this.state.title,
                    onChange: this.AutoCompleteTitleChange,
                    className: 'create-places-autocomplete'
                  }}
                  onSelect={(address) => this.handleSelect(address)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-2 col-sm-3 col-xs-4">
                <p className="form-label">HOW (URL):</p>
              </div>
              <div className="col-md-10 col-sm-9 col-xs-8">
                <input
                  name="url"
                  onChange={this.handleChange}
                  value={this.state.url}
                  placeholder=""
                  />
              </div>
            </div>
            <div className="row">
              <div className="col-md-2 col-sm-3 col-xs-4">
                <p className="form-label">TAGS:</p>
              </div>
              <div className="col-md-10 col-sm-9 col-xs-8">
                <Tags
                  name="tags"
                  allowNew
                  newTagPrefix="Add New"
                  suggestions={this.props.tags}
                  onDelete={(i) => this.handleDelete('tags', i)}
                  onAddition={(tag) => this.handleAddition('tags', tag)}
                  tags={this.state.tags}
                  delimiters={delimiters}
                  autocomplete={true}
                  minQueryLength={this.state.showTags ? 0 : 1}
                  inputAttributes={{
                    onClick: () => this.setState({ showTags: true }),
                  }}
                  onInput={(e) => {
                    this.setState({ showTags: !e })
                  }}
                  maxSuggestionsLength={100}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-2 col-sm-3 col-xs-4">
                <p className="form-label">SUPERTAG:</p>
              </div>
              <div className="col-md-10 col-sm-9 col-xs-8">
                <Tags
                  name="supertags"
                  allowNew
                  newTagPrefix="Add New"
                  suggestions={this.props.superTags}
                  onDelete={(i) => this.handleDelete('superTags', i)}
                  onAddition={(tag) => this.handleAddition('superTags', tag)}
                  tags={this.state.superTags}
                  delimiters={delimiters}
                  autocomplete={true}
                  minQueryLength={this.state.showTags ? 0 : 1}
                  inputAttributes={{
                    onClick: () => this.setState({ showTags: true }),
                  }}
                  onInput={(e) => {
                    this.setState({ showTags: !e })
                  }}
                  maxSuggestionsLength={100}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-xs-2">
                <p className="form-label">
                  PHOTOS:
                </p>
                  <label htmlFor="image-uploader">
                    <IconButton icon="plus" btnColor="white" className="add-img-btn"/>
                  </label>
                  <input
                    accept="image/png, image/gif, image/jpeg"
                    id="image-uploader"
                    name="image-uploader"
                    onChange={(e) => this.handleImageUpload(e)}
                    type="file"
                    multiple
                  />
              </div>
              <div className="col-xs-10">
                <div className="row">
                  {this.state.images.map((image, idx) => {
                    if (image.preview) {
                      return (
                        <div key={'image-preview-' + idx} className="col-sm-3">
                          <div className="image-preview" style={{ backgroundImage: `url("${image.preview}")` }} />
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-2">
                <p className="form-label">
                  VIDEOS:
                </p>
                  <label htmlFor="video-uploader">
                    <IconButton icon="plus" btnColor="white" className="add-img-btn"/>
                  </label>
                  <input
                    id="video-uploader"
                    name="video-uploader"
                    onChange={(e) => this.handleVideoUpload(e)}
                    type="file"
                    accept="video/*"
                  />
              </div>
              <div className="col-xs-10">
                <div className="row video-block">
                  {this.state.cloud_key
                    ?
                    <div className="video-preview">
                      <Video
                        publicId={this.state.cloud_key}
                        cloudName={this.context.cloudName}
                        controls={true}
                        width={200}
                      />
                    </div>
                    :
                    <div>
                        {this.state.showProgressBar &&
                          <div className="progress-bar">
                            <div
                              className="progress"
                              role="progressbar"
                              style={{ width: percent + '%' }}
                            />
                          </div>
                        }

                    </div>
                }
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                <p className="form-label">WHY (notes)</p>
              </div>
              <div className="col-md-10">
                <TextareaAutosize 
                    className="create-markive-text-area"
                    onKeyUp={(e) => this.handleKeyUp(e)}
                    style={expandedStyle}
                    name="notes"
                    type="text"
                    onChange={this.handleChange}
                    value={this.state.notes}
                  />
              </div>

              <button id="create-markive-button" className="pull-right submit-button save-button" type="submit">
                Save
              </button>
              <div id="google-map-hide"></div>
            </div>
          </form>
        </div>
        </div>
      </div>
    );
  }
}

function mapStateToProperties(state) {
  return {
    categories: state.suggestionTagData.categories,
    people: state.suggestionTagData.people,
    superTags: state.suggestionTagData.superTags,
    tags: state.suggestionTagData.tags,
  };
}

export default connect(mapStateToProperties)(CreateMarkive);

import {
  FETCH_MARKIVE_TOKEN_BEGIN,
  FETCH_MARKIVE_TOKEN_SUCCESS,
  FETCH_MARKIVE_TOKEN_FAILURE,
  FETCH_MARKIVE_URL_BEGIN,
  FETCH_MARKIVE_URL_SUCCESS,
  FETCH_MARKIVE_URL_FAILURE,
  POST_CHECKBOX,
} from '../constants/action-types';

const initialState = {
  loadingToken: false,
  loadingUrl: false,
  token: '',
  url: '',
  cbDate: false,
  cbTitle: false,
  cbNotes: false,
  cbUrl: false,
  cbCategories: false,
  cbPeople: false,
  cbSuperTags: false,
  cbAddress: false,
  cbImages: false,
};

// Reducer will comprise of the auto complete stuff here since they share the same store
export default function markiveData(state = initialState, action) {
  switch (action.type) {
    case POST_CHECKBOX:
      return {
        ...state,
        [action.checkbox]: action.cbState,
      };
    case FETCH_MARKIVE_TOKEN_BEGIN:
      return {
        ...state,
        loadingToken: true,
      };
    case FETCH_MARKIVE_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.token.url_token,
      };
    case FETCH_MARKIVE_TOKEN_FAILURE:
      return {
        ...state,
        loadingToken: false,
        token: action.errorToken,
      };
    case FETCH_MARKIVE_URL_BEGIN:
      return {
        ...state,
        loadingUrl: true,
      };
    case FETCH_MARKIVE_URL_SUCCESS:
      return {
        ...state,
        url: action.url,
      };
    case FETCH_MARKIVE_URL_FAILURE:
      return {
        ...state,
        ladingUrl: false,
        url: action.errorUrl,
      };
    default:
      return state;
  }
}

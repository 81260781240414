import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router-dom';
import Header from '../../components/Header';
import SearchBox from '../../components/SearchBox';
import FilterBox from '../../components/FilterBox';
import Dropdown from '../../components/Dropdown';
import FilterSingle from '../../components/FilterSingle';
import SearchEntry from '../../components/SearchEntry';
import SearchEntryCard from '../../components/SearchEntryCard';
import { searchMarkive } from '../../async/searchMarkive';
import { quickFindChange } from "../../actions/quickFindAction";
import IconButton from "../../components/IconButton";
import SVGIcon from '../../components/SVGIcon';
import {SHOW_LOAD, HIDE_LOAD} from "../../constants/action-types";
import {locate as locateAction, showModal} from '../../actions/app';
import {
  fetchCategoriesData, fetchPeopleData, fetchSuperTagsData,
  fetchTagsData, fetchYearsData, fetchLocationsData
} from '../../actions/suggestionTagsAction';
import {
  toggleMarkivesCheckboxes,
  addAllSharedMarkives,
  deleteAllSharedMarkives,
  fetchMultipleMarkivesToken
} from '../../actions/shareMultipleMarkivesAction';

class Markive extends Component {
  state = {
    activeFilters: [],
    activeSort: '',
    results: [],
    showFilters: false,
    sortDescending: false,
    isSortListOpen: false,
    isViewListOpen: false,
    activeView: 'Cards',
    term:'',
    location:'',
    date_from:'',
    date_to:'',
    category: '',
    tag: [],
    supertag: [],
    year: [],
    people: [],
    page: 0,
    stopScroll: false,
    orderby: 'name',
    order: '',
    latitude: '',
    longitude: '',
    userLat: '',
    userLng: '',
    token: '',
    prevY: 0,
    loading: false,
    totalLength: 0,
  };

  // Action
  showModal = type => {
    this.props.dispatch(showModal(type));
  };

  componentDidMount() {
    if (this.props.categoriesSuggestions && !this.props.categoriesSuggestions.length) {
      this.props.dispatch(fetchCategoriesData());
    }
    if (this.props.peopleSuggestions && !this.props.peopleSuggestions.length) {
      this.props.dispatch(fetchPeopleData());
    }
    if (this.props.tagsSuggestions && !this.props.tagsSuggestions.length) {
      this.props.dispatch(fetchTagsData());
    }
    if (this.props.superTagsSuggestions && !this.props.superTagsSuggestions.length) {
      this.props.dispatch(fetchSuperTagsData());
    }
    if (this.props.locationsSuggestions && !this.props.locationsSuggestions.length) {
      this.props.dispatch(fetchLocationsData());
    }
    if (this.props.yearsSuggestions && !this.props.yearsSuggestions.length) {
      this.props.dispatch(fetchYearsData());
    }


    const urlQuery = new URLSearchParams(window.location.search);
    let date = urlQuery.getAll('date_from')[0];
    if (date) {
      date = date.slice(0, -6);
    }
    const filterObject = {
      Location: urlQuery.get('location'),
      Tag: urlQuery.getAll('tag'),
      SuperTag: urlQuery.getAll('supertag'),
      Year: urlQuery.getAll('year'),
      People: urlQuery.getAll('people'),
      Date: date,
    }
    const hasAdvancedFilters = urlQuery.has('location') || urlQuery.has('date_from') || urlQuery.has('people') || urlQuery.has('tag') || urlQuery.has('supertag') || urlQuery.has('year');

    const advancedFilters = [];
    // TODO: Handle this within the addFilter function
    // Issue w/ state currently, so much be added manually to array right now
    for (const [key, value] of Object.entries(filterObject)) {
      if (value && value.length) {
        if (Array.isArray(value)) {
          value.forEach((val) => {
            advancedFilters.push(`${key}: ${val}`)
          });
        } else {
          advancedFilters.push(`${key}: ${value}`)
        }
      }
    }

    const activeViewValue = window.localStorage.getItem('activeView');

    this.setState({
      showFilters: hasAdvancedFilters,
      activeFilters: advancedFilters,
      activeView: activeViewValue,
    });

    this.handleURLParamSubmit();

    var options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0
    };

    this.observer = new IntersectionObserver(
      this.handleObserver.bind(this),
      options
    );
    this.observer.observe(this.loadingRef);
  }

  handleURLParamSubmit() {
    const urlQuery = new URLSearchParams(window.location.search);

    this.setState({
      category: urlQuery.get('category') || '',
      term: urlQuery.get('term') || '',
      location: urlQuery.get('location') || '',
      date_from: urlQuery.get('date_from') || '',
      date_to: urlQuery.get('date_to') || '',
      people: urlQuery.getAll('people') || '',
      tag: urlQuery.getAll('tag') || '',
      supertag: urlQuery.getAll('supertag') || '',
      year: urlQuery.getAll('year') || '',
      orderby: urlQuery.getAll('orderby') || '',
      order: urlQuery.getAll('order') || '',
      page: 0,
      longitude: urlQuery.getAll('longitude') || '',
      latitude: urlQuery.getAll('latitude') || '',
    }, this.handleSubmit);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.handleURLParamSubmit();
    }
  }

  handleObserver(entities, observer) {
    const y = entities[0].boundingClientRect.y;

    if (this.state.prevY > y && !this.state.loading) {
      this.setState({ loading: true });
      const page = parseInt(this.state.page) + 1;
      this.getPage(page);
    }
    this.setState({ prevY: y });
  }

  handleChange(event, notEvent=false) {
    if (notEvent) {
      // This accounts for pressing on an autocorrect btn. There's likely a better
      // implementation here.
      this.setState({ category: event });
      return true;
    }
    if(event.target.name) {
      this.setState({ [event.target.name]: event.target.value });
    }
  }

  sortBy = async (category) => {
    this.props.dispatch({
      type: SHOW_LOAD,
    });
    this.props.dispatch(quickFindChange(''));
    const {
      locate
    } = this.props;
    let orderby;

    if(category === "Proximity") {
      if (this.props.latitude) {
        this.setState({
          orderby: 'proximity',
          order: 'ASC',
          latitude: this.props.latitude,
          longitude: this.props.longitude,
          activeSort: 'Proximity',
          sortDescending: false,
          isSortListOpen: false,
          page: 0,
        }, this.handleSubmit);
      } else {
        await locate()
          .then(() => {
            this.setState({
              orderby: 'proximity',
              order: 'ASC',
              latitude: this.props.latitude,
              longitude: this.props.longitude,
              activeSort: 'Proximity',
              sortDescending: false,
              isSortListOpen: false,
              page: 0,
            }, this.handleSubmit);
          });
      }

    } else {
      if(category === "Name") {
        orderby = 'name';
      }
      if(category === "Time") {
        orderby = 'date';
      }
      this.setState({
        orderby: orderby,
        order: 'ASC',
        activeSort: category,
        sortDescending: false,
        isSortListOpen: false,
        page: 0,
      }, this.handleSubmit);
    }

    this.props.dispatch({
      type: HIDE_LOAD,
    });
  };

  reverseSort() {
    this.setState({
      sortDescending: !this.state.sortDescending,
      order: this.state.sortDescending ? 'ASC' : 'DESC',
      page: 0,
    }, this.handleSubmit);
  }

  toggleSortList = () => {
    this.setState(prevState => ({
      isSortListOpen: !prevState.isSortListOpen
    }))
  };

  toggleViewList = () => {
    this.setState(prevState => ({
      isViewListOpen: !prevState.isViewListOpen
    }))
  };

  toggleShare = () => {
    this.props.dispatch(toggleMarkivesCheckboxes());
  };

  addAllToShare = () => {
    const ids = this.state.results.map((item) => item.id);
    this.props.dispatch(addAllSharedMarkives(ids));
  }

  deleteAllFromShare = () => {
    this.props.dispatch(deleteAllSharedMarkives());
  }

  updateView = (view) => {
    window.localStorage.setItem("activeView", view);
    this.setState({
      activeView: view
    });
  };

  addFilter(value, category, callSubmit=true) {
    if (value == '') {
      return;
    }

    var data = {
      tag: [...this.state.tag],
      supertag: [...this.state.supertag],
      year: [...this.state.year],
      people: [...this.state.people],
      location: this.state.location,
      date_from: this.state.date_from,
      date_to: this.state.date_to,
      page: 0,
    };

    // Array -> [nameOfState, overwrite (True=overwrite, False=append)]
    const valueKeys = {
      Tags: ['tag', false],
      SuperTags: ['supertag', false],
      People: ['people', false],
      Location: ['location', true],
      Date: ['date', true],
      Year: ['year', false]
    }

    const valueKey = valueKeys[category][0];
    const valueOverwrite = valueKeys[category][1];

    const newFilters = [...this.state.activeFilters];
    for(var i = 0; i < this.state.activeFilters.length; i++) {
      var result = this.state.activeFilters[i].split(': ');
      if (valueOverwrite && result[0] === category) {
          newFilters.splice(i, 1);
          break;
      }
    }


    let activeFilters;
    if (valueOverwrite) {
      data[valueKey] = value;
      activeFilters = [...newFilters, category + ': ' + value];
    } else {
      const idx = data[valueKey].indexOf(value);
      if (idx < 0) {
        data[valueKey].push(value);
        activeFilters = [...newFilters, category + ': ' + value];
      } else {
        if (valueKey === 'year') {
          data[valueKey].splice(idx, 1);
          var index = newFilters.indexOf('Year: '+value);
          if (index !== -1) newFilters.splice(index, 1)
        }
        activeFilters = newFilters;
      }
    }
    this.setState({
      ...data,
      activeFilters: activeFilters
    }, () => {
      if (callSubmit) {
        this.handleSubmit();
      }
    })
  };

  clearFilters() {
    this.setState({
      activeFilters: [],
      location: '',
      tag: [],
      supertag: [],
      year: [],
      people: [],
      date_from: '',
      date_to: '',
    }, this.handleSubmit);
  }

  showFilters() {
    this.setState({ showFilters: !this.state.showFilters})
    if(this.state.activeFilters[0] !== undefined) {
      this.clearFilters();
    }
  }

  removeFilter =  async (filter) => {
    var arrFilter = filter.split(': ');
    var activeFilters = [...this.state.activeFilters];
    var index = activeFilters.indexOf(filter);
    if (index !== -1) activeFilters.splice(index, 1);
    this.setState({ activeFilters });

    var data = {
      tag: [...this.state.tag],
      supertag: [...this.state.supertag],
      year: [...this.state.year],
      people: [...this.state.people],
      location: this.state.location,
      date_form: this.state.date_from,
      date_to: this.state.date_to,
      category: this.state.category
    }
    if(arrFilter[0] === 'Tags' || arrFilter[0] === 'Tag') {
      var indexFilter = data['tag'].indexOf(arrFilter[1]);
      if (indexFilter > -1) {
        data['tag'].splice(indexFilter, 1);
      }
    } else if(arrFilter[0] === 'People') {
      var indexFilter = data['people'].indexOf(arrFilter[1]);
      if (indexFilter > -1) {
        data['people'].splice(indexFilter, 1);
      }
    } else if(arrFilter[0] === 'SuperTag' || arrFilter[0] === 'SuperTags') {
      var indexFilter = data['supertag'].indexOf(arrFilter[1]);
      if (indexFilter > -1) {
        data['supertag'].splice(indexFilter, 1);
      }
    } else if(arrFilter[0] === 'Year') {
      var indexFilter = data['year'].indexOf(arrFilter[1]);
      if (indexFilter > -1) {
        data['year'].splice(indexFilter, 1);
      }
    } else if(arrFilter[0] === 'Location') {
      data['location'] = '';
    } else if(arrFilter[0] === 'Date') {
      data['date_from'] = '';
      data['date_to'] = '';
    }

    this.setState({
      ...data
    }, this.handleSubmit)
  }

  concatString(arr) {
    return [...arr].join(' ').trim();
  }

  getMainSearchParameters() {
    const urlParams = new URLSearchParams(window.location.search);

    const mainParams = this.concatString([
      urlParams.get('category'),
      urlParams.get('term')
    ]);
    if (mainParams) {
      return (
        <React.Fragment>
          for <span>{ mainParams }</span>
        </React.Fragment>
      )
    }
    return '';
  }
  // SHARE LINK MODAL POP UP
  handleShareLink = (type) => {
    this.props.dispatch(showModal(type));
  }

  shareLink = () => {
    // event.preventDefault();
    var data = {
      // Reason for the lat and lng having the same value is because they are tied to the address...
      markives: this.props.cbArray
    };


    this.props.dispatch(fetchMultipleMarkivesToken(data));
    this.props.dispatch(showModal(false));

    this.handleShareLink('multipleShareLinkModal');
  };

  handleSubmit = async (data) => {
    var query = data || {
      term: this.state.term,
      location: this.state.location,
      date_from: this.state.date_from,
      date_to: this.state.date_to,
      category: this.state.category ? [this.state.category] : '',
      tag: this.state.tag,
      supertag: this.state.supertag,
      year: this.state.year,
      people: this.state.people,
      page: this.state.page,
      orderby: this.state.orderby,
      order: this.state.order,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
    }

    const urlParams = new URLSearchParams();

    for (const [key, value] of Object.entries(query)) {
      if (value.length) {
        if (Array.isArray(value)) {
          value.forEach((val, i) => {
            if (i === 0) {
              urlParams.set(key, val);
            } else {
              urlParams.append(key, val);
            }
          });
        } else {
          urlParams.set(key, value);
        }
      }
    }
    let urlString = urlParams.toString();
    if (urlString) {
      urlString = `?${urlString}`;
    }
    if (this.props.quickFind === "nearby") {
      this.props.dispatch(quickFindChange(''));
      this.sortBy('Proximity');
    } else if(this.props.quickFind === "recent") {
      this.props.dispatch(quickFindChange(''));
      this.sortBy('Time');
    } else {
      window.history.pushState({}, '', `${location.pathname}${urlString}`);

      // Uses new async version of Search
      this.props.dispatch({
        type: SHOW_LOAD,
      });

      var results = await searchMarkive(query);

      this.props.dispatch({
        type: HIDE_LOAD,
      });
      let totalLength = 0;
      if (results.length !== 0) {
        totalLength = results[0].total_length;
      }

      if(results) {
        this.setState({
          results,
          totalLength: totalLength
        });
      }
    }
  }
  getPage = async (page) => {
    var query = {
      term: this.state.term,
      location: this.state.location,
      date_from: this.state.date_from,
      date_to: this.state.date_to,
      category: this.state.category ? [this.state.category] : '',
      tag: this.state.tag,
      year: this.state.year,
      people: this.state.people,
      page: page,
      orderby: this.state.orderby,
      order: this.state.order,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
    }
    var results = await searchMarkive(query);
    if(results) {
      if (results.length > 0) {
        this.setState({
          results: [...this.state.results, ...results],
          loading: false,
          page: results[0].page
        });
      } else {
        this.setState({
          stopScroll: false,
          loading: false,
        });
      }
    }
  }
  render() {
    const loadingCSS = {
      height: "50px",
      margin: "30px",
      position: "absolute",
      bottom: '0',
      width: '50px',
      right: '0',
    };
    const loadingTextCSS = { display: this.state.loading ? "block" : "none" };
    return (
      <div className="page-search">
        <Header {...this.props} />
        <div className="search-container-fluid pt-4">
          <SearchBox
            handleSubmit={() => this.handleSubmit()}
            term={this.state.term}
            category={this.state.category}
            date_from={this.state.date_from}
            date_to={this.state.date_to}
            handleChange={(e, isAutofilled) => this.handleChange(e, isAutofilled)}
          />

          <div className='nearby-filter-buttons-block mobile-only'>
            <div
              className={`filter-button-fixed nearby-btn base-label-large`}
              onClick={() => this.sortBy('Proximity')}
            >
              NEARBY
            </div>
            <div className='add-search-block'>
              <span className="add-search-no-mobile">
                <IconButton onClick={this.showModal.bind(this, 'createMarkiveModal')} icon="add-two" tooltip="Add" btnColor="blue" hoverColor="grey" outlineColor="blue"/>
              </span>
              <span>
                <IconButton onClick={() => this.handleSubmit()} icon="search" tooltip="Find" btnColor="blue" hoverColor="grey" outlineColor="blue"/>
              </span>
            </div>
            <div
              className={`filter-button-fixed filters-btn ${this.state.showFilters ? "filters-show" : "filters-hide"} base-label-large`}
              onClick={() => this.showFilters()}
            >
              {this.state.showFilters ? 'CLEAR FILTERS' : 'FILTERS' }
            </div>
          </div>

          <div className={`filters-container-${this.state.showFilters ? "show" : "hide"}`}>
            <div className="row">
              <div className="col-xs-6">
                <Dropdown handleSubmit={(v, l) => this.addFilter(v, l)} label="When" filter="Year"
                          list={this.props.yearsSuggestions} activeList={this.state.year} />
              </div>
              <div className="col-xs-6">
                <FilterBox
                  handleSubmit={(v, l) => this.addFilter(v, l)}
                  label="Where"
                  filter="Location"
                  options={this.props.locationsSuggestions}/>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-6">
                <FilterBox
                  handleSubmit={(v, l) => this.addFilter(v, l)}
                  label="Who"
                  filter="People"
                  options={this.props.peopleSuggestions}
                />
              </div>
              <div className="col-xs-6">
                <FilterBox
                  handleSubmit={(v, l) => this.addFilter(v, l)}
                  label="Tags"
                  filter="Tags"
                  options={this.props.tagsSuggestions}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-offset-2 col-xs-8 col-lg-6">
                <FilterBox
                  handleSubmit={(v, l) => this.addFilter(v, l)}
                  label="SuperTags"
                  filter="SuperTags"
                  options={this.props.superTagsSuggestions}
                />
              </div>
            </div>
            <div className="search-filters search-filters--mobile">
              <div>
                <span className="base-label">SORT BY:</span>
                <div className="sort-filter-button" onClick={() => {this.toggleSortList()}}>
                  {this.state.activeSort || 'Name'}
                  <svg style={{ transform: this.state.isSortListOpen ? 'rotate(180deg)': ''}}
                       width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.791687 8.375L7.50002 1.66667L14.2084 8.375" stroke="#212429" strokeWidth="2"/>
                  </svg>
                  <div className={`sort-filter-list ${this.state.isSortListOpen ? "show" : "hide"}`}>
                    <a onClick={() => this.sortBy('Time')} className={this.state.activeSort === 'Time' ? 'active' : undefined}>
                      <span>Time</span>
                      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 8.5L10 16.5L6 12.5" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                      </svg>
                    </a>
                    <hr/>
                    <a onClick={() => this.sortBy('Proximity')} className={this.state.activeSort === 'Proximity' ? 'active' : undefined}>
                      <span>Proximity</span>
                      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 8.5L10 16.5L6 12.5" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                      </svg>
                    </a>
                    <hr/>
                    <a onClick={() => this.sortBy('Name')} className={this.state.activeSort === 'Name' ? 'active' : undefined}>
                      <span>Name</span>
                      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 8.5L10 16.5L6 12.5" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                      </svg>
                    </a>
                  </div>
                </div>
                <a className="sort-filter-reverse" onClick={() => this.reverseSort()}>
                  <SVGIcon id="up-down" color={this.state.sortDescending ? 'blue' : 'dark-grey'} />
                </a>
              </div>
              <div>
                <span className="base-label">VIEW:</span>
                <div className="sort-filter-button" onClick={() => {this.toggleViewList()}}>
                  {this.state.activeView}
                  <svg style={{ transform: this.state.isViewListOpen ? 'rotate(180deg)': ''}}
                       width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.791687 8.375L7.50002 1.66667L14.2084 8.375" stroke="#212429" strokeWidth="2"/>
                  </svg>
                  <div className={`sort-filter-list ${this.state.isViewListOpen ? "show" : "hide"}`}>
                    <a onClick={() => {this.updateView('Cards')}} className={this.state.activeView === 'Cards' ? 'active' : undefined}>
                      <span>Cards</span>
                      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 8.5L10 16.5L6 12.5" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                      </svg>
                    </a>
                    <hr/>
                    <a onClick={() => this.updateView('Rows')} className={this.state.activeView === 'Rows' ? 'active' : undefined}>
                      <span>Rows</span>
                      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 8.5L10 16.5L6 12.5" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                      </svg>
                    </a>
                    <hr/>
                  </div>
                </div>
              </div>

            </div>

          </div>

          <div className='nearby-filter-buttons-block desktop-only'>
            <div
              className={`filter-button-fixed nearby-btn base-label-large`}
              onClick={() => this.sortBy('Proximity')}
            >
              NEARBY
            </div>
            <div className='add-search-block'>
              <span className="add-search-no-mobile">
                <IconButton onClick={this.showModal.bind(this, 'createMarkiveModal')} icon="add-two" tooltip="Add" btnColor="blue" hoverColor="grey" outlineColor="blue"/>
              </span>
              <span>
                <IconButton onClick={() => this.handleSubmit()} icon="search" tooltip="Find" btnColor="blue" hoverColor="grey" outlineColor="blue"/>
              </span>
            </div>
            <div
              className={`filter-button-fixed filters-btn ${this.state.showFilters ? "filters-show" : "filters-hide"} base-label-large`}
              onClick={() => this.showFilters()}
            >
              {this.state.showFilters ? 'CLEAR FILTERS' : 'FILTERS' }
            </div>
          </div>
          

            <div className={`filters-container-${this.state.showFilters ? "show" : "hide"}`}>
              <div>
                <p className="base-label-large search-label-large">
                  {this.state.activeFilters.length || 'NO'} ACTIVE FILTERS
                </p>
              </div>

              <div>
                {this.state.activeFilters.map((tag, idx) => {
                  return (
                    <FilterSingle
                      key={'tag-' + idx}
                      name={tag}
                      withClose={true}
                      handleClick={() => this.removeFilter(tag)}
                    />
                  );
                })}
              </div>

          </div>
        </div>
        <div className="search-results">
          <div className="search-container-fluid">
            <div className="row pb-md-5 pb-sx-1">
              <div className="col-xs-6 pt-0">
              {this.state.results ?
              (<h2 className="search-result-headline">{ this.state.totalLength }</h2>)
              :
              (<h2 className="search-result-headline">0</h2>)
              }
                <a className="search-result-headline-share d-none d-md-inline" onClick={() => this.toggleShare()}>Share</a>
              </div>
              <div className="col-xs-6 pt-0">
                <div className="search-filters search-filters--desktop">
                  <div>
                    <span className="base-label">SORT BY:</span>
                    <div className="sort-filter-button" onClick={() => {this.toggleSortList()}}>
                      {this.state.activeSort || 'Name'}
                      <svg style={{ transform: this.state.isSortListOpen ? 'rotate(180deg)': ''}}
                           width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.791687 8.375L7.50002 1.66667L14.2084 8.375" stroke="#212429" strokeWidth="2"/>
                      </svg>
                      <div className={`sort-filter-list ${this.state.isSortListOpen ? "show" : "hide"}`}>
                        <a onClick={() => this.sortBy('Time')} className={this.state.activeSort === 'Time' ? 'active' : undefined}>
                          <span>Time</span>
                          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 8.5L10 16.5L6 12.5" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                          </svg>
                        </a>
                        <hr/>
                        <a onClick={() => this.sortBy('Proximity')} className={this.state.activeSort === 'Proximity' ? 'active' : undefined}>
                          <span>Proximity</span>
                          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 8.5L10 16.5L6 12.5" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                          </svg>
                        </a>
                        <hr/>
                        <a onClick={() => this.sortBy('Name')} className={this.state.activeSort === 'Name' ? 'active' : undefined}>
                          <span>Name</span>
                          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 8.5L10 16.5L6 12.5" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                          </svg>
                        </a>
                      </div>
                    </div>
                    <a className="sort-filter-reverse" onClick={() => this.reverseSort()}>
                      <SVGIcon id="up-down" color={this.state.sortDescending ? 'blue' : 'dark-grey'} />
                    </a>
                  </div>
                  <div>
                    <span className="base-label">VIEW:</span>
                    <div className="sort-filter-button" onClick={() => {this.toggleViewList()}}>
                      {this.state.activeView}
                      <svg style={{ transform: this.state.isViewListOpen ? 'rotate(180deg)': ''}}
                           width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.791687 8.375L7.50002 1.66667L14.2084 8.375" stroke="#212429" strokeWidth="2"/>
                      </svg>
                      <div className={`sort-filter-list ${this.state.isViewListOpen ? "show" : "hide"}`}>
                        <a onClick={() => {this.updateView('Cards')}} className={this.state.activeView === 'Cards' ? 'active' : undefined}>
                          <span>Cards</span>
                          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 8.5L10 16.5L6 12.5" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                          </svg>
                        </a>
                        <hr/>
                        <a onClick={() => this.updateView('Rows')} className={this.state.activeView === 'Rows' ? 'active' : undefined}>
                          <span>Rows</span>
                          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 8.5L10 16.5L6 12.5" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                          </svg>
                        </a>
                        <hr/>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex d-md-none justify-content-end">
                  <a className="search-result-headline-share" onClick={() => this.toggleShare()}>Share</a>
                </div>
              </div>
            </div>
            <div className={`row search-result-row search-result-row__header ${this.state.activeView === 'Rows' ? "show" : "hide"}`}>
              <div className="col-xs-3">
                <p className="search-result-labels">What</p>
              </div>
              <div className="col-xs-3">
                <p className="search-result-labels">Where</p>
              </div>
              <div className="col-xs-3">
                <p className="search-result-labels">When</p>
              </div>
              <div className="col-xs-3">
                <p className="search-result-labels">Who</p>
              </div>
            </div>
            <div className="results">
              {this.state.results ?
                (this.state.results.map((result, idx) => {
                  if (this.state.activeView === 'Cards' || window.innerWidth < 768) {
                    return <SearchEntryCard key={'search-result-' + result.id} {...result} row={(window.innerWidth < 768 && this.state.activeView !== 'Cards')}/>
                  }
                  return <SearchEntry key={'search-result-' + result.id} {...result} />
                }))
                :
                (<div>
                  <p>There is nothing to see here...</p>
                </div>)
              }
              { !this.state.stopScroll &&
              (<div
                ref={loadingRef => (this.loadingRef = loadingRef)}
                style={loadingCSS}
              >
                {/*<span style={loadingTextCSS}>Loading...</span>*/}
              </div>)
              }
            </div>
          </div>
        </div>
        {
          (this.props.showMarkivesCheckboxes) &&
          <div className="multiple-share-popup">
            <div className="multiple-share-popup__title">{this.props.cbArray.length} ITEMS TO SHARE</div>
            {(this.props.cbArray.length > 0) ?
              (<a className="multiple-share-popup__toggle-button" onClick={() => {this.deleteAllFromShare()}}>Deselect All</a>)
              :
              (<a className="multiple-share-popup__toggle-button" onClick={() => {this.addAllToShare()}}>Select All</a>)
            }


            <a className="multiple-share-popup__share-button" onClick={() => {this.shareLink()}}>
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22.8246 8.33491C22.8284 8.05849 22.7198 7.79846 22.5339 7.64503L17.0007 2.3381C16.7902 2.11088 16.49 2.06052 16.238 2.21044C15.986 2.36036 15.8302 2.68128 15.8406 3.02799V5.33654C13.1098 5.48647 11.7326 6.59565 11.5156 6.77603C8.47043 9.23451 8.25343 12.8023 8.56781 14.3318L8.68864 14.9315C8.7566 15.2653 8.97276 15.5218 9.24367 15.5909H9.38903C9.61746 15.5909 9.83173 15.4574 9.9686 15.2313L10.2829 14.6914C11.578 12.4999 13.651 11.2246 15.8408 11.2737V13.6725C15.8351 14.0192 15.9965 14.3378 16.2514 14.4818C16.494 14.6353 16.7866 14.6001 17.0009 14.3916L22.534 9.05413C22.72 8.88781 22.8286 8.6196 22.8248 8.33498L22.8246 8.33491ZM17.2904 11.8734V10.4643C17.2942 10.0099 17.0242 9.62454 16.6627 9.56481C14.1829 9.20875 11.708 10.2804 9.94473 12.4731C10.0901 11.3334 10.597 9.655 12.313 8.27524H12.3376C12.3621 8.24479 13.6666 7.1356 16.4702 7.1356H16.5665H16.5655C16.961 7.12037 17.2782 6.72682 17.2905 6.23607V4.85631L20.9152 8.33509L17.2904 11.8734Z" fill="#4294F4"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M4.59785 21.8749H16.8091C17.4517 21.8749 18.0678 21.5711 18.5221 21.031C18.9763 20.4909 19.2319 19.7584 19.2319 18.9944V14.4429H17.7782V18.9944C17.7782 19.3004 17.676 19.593 17.4943 19.809C17.3126 20.0251 17.0656 20.1466 16.8091 20.1466H4.59785C4.06314 20.1466 3.62874 19.6312 3.62874 18.9944V8.59633C3.62874 7.95948 4.06314 7.44412 4.59785 7.44412H10.0491V5.71582H4.59785C3.2606 5.71582 2.17508 7.00531 2.17508 8.59633V18.9944C2.17508 19.7584 2.43061 20.4909 2.88488 21.031C3.33915 21.5711 3.95526 21.8749 4.59785 21.8749Z" fill="#4294F4"/>
              </svg>
              Share
            </a>
          </div>
        }

      </div>
    );
  }
}

function mapStateToProperties(state) {
  return {
    longitude: state.app.longitude,
    latitude: state.app.latitude,
    located: state.app.located,
    categoriesSuggestions: state.suggestionTagData.categories,
    peopleSuggestions: state.suggestionTagData.people,
    superTagsSuggestions: state.suggestionTagData.superTags,
    tagsSuggestions: state.suggestionTagData.tags,
    locationsSuggestions: state.suggestionTagData.locations,
    yearsSuggestions: state.suggestionTagData.years,
    quickFind: state.markiveData.quickFind,
    showMarkivesCheckboxes: state.shareMultipleMarkives.showMarkivesCheckboxes,
    cbArray: state.shareMultipleMarkives.cbArray,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    locate: () => dispatch(locateAction()),
    dispatch
  };
}

export default connect(
  mapStateToProperties,
  mapDispatchToProps,
)(Markive);

import React, { useEffect, useState } from 'react';

/* 
  props: {
    url,
  }
*/

const MarkiveUrl = (props) => {
  const [href, setHref] = useState();
  const [shownUrl, setShownUrl] = useState();

  useEffect(() => {
    if (props.url) {
      const hasProtocol = props.url.startsWith('http');
      const url = new URL(hasProtocol ? props.url : 'http://' + props.url);
      setHref(url.href);
      setShownUrl(url.hostname + (url.pathname !== '/' || url.hash.length || url.search.length ? '...' : ''));
    }
  }, [props.url]);

  return (
    <a className="markive-url" target="_blank" href={href}>{shownUrl}</a>
  );
}

export default MarkiveUrl;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { authSuccess } from '../../actions/app';
import axios from 'axios';
import IconButton from '../../components/IconButton';
import LogoWhite from '../../styles/assets/images/logoWhite';
import {Link} from "react-router-dom";

class SignUp extends Component {
  // This state should be separate from the login state
  state = {
    first: '',
    last: '',
    email: '',
    password: '',
    status: window.localStorage.token
  };

  validateForm = () => {
    return this.state.email.length > 0 && this.state.password.length > 0;
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleSubmit = event => {
    var shareToken = this.props.match.params.token;

    var data = {
      first: this.state.first,
      last: this.state.last,
      email: this.state.email,
      password: this.state.password
    };

    // If this is an account creation from a shared Markive,
    // send the token to the backend
    if (shareToken) {
      data['share'] = shareToken;
    }

    var login = {
      email: this.state.email,
      password: this.state.password,
    }

    axios
      .post('/api/sign-up', data)
      .then(resp => {
        if (resp.data.data.id) {
          alert('You have successfully signed up!');
          // this.props.history.push('/password-login');
          return axios.post('/api/password-login', login)
          .then(resp => {
            var token = resp.data.data.access_token;
            if (!token) {
              return;
            }
            this.props.dispatch(authSuccess(token));
            if (shareToken) {
              this.props.history.goBack()
            } else {
              this.props.history.push('/search?orderby=date&order=ASC');
            }
          })
          .catch(err => {
            console.log(err);
          })
        } else {
          alert('You have successfully signed up. Your account is pending approval.');
        }
      })
      .catch(err => {
        alert('Sign up unsuccessful, please try again.');
        console.log(err);
      });

    event.preventDefault();
  };

  render() {
    return (
      <div className="page-sign-up">
        <div className="header" ref="header">
        {this.state.status ? (
          <a className="header-home-link" href="/search?orderby=date&order=ASC" >
            <LogoWhite />
          </a>
        ) : (
          <a className="header-home-link" href="/" >
            <LogoWhite />
          </a>
        )}
          <IconButton 
            onClick={this.props.onRequestClose}
            className="modal-close"
            icon="close"
            iconColor="dark-grey" 
            btnColor="transparent" 
          />
        </div>


        <div className="sign-up-wrapper">
        <div className="container">
        <div className="content">
          <div className="sign-up-title">
            <h1>Sign Up</h1>
          </div>
          <div className="sign-up">
            <form onSubmit={this.handleSubmit}>
              <div className="row">
              <div className="col-md-4">
                <p className="form-label">First Name</p>
              </div>
              <div className="col-md-8">
                <input
                  name="first"
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.first}
                  />
              </div>
            </div>

              <div className="row">
              <div className="col-md-4">
                <p className="form-label">Last Name</p>
              </div>
              <div className="col-md-8">
                <input
                  name="last"
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.last}
                  />
              </div>
            </div>

              <div className="row">
              <div className="col-md-4">
                <p className="form-label">Email</p>
              </div>
              <div className="col-md-8">
                <input
                  name="email"
                  type="email"
                  onChange={this.handleChange}
                  value={this.state.email}
                  />
              </div>
            </div>

              <div className="row">
              <div className="col-md-4">
                <p className="form-label">Password</p>
              </div>
              <div className="col-md-8">
                <input
                  name="password"
                  type="password"
                  onChange={this.handleChange}
                  value={this.state.password}
                  />
              </div>
            </div>
              <button className="pull-right save-button" disabled={!this.validateForm()} type="submit">
                Sign Up
              </button>
            </form>
          </div>
        </div>
        </div>
        </div>
        <footer className="footer">
          <Link to="/terms">Terms of use</Link>
          <Link to="/privacy">privacy policy</Link>
        </footer>
      </div>
    );
  }
}

function mapStateToProperties(state) {
  return {};
}

export default connect(mapStateToProperties)(SignUp);

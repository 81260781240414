import React, { useEffect, useState } from 'react';
import { images } from '../lib/images';

const PrivateImage = (props) => {
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState('');
  const [objectURL, setObjectURL] = useState();
  const [isPortrait, setIsPortrait] = useState();
  const fetchImage = () => {
    setLoading(true);
    setUrl(props.url);
    Promise.resolve(images.getObjectURL(props.url))
      .then(objUrl => {
        setLoading(false);
        setObjectURL(objUrl);
      });
  }
  const onImgLoad = (e) => {
    setIsPortrait(e.currentTarget.naturalHeight > e.currentTarget.naturalWidth);
  }

  useEffect(() => {
    if (url !== props.url && !loading) {
      fetchImage(props.url);
    }
  }, [props.url]);

  return (
    <div
        id={props.id}
        className={`private-img ${props.className}`}
        onClick={props.onClick}
        style={{ backgroundImage: `url('${objectURL || props.thumbnail}')`, backgroundSize: 'cover' }}
        data-portrait={isPortrait}
      >
        { isPortrait === undefined ? <img src={objectURL || props.thumbnail} onLoad={onImgLoad} /> : null }
        {props.children}
        <div className={`lds-ring${loading ? ' show' : ''}`}>
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
  )
}

export default PrivateImage;

import React from "react";
import PrivateImage from "./PrivateImage";

const DraggableImage = ({ image, idx, provided, snapshot, onHandleDeleteImage, onHandleAssignImageThumbnail }) => {

  return (
    <div
      ref={provided.innerRef}
      snapshot={snapshot}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <div
        key={"image-preview-" + idx}
        className="draggable-image"
      >
        <PrivateImage id={"preview-img" + idx} className="image-preview" url={image.url}/>
        <div
          id={"image-preview-" + idx}
          className="delete-image-button"
          onClick={() => {
            onHandleDeleteImage(idx)
          }}
        >
          &#10005;
        </div>
        <div
          id={"image-preview-" + idx}
          className="thumbnail-image-button"
          data-thumbnail={!!image.is_thumbnail}
          onClick={() => {
            onHandleAssignImageThumbnail(idx)
          }}
          >
          &#9733;
        </div>
      </div>
      
    </div>
    // <div
    //   ref={provided.innerRef}
    //   snapshot={snapshot}
    //   {...provided.draggableProps}
    //   {...provided.dragHandleProps}
    // >
    //   <span>{image.image_id}</span>
    //     <span>{image.image_id}</span>
    //     <div>
    //       {image.image_id}
    //     </div>
    // </div>
  );
};

export default DraggableImage;

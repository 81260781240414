import React, { Component } from 'react';

var GetStartedButton = props => {
  return (
    <div className="home-button">
      <a onClick={props.handleClick}>
        <p className="home-button-text">GET STARTED</p>
      </a>
    </div>
  );
};
export default GetStartedButton;

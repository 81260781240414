import { axios } from '../lib/axios';
import {
  FETCH_MARKIVE_URL_BEGIN,
  FETCH_MARKIVE_URL_SUCCESS,
  FETCH_MARKIVE_URL_FAILURE,
  FETCH_MARKIVE_TOKEN_BEGIN,
  FETCH_MARKIVE_TOKEN_SUCCESS,
  FETCH_MARKIVE_TOKEN_FAILURE,
  POST_CHECKBOX,
} from "../constants/action-types";

export function postCheckbox(checkbox, cbState) {
  return {
    type: POST_CHECKBOX,
    checkbox,
    cbState,
  }
}

// FETCH MARKIVE DATA
export function fetchMarkiveToken(data, id) {
  return dispatch => {
    dispatch(fetchMarkiveTokenBegin());

    axios
      .post("/api/share/" + id, data)
      .then(res => {
        if(res && res.data && res.data.data) {
          dispatch(fetchMarkiveTokenSuccess(res.data.data));
        }
      })
      .catch(err => {
        // TODO: this doesn't handle failure gracefully
        dispatch(fetchMarkiveTokenFailure(err));
      });
  };
}

export function fetchMarkiveTokenBegin() {
  return {
    type: FETCH_MARKIVE_TOKEN_BEGIN
  };
}

export function fetchMarkiveTokenSuccess(token) {
  return {
    type: FETCH_MARKIVE_TOKEN_SUCCESS,
    token,
  };
}

export function fetchMarkiveTokenFailure(error) {
  return {
    type: FETCH_MARKIVE_TOKEN_FAILURE,
    errorToken: error
  };
}

export function fetchMarkiveUrl(shareToken) {
  return dispatch => {
    dispatch(fetchMarkiveUrlBegin());

    axios
      .get("/api/share/" + shareToken)
      .then(res => {
        if(res && res.data && res.data.data) {
          dispatch(fetchMarkiveUrlSuccess(res.data.data));
        }
      })
      .catch(err => {
        dispatch(fetchMarkiveUrlFailure(err));
      });
  };
}

export function fetchMarkiveUrlBegin() {
  return {
    type: FETCH_MARKIVE_URL_BEGIN
  };
}

export function fetchMarkiveUrlSuccess(url) {
  return {
    type: FETCH_MARKIVE_URL_SUCCESS,
    url,
  };
}

export function fetchMarkiveUrlFailure(error) {
  return {
    type: FETCH_MARKIVE_URL_FAILURE,
    errorUrl: error
  };
}

export default {
  fetchMarkiveToken,
  fetchMarkiveUrl,
  postCheckbox
};

// ALL ACTION TYPES LISTED HERE FOR THE GLOBAL STORE

// SYSTEM
export const APP_LOAD = "APP_LOAD";

// AUTH
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

// MODAL
export const SHOW_MODAL = "SHOW_MODAL";
export const UPDATE_MODAL_MARKIVE = "UPDATE_MODAL_MARKIVE";

// LOADING
export const SHOW_LOAD = "SHOW_LOAD";
export const SHOW_LOAD_IN_BACKGROUND = "SHOW_LOAD_IN_BACKGROUND";
export const HIDE_LOAD = "HIDE_LOAD";

// PLACEHOLDER
export const DATE_INPUT = "DATE_INPUT";

// GET MARKIVE
export const FETCH_MARKIVE_BEGIN = "FETCH_MARKIVE_BEGIN";
export const FETCH_MARKIVE_SUCCESS = "FETCH_MARKIVE_SUCCESS";
export const FETCH_MARKIVE_FAILURE = "FETCH_MARKIVE_FAILURE";

// ADDRESS (WITH AUTO COMPLETE)
export const AUTO_COMPLETE_CHANGE = "AUTO_COMPLETE_CHANGE";
export const AUTO_COMPLETE_LNG = "AUTO_COMPLETE_LNG";
export const AUTO_COMPLETE_LAT = "AUTO_COMPLETE_LAT";
export const QUICK_FIND = "QUICK_FIND";

// EDIT MARKIVE STUFF
export const HANDLE_CHANGE = "HANDLE_CHANGE";
export const HANDLE_CHANGE_IN_ARRAY = "HANDLE_CHANGE_IN_ARRAY";
export const HANDLE_ADDITION = "HANDLE_ADDITION";
export const HANDLE_DELETE = "HANDLE_DELETE";
export const HANDLE_DELETE_IMAGE = "HANDLE_DELETE_IMAGE";
export const HANDLE_DELETE_VIDEO = "HANDLE_DELETE_VIDEO";
export const UPDATE_VIDEO_BEGIN = "UPDATE_VIDEO_BEGIN";
export const UPDATE_VIDEO_SUCCESS = "UPDATE_VIDEO_SUCCESS";
export const HANDLE_VIDEO = "HANDLE_VIDEO";
export const HANDLE_THUMBNAIL_IMAGE = "HANDLE_THUMBNAIL_IMAGE";
export const HANDLE_DRAG = "HANDLE_DRAG";
export const HANDLE_IMAGE = "HANDLE_IMAGE";

// TAGS FOR SUGGESTION AUTOCOMPLETE
export const FETCH_CATEGORIES_BEGIN = "FETCH_CATEGORIES_BEGIN";
export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS";
export const FETCH_CATEGORIES_FAILURE = "FETCH_CATEGORIES_FAILURE";

export const FETCH_YEARS_BEGIN = "FETCH_YEARS_BEGIN";
export const FETCH_YEARS_SUCCESS = "FETCH_YEARS_SUCCESS";
export const FETCH_YEARS_FAILURE = "FETCH_YEARS_FAILURE";

export const FETCH_PEOPLE_BEGIN = "FETCH_PEOPLE_BEGIN";
export const FETCH_PEOPLE_SUCCESS = "FETCH_PEOPLE_SUCCESS";
export const FETCH_PEOPLE_FAILURE = "FETCH_PEOPLE_FAILURE";

export const FETCH_SUPERTAGS_BEGIN = "FETCH_SUPERTAGS_BEGIN";
export const FETCH_SUPERTAGS_SUCCESS = "FETCH_SUPERTAGS_SUCCESS";
export const FETCH_SUPERTAGS_FAILURE = "FETCH_SUPERTAGS_FAILURE";

export const FETCH_TAGS_BEGIN = "FETCH_TAGS_BEGIN";
export const FETCH_TAGS_SUCCESS = "FETCH_TAGS_SUCCESS";
export const FETCH_TAGS_FAILURE = "FETCH_TAGS_FAILURE";

export const FETCH_LOCATIONS_BEGIN = "FETCH_LOCATIONS_BEGIN";
export const FETCH_LOCATIONS_SUCCESS = "FETCH_LOCATIONS_SUCCESS";
export const FETCH_LOCATIONS_FAILURE = "FETCH_LOCATIONS_FAILURE";

// SHARE MARKIVE STUFF HERE
export const FETCH_MULTIPLE_MARKIVES_TOKEN_BEGIN = "FETCH_MULTIPLE_MARKIVES_TOKEN_BEGIN";
export const FETCH_MULTIPLE_MARKIVES_TOKEN_SUCCESS = "FETCH_MULTIPLE_MARKIVES_TOKEN_SUCCESS";
export const FETCH_MULTIPLE_MARKIVES_TOKEN_FAILURE = "FETCH_MULTIPLE_MARKIVES_TOKEN_FAILURE";

export const FETCH_MULTIPLE_MARKIVES_URL_BEGIN = "FETCH_MULTIPLE_MARKIVES_URL_BEGIN";
export const FETCH_MULTIPLE_MARKIVES_URL_SUCCESS = "FETCH_MULTIPLE_MARKIVES_URL_SUCCESS";
export const FETCH_MULTIPLE_MARKIVES_URL_FAILURE = "FETCH_MULTIPLE_MARKIVES_URL_FAILURE";

export const ADD_SHARED_MARKIVE = "ADD_SHARED_MARKIVE";
export const ADD_ALL_SHARED_MARKIVES = "ADD_ALL_SHARED_MARKIVES";
export const DELETE_SHARED_MARKIVE = "DELETE_SHARED_MARKIVE";
export const DELETE_ALL_SHARED_MARKIVES = "DELETE_ALL_SHARED_MARKIVES";
export const TOGGLE_MARKIVES_CHECKBOXES = "TOGGLE_MARKIVES_CHECKBOXES";


// SHARE MULTIPLE MARKIVES STUFF HERE
export const FETCH_MARKIVE_TOKEN_BEGIN = "FETCH_MARKIVE_TOKEN_BEGIN";
export const FETCH_MARKIVE_TOKEN_SUCCESS = "FETCH_MARKIVE_TOKEN_SUCCESS";
export const FETCH_MARKIVE_TOKEN_FAILURE = "FETCH_MARKIVE_TOKEN_FAILURE";

export const FETCH_MARKIVE_URL_BEGIN = "FETCH_MARKIVE_URL_BEGIN";
export const FETCH_MARKIVE_URL_SUCCESS = "FETCH_MARKIVE_URL_SUCCESS";
export const FETCH_MARKIVE_URL_FAILURE = "FETCH_MARKIVE_URL_FAILURE";

export const POST_CHECKBOX = "POST_CHECKBOX";

// Geolocation
export const LOCATE_BEGIN = "LOCATE_BEGIN";
export const LOCATE_SUCCESS = "LOCATE_SUCCESS";
export const LOCATE_FAILURE = "LOCATE_FAILURE";

// Image Deletion
export const  UPDATE_IMAGE_BEGIN = "UPDATE_IMAGE_BEGIN";
export const  UPDATE_IMAGE_SUCCESS = "UPDATE_IMAGE_SUCCESS";

// Image Deletion
export const  HANDLE_IMAGE_SORTING = "HANDLE_IMAGE_SORTING";

// Image Deletion
export const  DELETE_IMAGE_BEGIN = "DELETE_IMAGE_BEGIN";
export const  DELETE_IMAGE_SUCCESS = "DELETE_IMAGE_SUCCESS";
export const  DELETE_IMAGE_FAILURE = "DELETE_IMAGE_FAILURE";

// Video Deletion
export const  DELETE_VIDEO_BEGIN = "DELETE_VIDEO_BEGIN";
export const  DELETE_VIDEO_SUCCESS = "DELETE_VIDEO_SUCCESS";
export const  DELETE_VIDEO_FAILURE = "DELETE_VIDEO_FAILURE";

export default {
  APP_LOAD,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  AUTO_COMPLETE_CHANGE,
  AUTO_COMPLETE_LNG,
  AUTO_COMPLETE_LAT,
  QUICK_FIND,
  DATE_INPUT,
  HANDLE_IMAGE_SORTING,
  UPDATE_IMAGE_BEGIN,
  UPDATE_IMAGE_SUCCESS,
  DELETE_IMAGE_BEGIN,
  DELETE_VIDEO_BEGIN,
  DELETE_IMAGE_SUCCESS,
  DELETE_VIDEO_SUCCESS,
  FETCH_CATEGORIES_BEGIN,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAILURE,
  FETCH_YEARS_BEGIN,
  FETCH_YEARS_SUCCESS,
  FETCH_YEARS_FAILURE,
  FETCH_MARKIVE_BEGIN,
  FETCH_MARKIVE_SUCCESS,
  FETCH_MARKIVE_FAILURE,
  FETCH_MARKIVE_TOKEN_BEGIN,
  FETCH_MARKIVE_TOKEN_SUCCESS,
  FETCH_MARKIVE_TOKEN_FAILURE,
  FETCH_MARKIVE_URL_BEGIN,
  FETCH_MARKIVE_URL_SUCCESS,
  FETCH_MARKIVE_URL_FAILURE,
  FETCH_MULTIPLE_MARKIVES_TOKEN_BEGIN,
  FETCH_MULTIPLE_MARKIVES_TOKEN_SUCCESS,
  FETCH_MULTIPLE_MARKIVES_TOKEN_FAILURE,
  FETCH_MULTIPLE_MARKIVES_URL_BEGIN,
  FETCH_MULTIPLE_MARKIVES_URL_SUCCESS,
  FETCH_MULTIPLE_MARKIVES_URL_FAILURE,
  ADD_SHARED_MARKIVE,
  ADD_ALL_SHARED_MARKIVES,
  DELETE_SHARED_MARKIVE,
  DELETE_ALL_SHARED_MARKIVES,
  TOGGLE_MARKIVES_CHECKBOXES,
  FETCH_PEOPLE_BEGIN,
  FETCH_PEOPLE_SUCCESS,
  FETCH_PEOPLE_FAILURE,
  FETCH_SUPERTAGS_BEGIN,
  FETCH_SUPERTAGS_SUCCESS,
  FETCH_SUPERTAGS_FAILURE,
  FETCH_TAGS_BEGIN,
  FETCH_TAGS_SUCCESS,
  FETCH_TAGS_FAILURE,
  FETCH_LOCATIONS_BEGIN,
  FETCH_LOCATIONS_SUCCESS,
  FETCH_LOCATIONS_FAILURE,
  HANDLE_ADDITION,
  HANDLE_CHANGE_IN_ARRAY,
  HANDLE_CHANGE,
  HANDLE_DELETE,
  HANDLE_DELETE_IMAGE,
  HANDLE_DELETE_VIDEO,
  HANDLE_THUMBNAIL_IMAGE,
  HANDLE_DRAG,
  HANDLE_IMAGE,
  POST_CHECKBOX,
  SHOW_MODAL,
  UPDATE_MODAL_MARKIVE,
  SHOW_LOAD,
  SHOW_LOAD_IN_BACKGROUND,
  HIDE_LOAD,
};

import React, { useEffect, useState } from 'react';

const ResizedImage = (props) => {
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState('');
  const [isPortrait, setIsPortrait] = useState();
  const fetchImage = () => {
    setLoading(true);
    setUrl(props.url);
    setLoading(false);
  }

  const onImgLoad = (e) => {
    setIsPortrait(e.currentTarget.naturalHeight > e.currentTarget.naturalWidth);
  }

  useEffect(() => {
    if (url !== props.url && !loading) {
      fetchImage(props.url);
    }
  }, [props.url]);

  return (
    <div
        id={props.id}
        className={`private-img ${props.className}`}
        onClick={props.onClick}
        data-portrait={isPortrait}
      >
        <img className={isPortrait ? 'img__portrait' : 'img__landscape'} src={props.url} onLoad={onImgLoad} alt={`${props.alt}`} />
        {props.children}
        <div className={`lds-ring${loading ? ' show' : ''}`}>
          <div />
          <div />
          <div />
          <div />
        </div>
    </div>
  )
}

export default ResizedImage;

import React, { Component } from 'react';

var LoginEmail = props => {
  return (
    <div className="login-sign-up">
      <a onClick={props.handleClick}>
        <p>or Login with Email</p>
      </a>
    </div>
  );
};
export default LoginEmail;

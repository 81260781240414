import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showModal } from '../../actions/app';
import { deleteMarkive } from '../../async/deleteMarkive';
import SVGIcon from '../../components/SVGIcon';
import {SHOW_MODAL, SHOW_LOAD, HIDE_LOAD} from "../../constants/action-types";

class DeleteMarkive extends Component {

  async handleSubmit(event) {
    const {
      modalMarkive,
    } = this.props;
    event.preventDefault();
    if (modalMarkive) {
      var markiveId = modalMarkive;
    } else {
      var url = window.location.href;
      var arr = url.split("/");
      var markiveId = arr[arr.length - 1];
    }

    this.props.dispatch({
      type: SHOW_LOAD,
    });

    if (!modalMarkive) {
      await deleteMarkive(markiveId);
      this.props.history.push('/search?orderby=date&order=ASC');
    } else {
      await deleteMarkive(markiveId, false);
    }

    this.props.dispatch({
      type: HIDE_LOAD,
    });
    this.props.dispatch({
      type: SHOW_MODAL,
      payload: false,
    });
  }

  render() {
    return (
      <div className="delete-markive">
        <div className="delete-markive-content">
          <SVGIcon id="trash" />
          <h1 className="delete-font">Would you like to delete this Markive?</h1>
          <h2 className="delete-font">This cannot be undone, are you sure you want to proceed.</h2>
          <button className="delete-button" onClick={(e) => this.handleSubmit(e)}>Yes</button>
          <button className="delete-button cancel" onClick={this.props.dispatch.bind(this, showModal(false))}>No</button>
        </div>
      </div>
    )
  }
}

function mapStateToProperties(state) {
  return {
    modalMarkive: state.app.modalMarkive,
  };
}

export default connect(mapStateToProperties)(DeleteMarkive);

import { axios } from '../lib/axios';

export async function postNewPerson(person) {
  return await axios
    .post('/api/people', person)
    .then(async resp => {
      return resp.data.data.id;
    });
}

export async function linkPersonToMarkive(peopleData) {
  await axios.post('/api/people/update', peopleData);
}

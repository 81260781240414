import {
  FETCH_MULTIPLE_MARKIVES_TOKEN_BEGIN,
  FETCH_MULTIPLE_MARKIVES_TOKEN_SUCCESS,
  FETCH_MULTIPLE_MARKIVES_TOKEN_FAILURE,
  FETCH_MULTIPLE_MARKIVES_URL_BEGIN,
  FETCH_MULTIPLE_MARKIVES_URL_SUCCESS,
  FETCH_MULTIPLE_MARKIVES_URL_FAILURE,
  ADD_SHARED_MARKIVE,
  ADD_ALL_SHARED_MARKIVES,
  DELETE_ALL_SHARED_MARKIVES,
  DELETE_SHARED_MARKIVE,
  TOGGLE_MARKIVES_CHECKBOXES,
} from '../constants/action-types';

const initialState = {
  loadingToken: false,
  loadingUrl: false,
  token: '',
  url: '',
  showMarkivesCheckboxes: false,
  cbArray: [],
};

// Reducer will comprise of the auto complete stuff here since they share the same store
export default function MultipleMarkivesData(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MARKIVES_CHECKBOXES:
      return {
        ...state,
        showMarkivesCheckboxes: !state.showMarkivesCheckboxes,
      };
    case ADD_SHARED_MARKIVE:
      return {
        ...state,
        cbArray: [...state.cbArray, action.value],
      };
    case DELETE_SHARED_MARKIVE:
      return {
        ...state,
        cbArray: state.cbArray.filter(item => item !== action.value),
      };
    case ADD_ALL_SHARED_MARKIVES:
      return {
        ...state,
        cbArray: action.value,
      };
    case DELETE_ALL_SHARED_MARKIVES:
      return {
        ...state,
        cbArray: [],
      };
    case FETCH_MULTIPLE_MARKIVES_TOKEN_BEGIN:
      return {
        ...state,
        loadingToken: true,
      };
    case FETCH_MULTIPLE_MARKIVES_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.token.url_token,
      };
    case FETCH_MULTIPLE_MARKIVES_TOKEN_FAILURE:
      return {
        ...state,
        loadingToken: false,
        token: action.errorToken,
      };
    case FETCH_MULTIPLE_MARKIVES_URL_BEGIN:
      return {
        ...state,
        loadingUrl: true,
      };
    case FETCH_MULTIPLE_MARKIVES_URL_SUCCESS:
      return {
        ...state,
        url: action.url,
      };
    case FETCH_MULTIPLE_MARKIVES_URL_FAILURE:
      return {
        ...state,
        ladingUrl: false,
        url: action.errorUrl,
      };
    default:
      return state;
  }
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { authLogout } from '../actions/app';
import IconButton from './IconButton';
import { axios } from '../lib/axios';

class DashboardHeader extends Component {
  state = {
    token: window.localStorage.token
  };

  handleLogout = () => {
    axios
      .post('/api/logout')
      .then(resp => {
        if (resp.data.status !== 200) {
          return;
        }
        this.props.dispatch(authLogout());
        alert('You have successfully logged out!');
      })
      .catch(err => {
        console.log(err);
      })
  };

  render() {
    return (
      <nav className="header-wrapper header-dashboard">
        <div className="header-actions">
          {this.state.token ? (
            <li>
              <IconButton icon="exit" iconColor="light-grey" btnColor="grey" onClick={this.handleLogout} />
            </li>
          ) : (
            <li>
              <IconButton icon="mobile-menu" iconColor="grey" btnColor="transparent" href="/google-auth" />
            </li>
          )}
        </div>
      </nav>
    );
  }
}

function mapStateToProperties(state) {
  return {};
}

export default connect(mapStateToProperties)(DashboardHeader);

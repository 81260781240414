import React from 'react';
import IconButton from './IconButton';

var AddMarkiveButton = props => {
  return (
    <div className="dashboard-action-button">
      <IconButton icon="add" btnColor="lighter-blue" onClick={props.handleClick}>
        <p>ADD</p>
      </IconButton>
    </div>
  );
};
export default AddMarkiveButton;

import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import request from 'superagent';
import {CloudinaryContext, Video} from "cloudinary-react";
import Tags from "../../components/Tags";
import LogoWhite from "../../styles/assets/images/logoWhite";
import IconButton from '../../components/IconButton';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { putExistingMarkive } from "../../async/putExistingMarkive";
import { postNewCategory, linkCategoryToMarkive } from "../../async/postNewCategories";
import { postNewTag, linkTagToMarkive } from "../../async/postNewTags";
import { postNewPerson, linkPersonToMarkive } from "../../async/postNewPeople";
import { postNewSuperTag, linkSuperTagToMarkive } from "../../async/postNewSuperTag";
import { submitUpdateImage } from "../../async/updateMarkiveThumbnail";
import { postNewImages } from "../../async/postNewImages";
import TextareaAutosize from 'react-textarea-autosize';
import { postNewVideo } from '../../async/postNewVideo';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
// Actions
import { showModal, showLoad, hideLoad } from '../../actions/app';
import { autoCompleteChange, autoCompleteLng, autoCompleteLat } from "../../actions/autoCompleteAction";
import { handleChange, handleAddition, handleChangeInArray, handleAssignImageThumbnail, handleDelete, handleDrag, handleImage, handleDeleteImage, handleDeleteVideo, submitDeleteVideo, submitDeleteImage, handleImageSorting } from "../../actions/editMarkiveAction";
import { fetchMarkiveData } from "../../actions/fetchMarkiveAction";
import { fetchCategoriesData, fetchPeopleData, fetchTagsData, fetchSuperTagsData } from '../../actions/suggestionTagsAction';
import DragAndDropList from "../../components/DragAndDropList";

// NOTE TO FUTURE SELF - THIS COMPONENT'S PROPS IS COMING FROM THE REDUCER!!!
class EditMarkive extends Component {
  state = {
    token: window.localStorage.token,
    requiredComplete: true,
    boxHeight: 'auto',
    state: false,
    progress: 0,
    showProgressBar: false,
    cloud_key: '',
  };

  // There seems to be a good use for having componentDidMount in ES6 syntax -- TIP
  componentDidMount = () => {
    const {
      modalMarkive,
    } = this.props;
    if (modalMarkive) {
      var markiveId = modalMarkive;
    } else {
      var url = window.location.href;
      var arr = url.split("/");
      var markiveId = arr[arr.length - 1];
    }

    this.refs.header.scrollIntoView();
    this.props.dispatch(fetchMarkiveData(markiveId));
    if (this.props.categoriesSuggestions && !this.props.categoriesSuggestions.length) {
      this.props.dispatch(fetchCategoriesData());
    }
    if (this.props.peopleSuggestions && !this.props.peopleSuggestions.length) {
      this.props.dispatch(fetchPeopleData());
    }
    if (this.props.superTagsSuggestions && !this.props.superTagsSuggestions.length) {
      this.props.dispatch(fetchSuperTagsData());
    }
    if (this.props.tagsSuggestions && !this.props.tagsSuggestions.length) {
      this.props.dispatch(fetchTagsData());
    }
  };

  AutoCompleteChange = address =>
    this.props.dispatch(autoCompleteChange(address));

  handleAddition(name, tag) {
    if(tag) {
      new Promise((resolve) => {
        let id = parseInt(tag.id, 10);
        if (id === 0 || isNaN(id)) {
          switch (name) {
            case 'categories':
              id = postNewCategory(tag);
              break;
            case 'people':
              id = postNewPerson(tag);
              break;
            case 'superTags':
              id = postNewSuperTag(tag);
              break;
            case 'tags':
              id = postNewTag(tag);
          }
        }
        resolve(id);

      }).then(id => {
        tag.id = id.toString();
        if (name === "superTags") {
          this.props.dispatch(handleChangeInArray(name, tag));
        } else {
          this.props.dispatch(handleAddition(name, tag));
        }
        this.setState({ showTags: false });
      });
    }
  }

  handleChange = event =>
    this.props.dispatch(handleChange(event.target.name, event.target.value))

  handleDelete(name, i) {
    this.props.dispatch(handleDelete(name, i));
    this.setState({ showTags: false });
  }

  handleDeleteImage(index) {
    this.props.dispatch(handleDeleteImage(index));
  }

  handleDeleteVideo(index, cloud_key) {
    this.props.dispatch(handleDeleteVideo(index, cloud_key));
  }

  handleImageSorting(images) {
    this.props.dispatch(handleImageSorting(images));
  }

  handleAssignImageThumbnail(index) {
    this.props.dispatch(handleAssignImageThumbnail(index));
  }

  handleDrag(name, tag, currPos, newPos) {
    this.props.dispatch(handleDrag(name, tag, currPos, newPos));
  }

  // Not even sure if this is used
  // handleCaptionChange = event => {
    // event.preventDefault();
    // var images = this.state.images;
    // var idx = Number(event.target.attributes.idx.value);
    // images[idx].caption = event.target.value;
    // this.setState({
    //   images
    // });
  // };

  handleImageUpload = e => {
    const files = e.target.files;
    if (e && e.target && files && files.length > 0) {
      for (var i = 0; i < files.length; i++) {

        let reader = new FileReader();
        let file = e.target.files[i];

        reader.onloadend = () => {
          let data = reader.result;
          this.props.dispatch(handleImage(file, data));
        };
        reader.readAsDataURL(file);
      }
    }
  };

  handleVideoUpload(event) {
    this.setState({
      showProgressBar: true,
    });
    
    const url = `https://api.cloudinary.com/v1_1/${
        this.context.cloudName
        }/upload`;

    const file = event.target.files[0];

    const title = event.currentTarget.files[0].name;

    request.post(url)
      .field('upload_preset', this.context.uploadPreset)
      .field('file', file)
      .field('multiple', false)
      .field('tags', title ? `myphotoalbum,${title}` : 'myphotoalbum')
      .field('context', title ? `photo=${title}` : '')
      .on('progress', (progress) => this.onPhotoUploadProgress(progress.percent))
      .end((error, response) => {
        this.onPhotoUploaded(response.body.public_id);
    });
  };

  onPhotoUploadProgress(progress) {
    this.setState({
        progress: progress,
    });
  }

  onPhotoUploaded(cloud_key) {
    this.setState({
      cloud_key: cloud_key,
    });
  }

  static contextType = CloudinaryContext.contextType;

  handleKeyUp(e) {
    let newHeight = Math.max(Math.min(e.target.scrollHeight + 2, 150), 30);
    if (newHeight !== this.state.boxHeight) {
      this.setState({
        boxHeight: newHeight
      });
    }
  }

  handleSelect = address => {
    this.AutoCompleteChange(address);
    this.setState({ address });
    if (address) {
      geocodeByAddress(address)
        .then(results => getLatLng(results[0]))
        .then(latLng => {
          this.props.dispatch(autoCompleteLng(latLng.lng));
          this.props.dispatch(autoCompleteLat(latLng.lat));
        })
        .catch(error => console.error("Error", error));
    }
  };

  onBeforeCapture = e => {
    console.log(e)
  }
  onBeforeDragStart = e => {
    console.log(e)
  }
  onDragStart = e => {
    console.log(e)
  }
  onDragUpdate = e => {
    console.log(e)
  }
  onDragEnd = e => {
    console.log(e)
  }

  async updateImageSorting() {
    for (const [id, image] of this.props.images.entries()) {
      if (!image.file) {
        await submitUpdateImage(image, id + 1);
      }
    }
  }


  handleSubmit = async event => {
    event.preventDefault();

    var errors = [];

    var url = window.location.href;
    var arr = url.split("/");
    var currentMarkiveId = arr[arr.length - 1];
    if (currentMarkiveId.includes('search')) {
      currentMarkiveId = this.props.modalMarkive;
    }

    // JS Date sheenanigans
    var date = this.props.date;
    var dateArr = date.split('-');
    var dateParse = new Date(parseInt(dateArr[0]), parseInt(dateArr[1]-1), parseInt(dateArr[2]))
    var dateIso = dateParse.toISOString();
    var dateData = dateIso.split('T');
    var dateFormat = dateData[0];

    var data = {
      id: currentMarkiveId,
      address: this.props.address,
      longitude: this.props.lng,
      latitude: this.props.lat,
      title: this.props.title,
      url: this.props.url,
      notes: this.props.notes,
      date: dateFormat,
    };

    if (!data.title) {
      this.setState({requiredComplete:false});
      return;
    }

    /*
    MARKIVE ID AFTER PUT
    */
    this.props.dispatch(showLoad());

    await putExistingMarkive(data);
    var markiveId = currentMarkiveId;

    this.props.dispatch(showModal(false));
    this.props.dispatch(showLoad(true));
    /*
    CATEGORY
    */
    let categoryData = {
      categoryArray: this.props.categories.map(i => parseInt(i.id, 10)),
      markiveId: markiveId
    };

    try {
      await linkCategoryToMarkive(categoryData);
    } catch {
      errors.push('There was an issue with some category uploads')
    }

    /*
    TAGS
    */

    let tagData = {
      tagArray: this.props.tags.map(i => parseInt(i.id, 10)),
      markiveId: markiveId
    };

    try {
      await linkTagToMarkive(tagData);
    } catch {
      errors.push('There was an issue with some tag uploads')
    }

    /*
    PEOPLE
    */
    let peopleData = {
      peopleArray: this.props.people.map(i => parseInt(i.id, 10)),
      markiveId: markiveId
    };

    try {
      await linkPersonToMarkive(peopleData);
    } catch {
      errors.push('There was an issue with linked a person to the Markive.')
    }

    /*
    SUPERTAGS
    */
    let superTagsData = {
      superTagsArray: this.props.superTags.map(i => parseInt(i.id, 10)),
      markiveId: markiveId
    };

    try {
      await linkSuperTagToMarkive(superTagsData);
    } catch {
      errors.push('There was an issue with linked a supertag to the Markive.')
    }

    // WHAT IS THIS FOR AGAIN?
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      axios.defaults.headers.common["Authorization"] = null;
      delete axios.defaults.headers.common["Authorization"];
    }

    /*
    IMAGES
    */
    for (const [id, image] of this.props.images.entries()) {
      const files = new FormData();
      files.append('markive_id', markiveId);
      if (image.file) {
        files.append("image", image.file);
        files.append('caption', image.caption);
        files.append('is_thumbnail', image.is_thumbnail)
        files.append('sort_order', id + 1)
        try {
          await postNewImages(files);
        } catch {
          errors.push(`${image.file.name} failed to upload.`)
        }
      }
    }

    /*
    VIDEO
    */
    if (this.state.cloud_key) {
      const videoFile = new FormData();
      videoFile.append('markive_id', markiveId);
      videoFile.append("cloud_key", this.state.cloud_key);
      try {
        await postNewVideo(videoFile);
      } catch {
        errors.push(`${this.state.cloud_key} failed to upload.`)
      }
    }

    await this.updateImageSorting()

    // Submit Image Deletion
    this.props.deleteBucket.forEach(imageId => {
      this.props.dispatch(submitDeleteImage(imageId))
    })

    this.props.deleteVideoBucket.forEach(videoId => {
      this.props.dispatch(submitDeleteVideo(videoId))
    })

    if (errors.length) {
      alert(`
        Your Markive has been saved, but there were some issues: \n
        ${errors.join('\n')}
      `);
    } else {
      alert('Your Markive has been successfully saved!');
    }

    this.props.dispatch(hideLoad());
    this.props.dispatch(showModal(false));

    // Reloading here is okay because state needs to update anyways
    window.location.reload();

  };

  render() {
    let expandedStyle = {
      height: this.state.boxHeight,
      marginTop: 20,
    };

    const percent = Math.floor(this.state.progress);

    return (
      <div className="page-create-markive">
        <div className="header" ref="header">
          {this.state.token ? (
            <a className="header-home-link" href="/search?orderby=date&order=ASC">
              <LogoWhite />
            </a>
          ) : (
            <a className="header" href="/">
              <LogoWhite />
            </a>
          )}
          <IconButton 
            onClick={this.props.dispatch.bind(this, showModal(false))} 
            className="modal-close"
            icon="close"
            iconColor="dark-grey" 
            btnColor="transparent"
          />
        </div>

        <div className="create-markive-wrapper">
          <div className="container content">
            <h1 className="form-label edit-headline">EDIT MARKIVE</h1>
            <form autoComplete="off" onSubmit={this.handleSubmit}>
              <div className="row">
                <div className="col-md-2 col-sm-3 col-xs-4">
                  <p className="form-label">WHERE</p>
                </div>
                <div className="col-md-10 col-sm-9 col-xs-8">
                  <PlacesAutocomplete
                    className="create-places-autocomplete"
                    inputProps={{
                      id: "address",
                      type: "search",
                      value: this.props.address,
                      onChange: this.AutoCompleteChange,
                      className: "create-places-autocomplete",
                      autoFocus: false,
                    }}
                    onSelect={this.handleSelect}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-2 col-sm-3 col-xs-4">
                  <p className="form-label">WHAT</p>
                </div>
                <div className="col-md-10 col-sm-9 col-xs-8">
                  <Tags
                    name="categories"
                    focused
                    allowNew
                    newTagPrefix="Add New"
                    suggestions={this.props.categoriesSuggestions}
                    onDelete={(i) => this.handleDelete('categories', i)}
                    onAddition={(tag) => this.handleAddition('categories', tag)}
                    tags={this.props.categories}
                    autocomplete={true}
                    minQueryLength={this.state.showTags ? 0 : 1}
                    inputAttributes={{
                      onClick: () => this.setState({ showTags: true }),
                    }}
                    onInput={(e) => {
                      this.setState({ showTags: !e })
                    }}
                    maxSuggestionsLength={100}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-2 col-sm-3 col-xs-4">
                  <p className="form-label">WHEN</p>
                </div>
                <div className="col-md-10 col-sm-9 col-xs-8">
                  <input
                    name="date"
                    type="date"
                    onChange={this.handleChange}
                    value={this.props.date.substring(0, this.props.date.indexOf('T')) || this.props.date}
                    placeholder={''}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-2 col-sm-3 col-xs-4">
                  <p className="form-label">WHO</p>
                </div>
                <div className="col-md-10 col-sm-9 col-xs-8">
                  <Tags
                    name="people"
                    allowNew
                    newTagPrefix="Add New"
                    suggestions={this.props.peopleSuggestions}
                    onDelete={(i) => this.handleDelete('people', i)}
                    onAddition={(tag) => this.handleAddition('people', tag)}
                    tags={this.props.people}
                    autocomplete={true}
                    minQueryLength={this.state.showTags ? 0 : 1}
                    inputAttributes={{
                      onClick: () => this.setState({ showTags: true }),
                    }}
                    onInput={(e) => {
                      this.setState({ showTags: !e })
                    }}
                    maxSuggestionsLength={100}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-2 col-sm-3 col-xs-4">
                  <p className="form-label">TITLE</p>
                </div>
                <div className="col-md-10 col-sm-9 col-xs-8">
                  <input
                    name="title"
                    type="text"
                    onChange={this.handleChange}
                    value={this.props.title}
                    className={this.state.requiredComplete ? undefined : "required"}
                    placeholder={this.state.requiredComplete ? undefined : "Please enter a title before submitting your Markive..."}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-2 col-sm-3 col-xs-4">
                  <p className="form-label">URL:</p>
                </div>
                <div className="col-md-10 col-sm-9 col-xs-8">
                  <input
                    name="url"
                    onChange={this.handleChange}
                    value={this.props.url}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-2 col-sm-3 col-xs-4">
                  <p className="form-label">TAGS:</p>
                </div>
                <div className="col-md-10 col-sm-9 col-xs-8">
                  <Tags
                    name="tags"
                    allowNew
                    newTagPrefix="Add New"
                    suggestions={this.props.tagsSuggestions}
                    onDelete={(i) => this.handleDelete('tags', i)}
                    onAddition={(tag) => this.handleAddition('tags', tag)}
                    tags={this.props.tags}
                    autocomplete={true}
                    minQueryLength={this.state.showTags ? 0 : 1}
                    inputAttributes={{
                      onClick: () => this.setState({ showTags: true }),
                    }}
                    onInput={(e) => {
                      this.setState({ showTags: !e })
                    }}
                    maxSuggestionsLength={100}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-2 col-sm-3 col-xs-4">
                  <p className="form-label">SUPERTAG:</p>
                </div>
                <div className="col-md-10 col-sm-9 col-xs-8">
                  <Tags
                    name="supertag"
                    allowNew
                    newTagPrefix="Add New"
                    suggestions={this.props.superTagsSuggestions}
                    onDelete={(i) => this.handleDelete('superTags', i)}
                    onAddition={(tag) => this.handleAddition('superTags', tag)}
                    tags={this.props.superTags}
                    autocomplete={true}
                    minQueryLength={this.state.showTags ? 0 : 1}
                    inputAttributes={{
                      onClick: () => this.setState({ showTags: true }),
                    }}
                    onInput={(e) => {
                      this.setState({ showTags: !e })
                    }}
                    maxSuggestionsLength={100}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-2">
                  <p className="form-label">PHOTOS:</p>
                  <label htmlFor="image-uploader">
                    <IconButton icon="plus" btnColor="white" className="add-img-btn"/>
                  </label>
                  <input
                    id="image-uploader"
                    name="image-uploader"
                    onChange={this.handleImageUpload}
                    type="file"
                    multiple
                  />
                </div>
                <div className="col-xs-10">
                  {
                    this.props.images.length > 0 &&
                    <DragAndDropList 
                      images={this.props.images} 
                      onHandleDeleteImage={(idx) => this.handleDeleteImage(idx)}
                      onHandleAssignImageThumbnail={(idx) => this.handleAssignImageThumbnail(idx)}
                      setImages={(images) => this.handleImageSorting(images)}
                    />
                  }
                </div>
              </div>
              <div className="row">
                <div className="col-xs-2">
                  <p className="form-label">
                    VIDEOS:
                  </p>
                    <label htmlFor="video-uploader">
                      <IconButton icon="plus" btnColor="white" className="add-img-btn" disabled={this.props.videos.length > 2} />
                    </label>
                    <input
                      id="video-uploader"
                      name="video-uploader"
                      onChange={(e) => this.handleVideoUpload(e)}
                      type="file"
                      accept="video/*"
                      disabled={this.props.videos.length > 2}
                    />
                </div>
                <div className="col-xs-10">
                  <div className="row video-block">
                    {this.state.cloud_key
                      ?
                      <div className="video-preview">
                        <Video
                          publicId={this.state.cloud_key}
                          cloudName={this.context.cloudName}
                          controls={true}
                          width={200}
                          height={150}
                        />
                      </div>
                      :
                      <div>
                          {this.state.showProgressBar &&
                            <div className="progress-bar">
                              <div
                                className="progress"
                                role="progressbar"
                                style={{ width: percent + '%' }}
                              />
                            </div>
                          }
                      </div>
                    }
                    {this.props.videos.length ? 
                      this.props.videos.map((obj, i) => {
                        return (
                          <div key={i} id={i} className="video-preview">
                            <Video
                              publicId={obj.cloud_key}
                              cloudName={this.context.cloudName}
                              controls={true}
                              width={200}
                              height={150}
                            />
                            <div
                              className="delete-video-button"
                              onClick={() => {this.handleDeleteVideo(obj.video_id, obj.cloud_key)}}
                            >
                              &#10005;
                            </div>
                          </div>
                        )
                      }) : null
                    }
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <p className="form-label">WHY (notes)</p>
                </div>
                <div className="col-md-10">
                  <TextareaAutosize 
                    onKeyUp={this.handleKeyUp.bind(this)}
                    style={expandedStyle}
                    name="notes"
                    type="text"
                    className="create-markive-text-area"
                    onChange={this.handleChange}
                    value={this.props.notes}
                  />
                </div>

                <button id="create-markive-button" className="pull-right submit-button save-button" type="submit">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProperties(state) {
  return {
    modalMarkive: state.app.modalMarkive,
    date: state.markiveData.date,
    address: state.markiveData.address,
    images: state.markiveData.images,
    categories: state.markiveData.categories,
    categoriesSuggestions: state.suggestionTagData.categories,
    updateBucket: state.markiveData.updateBucket,
    deleteBucket: state.markiveData.deleteBucket,
    deleteVideoBucket: state.markiveData.deleteVideoBucket,
    tags: state.markiveData.tags,
    superTags: state.markiveData.superTags,
    tagsSuggestions: state.suggestionTagData.tags,
    people: state.markiveData.people,
    peopleSuggestions: state.suggestionTagData.people,
    superTagsSuggestions: state.suggestionTagData.superTags,
    error: state.markiveData.error,
    lat: state.markiveData.lat,
    lng: state.markiveData.lng,
    loading: state.markiveData.loading,
    markive: state.markiveData.items,
    notes: state.markiveData.notes,
    title: state.markiveData.title,
    url: state.markiveData.url,
    videos: state.markiveData.videos,
  };
}

export default connect(mapStateToProperties)(EditMarkive);

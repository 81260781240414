import React, { Component } from 'react';
import { connect } from "react-redux";

// /*
// * LOAD STATES
// * 0 - Not loading: Shows no component
// * 1 - Loading: General overlay
// * 2 - Loading: Bottom overlay w/ prompt to not close / reload page
// */
class LoadState extends Component {
  componentDidMount() {
    window.addEventListener('beforeunload', (e) => {
      if (this.props.loadState === 2) {
        var confirmationMessage = 'Navigating away from this page may result in data loss.';
        (e || window.event).returnValue = confirmationMessage; //Gecko + IE
        return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
      }
      return undefined;
    });
  }

  render () {
    const { loadState } = this.props;
    if (loadState > 1) {
      return (
        <div id="overlay" className="page-loader-overlay">
          <div className="lds-ring">
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      );
    }
    if (loadState === 1) {
      return (
        <div id="overlay" className="container  page-background-overlay">
          <div className="background-copy">
            Content is being uploaded in the background. You may continue to explore Markive,
            but do not navigate away from the website.
          </div>
          <div className="lds-ring">
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      )
    }
    return (<span className="d-none" />);
  }
};

function mapStateToProperties(state) {
  return {
    loadState: state.app.loadState,
  };
}

export default connect(mapStateToProperties)(LoadState);

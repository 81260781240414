import {
  APP_LOAD,
  AUTH_LOGOUT,
  AUTH_SUCCESS,
  DATE_INPUT,
  LOCATE_BEGIN,
  LOCATE_FAILURE,
  LOCATE_SUCCESS,
  SHOW_MODAL,
  UPDATE_MODAL_MARKIVE,
  SHOW_LOAD,
  SHOW_LOAD_IN_BACKGROUND,
  HIDE_LOAD,
} from '../constants/action-types';

const initialState = {
  dateTo: '',
  dateFrom: '',
  isModalVisible: false,
  latitude: 0,
  loaded: false,
  located: false,
  longitude: 0,
  modalType: null,
  modalMarkive: null,
  loadState: 0,
};

export default function app(state = initialState, action) {
  switch (action.type) {
    case APP_LOAD:
      return {
        ...state,
        loaded: true,
      };
    case AUTH_SUCCESS:
      return {
        ...state,
        token: action.token,
      };
    case AUTH_LOGOUT:
      return {
        ...state,
        token: null,
      };
    case DATE_INPUT:
      if (action.date === 'to') {
        return {
          ...state,
          dateTo: 'date',
        };
      }
      return {
        ...state,
        dateFrom: 'date',
      };
    case SHOW_MODAL:
      if (action.payload === false) {
        return {
          ...state,
          isModalVisible: false,
          modalType: null,
          modalMarkive: null,
        };
      }
      return {
        ...state,
        isModalVisible: true,
        modalType: action.payload,
      };
    case UPDATE_MODAL_MARKIVE:
      return {
        ...state,
        modalMarkive: action.payload,
      };
    case SHOW_LOAD:
      return {
        ...state,
        loadState: state.loadState + 2,
      };
    case SHOW_LOAD_IN_BACKGROUND:
      return {
        ...state,
        loadState: 1,
      };
    case HIDE_LOAD:
      return {
        ...state,
        // This is to handle when a fullscreen load transitions to background load
        loadState: (state.loadState - 2 < 0) ? 0 : (state.loadState - 2),
      };
    case LOCATE_SUCCESS:
      return {
        ...state,
        located: true,
        longitude: action.payload.longitude,
        latitude: action.payload.latitude
      };
    case LOCATE_BEGIN:
    case LOCATE_FAILURE:
    default:
      return state;
  }
}

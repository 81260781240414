import React from 'react';
import IconButton from './IconButton';

var FindMarkiveButton = props => {
  return (
    <div className={props.className}>
      <IconButton icon="find" iconColor="light-grey" btnColor="white" onClick={props.handleClick}>
        <p>FIND</p>
      </IconButton>
    </div>
  );
};
export default FindMarkiveButton;

import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DraggableImage from "./DraggableImage";

/* 
  props: {
    images,
    onDelete,
    onHandleDeleteImage,
    onHandleAssignImageThumbnail,
    updateImageSort
  }
*/

const DragAndDropList = (props) => {
  const [items, setItems] = useState(props.images);
  useEffect(() => {
    setItems(props.images);
  }, [props.images]);

  const onDragEnd = (result) => {
    const newItems = Array.from(items);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setItems(newItems);
    props.setImages(newItems);
  };

  const specialId = (image) => {
    if (image.file) {
      return image.file.name;
    }
    return image.image_id.toString()
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div className="row" {...provided.droppableProps} ref={provided.innerRef}>
            {items.map((item, index) => (
              <Draggable key={specialId(item)} draggableId={specialId(item)} index={index}>
                {(provided, snapshot) => (
                  <DraggableImage
                    provided={provided}
                    snapshot={snapshot}
                    image={item}
                    idx={index}
                    onHandleDeleteImage={props.onHandleDeleteImage}
                    onHandleAssignImageThumbnail={props.onHandleAssignImageThumbnail}
                  />
                )}
              </Draggable>
            ))}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default DragAndDropList;

import React from 'react';
import { Switch, Route, Router } from 'react-router';
import { Provider } from 'react-redux';
import { history } from './store/index';
import App from './containers/app';
import PropTypes from 'prop-types';
import RouterWatcher from "./components/RouterWatcher";

export default function Root({ store }) {
  return (
    <Provider store={store}>
      <Router history={history}>
        <RouterWatcher>
          <Switch>
            <Route path="/" component={App} />
          </Switch>
        </RouterWatcher>
      </Router>
    </Provider>
  );
};

Root.propTypes = {
  store: PropTypes.object.isRequired,
};

import { axios } from '../lib/axios';
import {
  FETCH_MULTIPLE_MARKIVES_URL_BEGIN,
  FETCH_MULTIPLE_MARKIVES_URL_SUCCESS,
  FETCH_MULTIPLE_MARKIVES_URL_FAILURE,
  FETCH_MULTIPLE_MARKIVES_TOKEN_BEGIN,
  FETCH_MULTIPLE_MARKIVES_TOKEN_SUCCESS,
  FETCH_MULTIPLE_MARKIVES_TOKEN_FAILURE,
  ADD_SHARED_MARKIVE,
  ADD_ALL_SHARED_MARKIVES,
  DELETE_SHARED_MARKIVE,
  DELETE_ALL_SHARED_MARKIVES,
  TOGGLE_MARKIVES_CHECKBOXES,
} from "../constants/action-types";

export function toggleMarkivesCheckboxes() {
  return {
    type: TOGGLE_MARKIVES_CHECKBOXES,
  };
}


export function addSharedMarkive(value) {
  return {
    type: ADD_SHARED_MARKIVE,
    value,
  };
}

export function addAllSharedMarkives(value) {
  return {
    type: ADD_ALL_SHARED_MARKIVES,
    value,
  };
}

export function deleteSharedMarkive(value) {
  return {
    type: DELETE_SHARED_MARKIVE,
    value,
  };
}

export function deleteAllSharedMarkives() {
  return {
    type: DELETE_ALL_SHARED_MARKIVES
  };
}

// FETCH MARKIVE DATA
export function fetchMultipleMarkivesToken(data) {
  return dispatch => {
    dispatch(fetchMultipleMarkivesTokenBegin());

    axios
      .post("/api/mshare/", data)
      .then(res => {
        if(res && res.data && res.data.data) {
          dispatch(fetchMultipleMarkivesTokenSuccess(res.data.data));
        }
      })
      .catch(err => {
        // TODO: this doesn't handle failure gracefully
        dispatch(fetchMultipleMarkivesTokenFailure(err));
      });
  };
}

export function fetchMultipleMarkivesTokenBegin() {
  return {
    type: FETCH_MULTIPLE_MARKIVES_TOKEN_BEGIN
  };
}

export function fetchMultipleMarkivesTokenSuccess(token) {
  return {
    type: FETCH_MULTIPLE_MARKIVES_TOKEN_SUCCESS,
    token,
  };
}

export function fetchMultipleMarkivesTokenFailure(error) {
  return {
    type: FETCH_MULTIPLE_MARKIVES_TOKEN_FAILURE,
    errorToken: error
  };
}

export function fetchMultipleMarkivesUrl(shareToken) {
  return dispatch => {
    dispatch(fetchMultipleMarkivesUrlBegin());

    axios
      .get("/api/mshare/" + shareToken)
      .then(res => {
        if(res && res.data && res.data.data) {
          dispatch(fetchMultipleMarkivesUrlSuccess(res.data.data));
        }
      })
      .catch(err => {
        dispatch(fetchMultipleMarkivesUrlFailure(err));
      });
  };
}

export function fetchMultipleMarkivesUrlBegin() {
  return {
    type: FETCH_MULTIPLE_MARKIVES_URL_BEGIN
  };
}

export function fetchMultipleMarkivesUrlSuccess(url) {
  return {
    type: FETCH_MULTIPLE_MARKIVES_URL_SUCCESS,
    url,
  };
}

export function fetchMultipleMarkivesUrlFailure(error) {
  return {
    type: FETCH_MULTIPLE_MARKIVES_URL_FAILURE,
    errorUrl: error
  };
}

export default {
  fetchMultipleMarkivesToken,
  fetchMultipleMarkivesUrl,
  addSharedMarkive,
  addAllSharedMarkives,
  deleteSharedMarkive,
  deleteAllSharedMarkives,
  toggleMarkivesCheckboxes,
};

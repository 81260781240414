import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadApp, authSuccess } from '../../actions/app';
import { fetchCategoriesData } from '../../actions/suggestionTagsAction';

import axios from 'axios';
import IconButton from '../../components/IconButton';
import LogoWhite from '../../styles/assets/images/logoWhite';
import {Link} from "react-router-dom";

export class Login extends Component {
  state = {
    email: '',
    password: '',
    status: window.localStorage.token
  };

  validateForm = () => {
    return this.state.email.length > 0 && this.state.password.length > 0;
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleSubmit = event => {
    var shareToken = this.props.match.params.token;

    var data = {
      email: this.state.email,
      password: this.state.password
    };

    axios.defaults.withCredentials = true;
    axios.defaults.crossDomain = true;

    axios
      // What was the reason of adding this header here??? @BK?!
      // .post('/api/password-login', {
      //   headers: {
      //     'Access-Control-Allow-Credentials': true
      //   },
      //   data
      // })
      .post('/api/password-login',data)
      .then(resp => {
        var token = resp.data.data.access_token;
        if (!token) {
          return;
        }
        this.props.dispatch(authSuccess(token));
        this.props.dispatch(fetchCategoriesData());

        if (shareToken) {
          this.props.history.goBack()
        } else {
          this.props.history.push('/search?orderby=date&order=ASC');
        }
      })
      .catch(err => {
        alert('The username or password you have entered is incorrect.');
        console.log(err);
      });

    event.preventDefault();
  };

  render() {
    return (
      <div className="page-login">
        <div className="header" ref="header">
        {this.state.status ? (
          <a className="header-home-link" href="/search?orderby=date&order=ASC" >
            <LogoWhite />
          </a>
        ) : (
          <a className="header-home-link" href="/" >
            <LogoWhite />
          </a>
        )}
          {
            this.props.onRequestClose ?
            <IconButton 
              onClick={this.props.onRequestClose}
              className="modal-close"
              icon="close"
              iconColor="dark-grey" 
              btnColor="transparent" 
            /> : null
          }
        </div>

        <div className="login-wrapper">
        <div className="container content">
          <div className="login-title">
            <h1>Login</h1>
          </div>
          <div className="login container content">
            <form onSubmit={this.handleSubmit}>

            <div className="row">
              <div className="col-md-4">
                <p className="form-label">Email</p>
              </div>
              <div className="col-md-8">
                <input
                  autoFocus={true}
                  name="email"
                  type="email"
                  onChange={this.handleChange}
                  value={this.state.email}
                  />
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <p className="form-label">Password</p>
              </div>
              <div className="col-md-8">
                <input
                  name="password"
                  type="password"
                  onChange={this.handleChange}
                  value={this.state.password}
                  />
              </div>
            </div>
              <button className="pull-right save-button" disabled={!this.validateForm()} type="submit">
                Login
              </button>
            </form>

          </div>
          </div>
        </div>
        <footer className="footer">
          <Link to="/terms">Terms of use</Link>
          <Link to="/privacy">privacy policy</Link>
        </footer>
      </div>
    );
  }
}

function mapStateToProperties(state) {
  return {};
}

export default connect(mapStateToProperties)(Login);

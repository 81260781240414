import { axios } from '../lib/axios';
import {
  FETCH_MULTIPLE_MARKIVES_URL_BEGIN,
  FETCH_MULTIPLE_MARKIVES_URL_SUCCESS,
  FETCH_MULTIPLE_MARKIVES_URL_FAILURE,
  FETCH_MULTIPLE_MARKIVES_TOKEN_BEGIN,
  FETCH_MULTIPLE_MARKIVES_TOKEN_SUCCESS,
  FETCH_MULTIPLE_MARKIVES_TOKEN_FAILURE,
  ADD_SHARED_MARKIVE,
  ADD_ALL_SHARED_MARKIVES,
  DELETE_SHARED_MARKIVE,
  DELETE_ALL_SHARED_MARKIVES,
  TOGGLE_MARKIVES_CHECKBOXES,
} from "../constants/action-types";

// Handling the uses ability to add Markives that were shared to them
// to their account
export function toggleMarkivesCheckboxes() {
  return {
    type: TOGGLE_MARKIVES_CHECKBOXES,
  };
}

export function addAllSharedMarkives(value) {
  return {
    type: ADD_ALL_SHARED_MARKIVES,
    value,
  };
}


export function deleteAllSharedMarkives() {
  return {
    type: DELETE_ALL_SHARED_MARKIVES
  };
}

export default {
  addAllSharedMarkives,
  deleteAllSharedMarkives,
  toggleMarkivesCheckboxes,
};

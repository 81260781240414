import React from 'react';
import svgStore from '../styles/assets/images/store.svg';

/* 
  props: {
    id,
    color
  }
*/

const SVGIcon = (props) => (
  <svg className={`svg-icon svg-icon--${props.id} ${props.color || 'white'}`}>
    <use xlinkHref={`${location.origin}${svgStore}#${props.id}`}></use>
  </svg>
);

export default SVGIcon;
import { axios } from '../lib/axios';
import {
  FETCH_CATEGORIES_BEGIN,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAILURE,

  FETCH_YEARS_BEGIN,
  FETCH_YEARS_SUCCESS,
  FETCH_YEARS_FAILURE,

  FETCH_PEOPLE_BEGIN,
  FETCH_PEOPLE_SUCCESS,
  FETCH_PEOPLE_FAILURE,

  FETCH_SUPERTAGS_BEGIN,
  FETCH_SUPERTAGS_SUCCESS,
  FETCH_SUPERTAGS_FAILURE,

  FETCH_TAGS_BEGIN,
  FETCH_TAGS_SUCCESS,
  FETCH_TAGS_FAILURE,

  FETCH_LOCATIONS_BEGIN,
  FETCH_LOCATIONS_SUCCESS,
  FETCH_LOCATIONS_FAILURE,
} from "../constants/action-types";

// CATEGORIES FETCH DATA
export function fetchCategoriesData() {
  return dispatch => {
    dispatch(fetchCategoriesDataBegin());

    axios
      .get("/api/categories/")
      .then(res => {
        dispatch(fetchCategoriesDataSuccess(res.data.data));
      })
      .catch(err => {
        dispatch(fetchCategoriesDataFailure(err));
      });
  };
}

export function fetchCategoriesDataBegin() {
  return {
    type: FETCH_CATEGORIES_BEGIN,
  };
}
export function fetchCategoriesDataSuccess(categories) {
  return {
    type: FETCH_CATEGORIES_SUCCESS,
    categories,
  };
}
export function fetchCategoriesDataFailure(error) {
  return {
    type: FETCH_CATEGORIES_FAILURE,
    error: error,
  };
}

// YEARS FETCH DATA
export function fetchYearsData() {
  return dispatch => {
    dispatch(fetchYearsDataBegin());

    axios
      .get("/api/years/")
      .then(res => {
        dispatch(fetchYearsDataSuccess(res.data.data));
      })
      .catch(err => {
        dispatch(fetchYearsDataFailure(err));
      });
  };
}

export function fetchYearsDataBegin() {
  return {
    type: FETCH_YEARS_BEGIN,
  };
}
export function fetchYearsDataSuccess(years) {
  return {
    type: FETCH_YEARS_SUCCESS,
    years,
  };
}
export function fetchYearsDataFailure(error) {
  return {
    type: FETCH_YEARS_FAILURE,
    error: error,
  };
}

// LOCATIONS FETCH DATA
export function fetchLocationsData() {
  return dispatch => {
    dispatch(fetchLocationsDataBegin());

    axios
      .get("/api/locations/")
      .then(res => {
        dispatch(fetchLocationsDataSuccess(res.data.data));
      })
      .catch(err => {
        dispatch(fetchLocationsDataFailure(err));
      });
  };
}

export function fetchLocationsDataBegin() {
  return {
    type: FETCH_LOCATIONS_BEGIN,
  };
}
export function fetchLocationsDataSuccess(locations) {
  return {
    type: FETCH_LOCATIONS_SUCCESS,
    locations,
  };
}
export function fetchLocationsDataFailure(error) {
  return {
    type: FETCH_LOCATIONS_FAILURE,
    error: error,
  };
}

// SUPERTAG FETCH DATA
export function fetchSuperTagsData() {
  return dispatch => {
    dispatch(fetchSuperTagsDataBegin());

    axios
      .get("/api/supertags/")
      .then(res => {
        console.log(res)
        dispatch(fetchSuperTagsDataSuccess(res.data.data));
      })
      .catch(err => {
        console.log(err)
        dispatch(fetchSuperTagsDataFailure(err));
      });
  };
}

export function fetchSuperTagsDataBegin() {
  return {
    type: FETCH_SUPERTAGS_BEGIN,
  };
}
export function fetchSuperTagsDataSuccess(superTags) {
  return {
    type: FETCH_SUPERTAGS_SUCCESS,
    superTags,
  };
}
export function fetchSuperTagsDataFailure(error) {
  return {
    type: FETCH_SUPERTAGS_FAILURE,
    error: error,
  };
}

// PEOPLE FETCH DATA
export function fetchPeopleData() {
  return dispatch => {
    dispatch(fetchPeopleDataBegin());

    axios
      .get("/api/people/")
      .then(res => {
        dispatch(fetchPeopleDataSuccess(res.data.data));
      })
      .catch(err => {
        dispatch(fetchPeopleDataFailure(err));
      });
  };
}

export function fetchPeopleDataBegin() {
  return {
    type: FETCH_PEOPLE_BEGIN,
  };
}
export function fetchPeopleDataSuccess(people) {
  return {
    type: FETCH_PEOPLE_SUCCESS,
    people,
  };
}
export function fetchPeopleDataFailure(error) {
  return {
    type: FETCH_PEOPLE_FAILURE,
    error: error,
  };
}

// TAGS FETCH DATA
export function fetchTagsData() {
  return dispatch => {
    dispatch(fetchTagsDataBegin());

    axios
      .get("/api/tags/")
      .then(res => {
        dispatch(fetchTagsDataSuccess(res.data.data));
      })
      .catch(err => {
        dispatch(fetchTagsDataFailure(err));
      });
  };
}

export function fetchTagsDataBegin() {
  return {
    type: FETCH_TAGS_BEGIN,
  };
}
export function fetchTagsDataSuccess(tags) {
  return {
    type: FETCH_TAGS_SUCCESS,
    tags,
  };
}
export function fetchTagsDataFailure(error) {
  return {
    type: FETCH_TAGS_FAILURE,
    error: error,
  };
}

export default {
  fetchCategoriesData,
  fetchYearsData,
  fetchPeopleData,
  fetchSuperTagsData,
  fetchTagsData,
  fetchLocationsData,
};

import {
  AUTO_COMPLETE_CHANGE,
  AUTO_COMPLETE_LNG,
  AUTO_COMPLETE_LAT,
  QUICK_FIND,
  DELETE_IMAGE_BEGIN,
  DELETE_VIDEO_BEGIN,
  DELETE_IMAGE_SUCCESS,
  DELETE_VIDEO_SUCCESS,
  FETCH_MARKIVE_BEGIN,
  FETCH_MARKIVE_SUCCESS,
  FETCH_MARKIVE_FAILURE,
  HANDLE_CHANGE,
  HANDLE_ADDITION,
  HANDLE_CHANGE_IN_ARRAY,
  HANDLE_THUMBNAIL_IMAGE,
  UPDATE_IMAGE_BEGIN,
  UPDATE_VIDEO_BEGIN,
  UPDATE_IMAGE_SUCCESS,
  UPDATE_VIDEO_SUCCESS,
  HANDLE_DELETE,
  HANDLE_DELETE_IMAGE,
  HANDLE_DELETE_VIDEO,
  HANDLE_DRAG,
  HANDLE_IMAGE,
  HANDLE_VIDEO,
  HANDLE_IMAGE_SORTING,
} from '../constants/action-types';

const initialState = {
  address: '',
  categories: [],
  date: '',
  deleteBucket: [],
  deleteVideoBucket: [],
  updateBucket: [],
  error: null,
  images: [],
  items: '',
  lat: '',
  lng: '',
  quickFind: '',
  loading: false,
  notes: '',
  people: [],
  tags: [],
  superTags: [],
  title: '',
  url: '',
  videos: [],
};

export default function markiveData(state = initialState, action) {
  switch (action.type) {
    case AUTO_COMPLETE_CHANGE:
      return {
        ...state,
        address: action.address,
      };
    case AUTO_COMPLETE_LAT:
      return {
        ...state,
        lat: action.lat,
      };
    case AUTO_COMPLETE_LNG:
      return {
        ...state,
        lng: action.lng,
      };
    case QUICK_FIND:
      return {
        ...state,
        quickFind: action.quickFind,
      };
   case UPDATE_IMAGE_BEGIN:
   case UPDATE_IMAGE_SUCCESS:
    return {
      ...state,
    };

    case HANDLE_IMAGE_SORTING:
      return {
        ...state,
        images: action.images,
      };

    case UPDATE_VIDEO_BEGIN:
    case UPDATE_VIDEO_SUCCESS:
    return {
      ...state,
    };

    case DELETE_IMAGE_BEGIN:
    case DELETE_IMAGE_SUCCESS:
      return {
        ...state,
      };

    case DELETE_VIDEO_BEGIN:
    case DELETE_VIDEO_SUCCESS:
      return {
        ...state,
      };
    case FETCH_MARKIVE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_MARKIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        date: action.markive.date,
        address: action.markive.address,
        title: action.markive.title,
        notes: action.markive.notes,
        url: action.markive.url,
        categories: action.markive.categories.map(i => ({id: i.id.toString(), name: i.name})),
        tags: action.markive.tags.map(i => ({id: i.id.toString(), name: i.name})),
        people: action.markive.people.map(i => ({id: i.id.toString(), name: i.name})),
        superTags: action.markive.supertag.map(i => ({id: i.id.toString(), name: i.name})),
        images: action.markive.images.sort((a,b) => b.is_thumbnail * 1 - a.is_thumbnail * 1),
        videos: action.markive.videos,
        lat: action.markive.latitude,
        lng: action.markive.longitude,
      };
    case FETCH_MARKIVE_FAILURE:
      return {
        ...state,
        loading: false,
        items: action.error,
      };
    case HANDLE_ADDITION:
    // THERE IS A SPECIFIC WAY TO PUSH ELEMENTS INTO AN ARRAY HERE... CANNOT USE JUST PUSH HERE
      if(action.tag) {
        return {
          ...state,
          [action.name]: [...state[action.name], action.tag]
        }
      }
    case HANDLE_CHANGE_IN_ARRAY:
        if(action.tag) {
          return {
            ...state,
            [action.name]: [action.tag]
          }
        }
    case HANDLE_CHANGE:
      return {
        ...state,
        [action.field]: action.value,
      }
    case HANDLE_THUMBNAIL_IMAGE:
      var updateBucket = [];
      var images = [...state.images]
      images.map((image, i) => {
        // console.log('---Start---')
        // console.log(action.index)
        // console.log(image)
        // console.log(i)
        // console.log('---End---')
          if (image.is_thumbnail) {
            image.is_thumbnail = 0;
            updateBucket.push(image);
          }
          if (i === action.index) {
            image.is_thumbnail = 1;
            updateBucket.push(image);
          }
      });
      return {
        ...state,
        images: images,
        updateBucket: updateBucket,
      }
    case HANDLE_DELETE:
      return {
        ...state,
        ...state[action.name].splice(action.i, 1)
      }
    case HANDLE_DELETE_IMAGE:
      var images = state.images
      var deletedImg = images.splice(action.index, 1);
      images = JSON.parse(JSON.stringify(images));
      const newDeleteBucket = [...state.deleteBucket];
      if (deletedImg[0].image_id) {
        newDeleteBucket.push(deletedImg[0].image_id)
      }
      return {
        ...state,
        images: images,
        deleteBucket: newDeleteBucket
      }

    case HANDLE_DELETE_VIDEO:
      var videos = state.videos;
      var deletedVideos = videos.filter(obj => obj.video_id == action.index);
      var videos = videos.filter(obj => obj.video_id !== action.index);
      videos = JSON.parse(JSON.stringify(videos));
      const newDeleteVideoBucket = [...state.deleteVideoBucket];
      if (deletedVideos[0].video_id) {
        newDeleteVideoBucket.push(deletedVideos[0])
      }
      return {
        ...state,
        videos: videos,
        deleteVideoBucket: newDeleteVideoBucket
      }
    case HANDLE_DRAG:
    // let tags = this.state[name];
    // tags.splice(currPos, 1);
    // tags.splice(newPos, 0, tag);
    // this.setState({ [name]: tags });

    // THIS DOESN'T SEEM TO WORK.. LETS COME BACK TO IT LATER SINCE ITS NOT PRIORITY...
      return {
        ...state,
        ...state[action.name].splice(action.currPos, 1),
        ...state[action.name].splice(action.newPos, 0, action.tag),
      }
    case HANDLE_IMAGE:
      return {
        ...state,
        images: [...state.images, {file: action.file, url: action.data}],
      }
    case HANDLE_VIDEO:
      return {
        ...state,
        images: [...state.images, {file: action.file, url: action.data}],
      }
    default:
      return state;
  }
}

import {
  QUICK_FIND,
} from "../constants/action-types";

// This is to handle address state change
export function quickFindChange(quickFind) {
  return {
    type: QUICK_FIND,
    quickFind
  };
}

export default {
  quickFindChange,
};

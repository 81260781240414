import React from 'react';
import IconButton from "../components/IconButton";
import useWindowDimensions from '../lib/useWindowDimensions';

/* 
  props: {
    infoHandler
    deleteHandler,
    editHandler,
    shareHandler,
    addHandler,
    infoIndex,
    isSharePage,
    hideNotes,
    hideTags
  }
*/


const MarkiveActionMenu = (props) => {
  const { height, width } = useWindowDimensions();
  return (
    <div className="markive-action-menu">
      <div className="info-dots-wrap" data-info-index={props.infoIndex || 0}>
        {
          props.hideNotes || props.hideTags ? null :
          <>
            <div className="info-dot" onClick={() => {if(props.infoIndex != 0) props.infoHandler()}}></div>
            <div className="info-dot" onClick={() => {if(props.infoIndex != 1) props.infoHandler()}}></div>
          </>
        }
      </div>
      <div className="action-btns">
        {
          !props.isSharePage ?
          <>
            <IconButton onClick={props.deleteHandler} icon="trash" tooltip="Delete" hoverColor="blue" outline={width < 768} outlineColor="grey" />
            <IconButton onClick={props.editHandler} icon="edit" tooltip="Edit" hoverColor="blue" outline={width < 768} outlineColor="grey"/>
          </> : null
        }
        {
          props.isSharePage ?
          <>
            <IconButton onClick={props.addHandler} icon="roundplus" tooltip="Add"  hoverColor="blue" outline={width < 768} outlineColor="grey" />
          </> : null
        }
        
        <IconButton onClick={props.shareHandler} icon="share" tooltip="Share"  hoverColor="blue" outline={width < 768} outlineColor="grey" />
      </div>
    </div>
  )
};


export default MarkiveActionMenu;
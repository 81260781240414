// Create final store using all reducers and applying middleware
import { createBrowserHistory } from 'history';
import { createLogger } from 'redux-logger';
import * as reducers from '../reducers';
import thunk from 'redux-thunk';
// Redux utility functions
import {
  compose,
  createStore,
  combineReducers,
  applyMiddleware
} from 'redux';

import {
  routerMiddleware,
  connectRouter
} from 'connected-react-router';

const logger = createLogger({
  collapsed: (getState, action, logEntry) => !logEntry.error
});

// Configure reducer to store state at state.router
// You can store it elsewhere by specifying a custom `routerStateSelector`
// in the store enhancer below
export const history = createBrowserHistory();
const reducer = combineReducers({ ...reducers });
const middlewares = [routerMiddleware(history)];
if (process.env.NODE_ENV !== 'production') {
  middlewares.push(logger);
}

const store = compose(
  // Enables your middleware:
  applyMiddleware(...middlewares, thunk), // any Redux middleware, e.g. redux-thunk
  // applyMiddleware(...middlewares),
  // Provides support for DevTools via Chrome extension
  window.devToolsExtension ? window.devToolsExtension() : f => f
)(createStore)(connectRouter(history)(reducer));

export default store;

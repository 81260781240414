import { axios } from '../lib/axios';

export async function searchMarkive(data) {
  let results = await axios
    .post('/api/search-markives', data)
    .then(async resp => {
      if(resp && (resp.data.status == 200)) {
        return resp.data.data;
      } else {
        delete localStorage.token;
        alert('Your session has expired, please log in again.');
        window.location.href = "/password-login";
      }
    })
    .catch(err => {
      console.error(err)
    })
    return results;
}

import { axios } from '../lib/axios';

export async function postNewCategory(category) {
  var accessToken = window.localStorage.token;
  let categoryId = await axios
    .post('/api/categories', category)
    .then(async resp => {
      return resp.data.data.id;
    });
  return categoryId;
}

export async function linkCategoryToMarkive(categoryData) {
  await axios.post('/api/categories/update', categoryData);
}

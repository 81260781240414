import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import moment from 'moment';
import PrivateImage from './PrivateImage';

/* 
  props: {
    token,
    markives,
    onToggleLightBox,
    featureBlock,
    showLinkedMarkiveLightBox,
    hideLinkedMarkiveLightBox,
    currentSlide
  }
*/

const LinkedMarkiveSlider = (props) => {
  const [index, setIndex] = useState(0);
  const [length, setLength] = useState(0);
  const [markives, setMarkives] = useState();
  const [drag, setDrag] = useState(false);
  const [dragX, setDragX] = useState('0%');
  const handlers = useSwipeable({
    onSwiping: (e) => update(e),
    onSwipedLeft: () => forward(),
    onSwipedRight: () => backward(),
    onSwiped: () => setDrag(false),
    onTap: (e) => {
      /* ignore MouseEvent */
      if (e.event.type === 'mouseup') return;
      if(e.event.target.tagName === 'A' || e.event.target.tagName === 'BUTTON' || e.event.target.onclick) e.event.target.click();
      e.event.preventDefault();
    },
    delta: 20,
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
    trackMouse: true
  });

  
  useEffect(() => {
    if (props.markives) {
      setLength(props.markives.length);
      setMarkives(props.markives);
      setIndex(props.currentSlide);
    }
  }, [props.markives, props.currentSlide])

  const forward = () => {
    if (index < length-1) setIndex(index + 1); 
  }

  const backward = () => {
    if (index > 0) setIndex(index - 1);
  }
  
  const update = (e) => {
    if (!drag) setDrag(true);
    setDragX(`calc(${index * (-100 / length)}% + ${e.deltaX}px)`);
  }

  return (
    <div className={`slider-outer-wrap${props.featureBlock ? ' slider-outer-wrap-100' : ''}`} {...handlers} data-grabbing={drag}>
      <div className="slider-wrap">
        <div className="slider" data-drag={drag} style={{
            minWidth: 100 * length + '%',
            transform: `translateX(${drag ? dragX : (index * (-100 / length) + '%')})`
          }}>
          {
            markives && length ?
            markives.map((obj, i) => {
              return (
                <div key={i} className={'linked-markive-div'}>
                  <div className="content">
                    <div className="top-content">
                      <Link to={{pathname: `/share/${props.token}/${obj.id}`,}} onClick={() => {props.hideLinkedMarkiveLightBox();}}>
                        <h1 className="heading" id="heading">{obj.title}</h1>
                      </Link>
                      <div className="subhead-date-wrap">
                        <a onClick={() => {}} className="subheading" title="Open map" data-title="Open map"><h2>{obj.address}</h2></a>
                        {obj.date ?
                          (
                            <>
                              <p className="markive-date markive-date--m">{moment(obj.date.split('T')[0]).format('MM/D/YY')}</p>
                              <p className="markive-date markive-date--d">{moment(obj.date.split('T')[0]).format('MMMM Do, YYYY')}</p>
                            </>
                          ) :
                          <div></div>
                        }
                      </div>
                    </div>
                    <PrivateImage className="featured-image" url={obj.images.length ? obj.images[0].url : ''} />
                  </div>
                </div>
              )
            }) : null
          }
        </div>
      </div>
      {
        !props.featureBlock && 
        <div className="slider-controls" data-length={length} >
          <button onClick={backward} disabled={!length || index === 0}>prev</button>
          <button onClick={forward} disabled={!length || index === length-1}>next</button>
        </div>
      }
      {
        !props.featureBlock && 
        <div className="slider-pagination">{index+1}/{length}</div>
      }
     
    </div>
  )
}

export default LinkedMarkiveSlider;
import { axios } from '../lib/axios';

export async function postNewMarkive(data) {
    // Because REQUESTS take a long time...
    document.getElementById("create-markive-button").setAttribute("disabled", "disabled");

    let markiveId = await axios
    .post('/api/create-markive', data)
    .then(async resp => {
      if(resp && resp.data && resp.data.data) {
        return resp.data.data.id;
      }
    })
    .catch(err => {
      console.log(err);
    });
  return markiveId;
}

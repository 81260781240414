class PrivateImages {
  constructor() {
    this.images = {};
    const instance = this.constructor.instance;
    if (instance) {
      return instance;
    }
    this.instance = this;
  }

  fetchImage(url) {
    let token = window.localStorage.getItem('token');
    return fetch(url, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => {
        if (response.ok) {
          return response.blob();
        }
        throw new Error('Error getting image boo');
      })
      .then((blob) => {
        let objectURL = URL.createObjectURL(blob);

        // overwrite promise object with actual value just because
        this.images[this.hashCode(url)] = objectURL;
        return objectURL;
      });

  }

  hashCode(s) {
    if (!s) return 0;
    let h = 0, l = s.length, i = 0;
    if ( l > 0 )
      while (i < l)
        h = (h << 5) - h + s.charCodeAt(i++) | 0;
    return h;
  }

  getObjectURL(url) {
    const hash = this.hashCode(url);
    if (!Object.prototype.hasOwnProperty.call(this.images, hash)) {
      // Save promise object so that the next call does not trigger an extra request
      this.images[hash] = this.fetchImage(url);
    }
    return this.images[hash];
  }

}

export const images = new PrivateImages();

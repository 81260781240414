import React, { useEffect, useState } from 'react';
import {Video} from "cloudinary-react";
import { useSwipeable } from 'react-swipeable';
/* 
  props: {
    videos,
    cloudName
    onToggleLightBox,
    featureBlock,
    showVideoLightBox
  }
*/

const MarkiveVideoSlider = (props) => {
  const [index, setIndex] = useState(0);
  const [length, setLength] = useState(0);
  const [videos, setVideos] = useState();
  const [drag, setDrag] = useState(false);
  const [dragX, setDragX] = useState('0%');
  const handlers = useSwipeable({
    onSwiping: (e) => update(e),
    onSwipedLeft: () => forward(),
    onSwipedRight: () => backward(),
    onSwiped: () => setDrag(false),
    onTap: (e) => {
      /* ignore MouseEvent */
      if (e.event.type === 'mouseup') return;
      if(e.event.target.tagName === 'A' || e.event.target.tagName === 'BUTTON' || e.event.target.onclick) e.event.target.click();
      e.event.preventDefault();
    },
    delta: 20,
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
    trackMouse: true
  });

  
  useEffect(() => {
    if (props.videos) {
      setLength(props.videos.length);
      setVideos(props.videos);
    }
  }, [props.videos])

  const forward = () => {
    if (index < length-1) setIndex(index + 1); 
  }

  const backward = () => {
    if (index > 0) setIndex(index - 1);
  }
  
  const update = (e) => {
    if (!drag) setDrag(true);
    setDragX(`calc(${index * (-100 / length)}% + ${e.deltaX}px)`);
  }

  return (
    <div className={`slider-outer-wrap${props.featureBlock ? ' slider-outer-wrap-100' : ''}`} {...handlers} data-grabbing={drag}>
      <div className="slider-wrap">
        <div className="slider" data-drag={drag} style={{
            minWidth: 100 * length + '%',
            transform: `translateX(${drag ? dragX : (index * (-100 / length) + '%')})`
          }}>
          {
            videos && length ?
            videos.map((obj, i) => {
              return (
                <div key={i} className={'video-div'}>
                  <Video
                    publicId={obj.cloud_key}
                    cloudName={props.cloudName}
                    controls={false}
                    width={600}
                    autoPlay={i===index && props.showVideoLightBox}
                    playsInline={true}
                  />
                </div>
                
              )
            }) : null
          }
        </div>
      </div>
      {
        !props.featureBlock && 
        <div className="slider-controls" data-length={length} >
          <button onClick={backward} disabled={!length || index === 0}>prev</button>
          <button onClick={forward} disabled={!length || index === length-1}>next</button>
        </div>
      }
      {
        !props.featureBlock && 
        <div className="slider-pagination">{index+1}/{length}</div>
      }
     
    </div>
  )
}

export default MarkiveVideoSlider;
import {
  APP_LOAD,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  SHOW_MODAL,
  SHOW_LOAD,
  SHOW_LOAD_IN_BACKGROUND,
  HIDE_LOAD,
  DATE_INPUT,
  LOCATE_BEGIN,
  LOCATE_FAILURE,
  LOCATE_SUCCESS, UPDATE_MODAL_MARKIVE,
} from "../constants/action-types";
import { axios } from '../lib/axios';

// STUFF THAT IS USED GLOBALLY
export function loadApp() {
  return {
    type: APP_LOAD
  };
}

export function locate() {
  return (dispatch) => new Promise((resolveWholeThing, rejectWholeThing) => {
    dispatch({
      type: LOCATE_BEGIN,
      payload: null,
    });

    if ('geolocation' in navigator) {
      new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      })
        .then(position => {
          dispatch({
            type: LOCATE_SUCCESS,
            payload: position.coords,
          });
          resolveWholeThing(position.coords);
        })
        .catch(error => {
          dispatch({
            type: LOCATE_FAILURE,
            payload: error,
          });
          rejectWholeThing(error);
        })
    } else {
      dispatch({
        type: LOCATE_FAILURE,
        payload: null,
      });
      rejectWholeThing();
    }
  });
}

// AUTH
export function authSuccess(token) {
  window.localStorage.setItem("token", token);
  axios.token = token;
  return {
    type: AUTH_SUCCESS,
    token
  };
}

export function authLogout() {
  window.localStorage.removeItem("token");
  window.location.href = window.location.origin;
  return {
    type: AUTH_LOGOUT
  };
}

// MODAL
export function showModal(type) {
  return {
    type: SHOW_MODAL,
    payload: type
  };
}

export function updateModalMarkive(markiveId) {
  return {
    type: UPDATE_MODAL_MARKIVE,
    payload: markiveId,
  }
}

export function showLoad(background) {
  return {
    type: background ? SHOW_LOAD_IN_BACKGROUND : SHOW_LOAD,
  };
}
export function hideLoad(background) {
  return {
    type: HIDE_LOAD,
  };
}

// DATE PLACEHOLDER
export function datePlaceholder(input) {
  return {
    type: DATE_INPUT,
    date: input
  };
}

export default {
  loadApp,
  authSuccess,
  authLogout,
  showModal,
  datePlaceholder,
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import FindMarkiveButton from '../../components/FindMarkiveButton';
import AddMarkiveButton from '../../components/AddMarkiveButton';
import LogoWhite from '../../styles/assets/images/logoWhite';
import DashboardHeader from '../../components/DashboardHeader';
import { showModal } from '../../actions/app';


class Dashboard extends Component {
  state = {
    token: window.localStorage.token
  };

  showModal = (type) => {
    this.props.dispatch(showModal(type));
  };

  render() {
    return (
      <div className="page-dashboard">
      <DashboardHeader />
        <div className="dashboard-container">
        {this.state.token ? (
          <a className="dashboard-home-button" href='/search?orderby=date&order=ASC'>
            <LogoWhite/>
          </a>
          ) : (
          <a href='/'>
            <LogoWhite />
          </a>
        )}
          <p className="logo-subtitle">archive your life</p>
          <AddMarkiveButton handleClick={this.showModal.bind(this, 'createMarkiveModal')} />
          <FindMarkiveButton className={"dashboard-action-button"} handleClick={() => {
            this.props.history.push('/search?orderby=date&order=ASC');
          }} />
        </div>

        {this.state.token && (
          <img
            className="base-tracking-pixel"
            src={`${API_URL}/users/cookie.gif?token=` + this.state.token}
          />
        )}
      </div>
    );
  }
}

function mapStateToProperties(state) {
  return {
    isModalVisible: state.app.isModalVisible,
    modalType: state.app.modalType,
  };
}

export default connect(mapStateToProperties)(Dashboard);

import React, { Component } from 'react';

class MapComponent extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    this.initMap();
  };

  shouldComponentUpdate = nextProps => {
    return !(this.props.mapLat === nextProps.mapLat && this.props.mapLng === nextProps.mapLng);
  };

  UNSAFE_componentWillUpdate = newProps => {
    var center = {
      lat: newProps.mapLat,
      lng: newProps.mapLng
    };

    var map = new google.maps.Map(document.getElementById('map'), {
      zoom: 16,
      center: center,
      fullscreenControl: false
    });

    var marker = new google.maps.Marker({
      position: center,
      map: map,
    });

    marker.addListener("click", () => {
      // More info on Google Maps:
      // https://gearside.com/easily-link-to-locations-and-directions-using-the-new-google-maps/
      // Destination - https://www.google.com/maps/dir/Current+Location/43.12345,-76.12345

      window.open(
        `https://www.google.com/maps/dir/Current+Location/${newProps.mapLat},${newProps.mapLng}`,
        '_blank'
      )
    });

    google.maps.event.trigger(map, 'resize');
  };

  initMap = () => {
    var center = { lat: 33.917396, lng: -118.407479 };

    var map = new google.maps.Map(document.getElementById('map'), {
      zoom: 11,
      center: center
    });
  };

  render() {
    return <div id="map" />;
  }
}

export default MapComponent;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { authSuccess } from '../../actions/app';
import axios, { post } from 'axios';
import GoogleLogin from 'react-google-login';
import LogoWhite from '../../styles/assets/images/logoWhite';
import IconButton from '../../components/IconButton';
import Modal from 'react-modal';
import LoginEmail from '../../components/LoginEmail';
import SignUpEmail from '../../components/SignUpEmail';
import Login from './Login';
import SignUp from './SignUp';
import GoogleIcon from '../../styles/assets/images/google-icon';
import {Link} from "react-router-dom";

Modal.setAppElement('#react');

const modalStyle = {
  content: {
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    padding: '0'
  }
};

class GoogleAuth extends Component {

  // Might consider modularizing this in Redux or something...
  // This might be everywhere for the AUTH
  state = {
    showModal: false,
    status: window.localStorage.token
  }

  showModal(type) {
    this.setState({
      showModal: type
    });
  }

  UNSAFE_componentWillMount() {
    if (this.props.location.pathname === '/login') {
      this.setState({
        showModal: 'login'
      });
    }
  }

  responseGoogle = response => {
    const url = `${API_URL}/users/login`;
    const formData = new FormData();
    formData.append('token', response.tokenId);
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    };
    post(url, formData, config)
      .then(resp => {
        var token = resp.data.data.access_token;
        if (!token) {
          return;
        }
        this.props.dispatch(authSuccess(token));
        this.props.dispatch(fetchCategoriesData());

        this.props.history.push('/search?orderby=date&order=ASC');
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    return (
      <div className="page-google-auth">
        <div className="header" ref="header">
        {this.state.status ? (
          <a className="header-home-link" href="/search?orderby=date&order=ASC" >
            <LogoWhite />
          </a>
        ) : (
          <a className="header-home-link" href="/" >
            <LogoWhite />
          </a>
        )}
          <IconButton 
            onClick={(e) => {
              if (this.props.onRequestClose) {
                this.props.onRequestClose(e);
              } else {
                window.history.back();
              }
            }}
            className="modal-close"
            icon="close"
            iconColor="dark-grey" 
            btnColor="transparent" 
          />
        </div>


          <div className="google-auth-wrapper">

          <div className="google-auth-login-wrapper">
            <div className="login-title">
              <h1>Login</h1>
            </div>
              <GoogleLogin
                clientId="391353654291-7rfql9vsn5is1caq3v1cv50cacjfntmh.apps.googleusercontent.com"
                onSuccess={this.responseGoogle}
                onFailure={this.responseGoogle}
                className="google-button"
              >
                <span className="icon d-none"><GoogleIcon /></span>
                <span className="buttonText">Login with Google</span>
              </GoogleLogin>
              <LoginEmail handleClick={this.showModal.bind(this, 'login')}/>
            </div>

            <div className="google-auth-divider"></div>

          <div className="google-auth-signup-wrapper">
            <div className="login-title">
              <h1>Sign Up</h1>
            </div>
              <GoogleLogin
                clientId="391353654291-7rfql9vsn5is1caq3v1cv50cacjfntmh.apps.googleusercontent.com"
                onSuccess={this.responseGoogle}
                onFailure={this.responseGoogle}
                className="google-button"
              >
                <span className="icon d-none"><GoogleIcon /></span>
                <span className="buttonText">Sign Up with Googlә</span>
              </GoogleLogin>
              <SignUpEmail handleClick={this.showModal.bind(this, 'signup')}/>
            </div>

              <Modal
                isOpen={this.state.showModal !== false}
                onRequestClose={this.showModal.bind(this, false)}
                style={modalStyle}
                contentLabel=""
              >
                {this.state.showModal === 'login' ? (
                  <Login {...this.props} onRequestClose={this.showModal.bind(this, false)} />
                ) : this.state.showModal === 'signup' ? (
                  <SignUp {...this.props} onRequestClose={this.showModal.bind(this, false)} />
                ) : null}
              </Modal>

          </div>
          <footer className="footer">
            <Link to="/terms">Terms of use</Link>
            <Link to="/privacy">privacy policy</Link>
          </footer>

        </div>
    );
  }
}

function mapStateToProperties(state) {
  return {};
}

export default connect(mapStateToProperties)(GoogleAuth);

import React from 'react';
import SVGIcon from './SVGIcon';

/* 
  props: {
    icon,
    iconColor,
    btnColor,
    hoverColor,
    tooltip,
    tooltipPos,
    onClick,
    outline,
    outlineColor,
    disabled
  }
*/

const IconButton = (props) => (
  <a className={`icon-btn icon-btn--${props.icon} 
      ${(props.disabled) ? 'icon-btn--disabled' : ''} 
      ${(props.outline) ? 'white border-'+props.outlineColor : props.btnColor || 'grey'} 
      ${props.hoverColor ? `hover-${props.hoverColor}` : ''} ${props.className || ''}`} 
    onClick={props.onClick}
    href={props.href}
    target={props.target}
  >
    <div 
      data-title={props.tooltip} 
      data-tooltip={props.tooltip ? true : false} 
      data-tooltip-pos={props.tooltipPos || 'top'}
    >
      <SVGIcon id={props.icon} color={(props.outline) ? props.outlineColor : props.iconColor}/>
    </div>
    {props.children}
  </a>
);

export default IconButton;

import 'babel-polyfill';
import React from 'react';
import { render } from 'react-dom';
import store from './store';
import Root from './routes';

render(
  <Root store={store}></Root>,
  document.getElementById('react')
);

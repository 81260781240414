import React, { Component } from 'react';
import { connect } from 'react-redux';
import GoogleLogin from 'react-google-login';
import Header from '../../components/Header';

class GoogleAuthSignUp extends Component {
  responseGoogle = response => {};

  render() {
    return (
      <div>
        <Header {...this.props} />
        <div className="google-auth-container">
          <div className="login-title">
            <h1>Sign Up</h1>
          </div>

          <GoogleLogin
            clientId="391353654291-7rfql9vsn5is1caq3v1cv50cacjfntmh.apps.googleusercontent.com"
            buttonText="Login using Google"
            onSuccess={this.responseGoogle}
            onFailure={this.responseGoogle}
          />

          <div className="login-sign-up">
            <p>
              Or, Sign Up with
              <span className="login-sign-up-text">
                <a href="/sign-up"> email</a>
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProperties(state) {
  return {};
}

export default connect(mapStateToProperties)(GoogleAuthSignUp);

import { axios } from '../lib/axios';
import request from 'superagent';

import {
  UPDATE_IMAGE_BEGIN,
  UPDATE_IMAGE_SUCCESS,
  DELETE_IMAGE_BEGIN,
  DELETE_VIDEO_BEGIN,
  DELETE_IMAGE_SUCCESS,
  DELETE_VIDEO_SUCCESS,
  HANDLE_ADDITION,
  HANDLE_CHANGE,
  HANDLE_CHANGE_IN_ARRAY,
  HANDLE_THUMBNAIL_IMAGE,
  HANDLE_DELETE,
  HANDLE_DELETE_IMAGE,
  HANDLE_DELETE_VIDEO,
  HANDLE_DRAG,
  HANDLE_IMAGE,
  HANDLE_IMAGE_SORTING
} from "../constants/action-types";

export function updateImageBegin() {
  return {
    type: UPDATE_IMAGE_BEGIN,
  }
}

export function updateImageSuccess() {
  return {
    type: UPDATE_IMAGE_SUCCESS,
  }
}

export function deleteImageBegin() {
  return {
    type: DELETE_IMAGE_BEGIN,
  }
}

export function deleteVideoBegin() {
  return {
    type: DELETE_VIDEO_BEGIN,
  }
}

export function deleteImageSuccess() {
  return {
    type: DELETE_IMAGE_SUCCESS,
  }
}

export function deleteVideoSuccess() {
  return {
    type: DELETE_VIDEO_SUCCESS,
  }
}

export function handleChange(field, value) {
  return {
    type: HANDLE_CHANGE,
    field,
    value,
  };
}

export function handleChangeInArray(name, tag) {
  return {
    type: HANDLE_CHANGE_IN_ARRAY,
    name,
    tag,
  };
}

export function handleAddition(name, tag) {
  return {
    type: HANDLE_ADDITION,
    name,
    tag,
  };
}

export function handleDelete(name, i) {
  return {
    type: HANDLE_DELETE,
    name,
    i,
  };
}

export function handleAssignImageThumbnail(index) {
  return {
    type: HANDLE_THUMBNAIL_IMAGE,
    index
  };
}

export function handleImageSorting(images) {
  return {
    type: HANDLE_IMAGE_SORTING,
    images
  };
}

export function handleDeleteImage(index) {
  return {
    type: HANDLE_DELETE_IMAGE,
    index
  };
}

export function handleDeleteVideo(index, cloud_key) {
  return {
    type: HANDLE_DELETE_VIDEO,
    index,
    cloud_key
  };
}

export function handleDrag(name, tag, currPos, newPos) {
  return {
    type: HANDLE_DRAG,
    name,
    tag,
    currPos,
    newPos,
  };
}

export function handleImage(file, data) {
  return {
    type: HANDLE_IMAGE,
    file,
    data,
  }
}

// Delete Image Action
export function submitDeleteImage(imageId) {
  return dispatch => {
    dispatch(deleteImageBegin());

     axios
      .delete('/api/delete-image/'+ imageId)
      .then(res => {
        if(res) {
          dispatch(deleteImageSuccess());
        }
      })
  }
}

// Delete Video Action
export function submitDeleteVideo(video) {
  return dispatch => {
    dispatch(deleteVideoBegin());

     axios
      .delete('/api/delete-video/'+ video.video_id)
      .then(res => {
        if(res) {
          dispatch(deleteVideoSuccess());
        }
      })
  }
}

export default {
  deleteImageBegin,
  deleteVideoBegin,
  deleteImageSuccess,
  deleteVideoSuccess,
  handleAddition,
  handleChange,
  handleDelete,
  handleDeleteImage,
  handleDeleteVideo,
  handleDrag,
  handleImage,
  submitDeleteImage,
  submitDeleteVideo
};

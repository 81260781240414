import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

class RouterWatcher extends Component {
  constructor(props) {
    super(props);
    this.pageHit(props.location);
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (location.pathname !== prevProps.location.pathname) {
      this.pageHit(location);
      window.scrollTo(0, 0);
    }
  }

  pageHit() {
    const { location } = this.props;

    if (process.env.NODE_ENV === 'production') {
    } else {
      console.log(`🐞 Debug page hit: ${location.pathname}${location.search}`); // eslint-disable-line no-console
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

RouterWatcher.propTypes = {
  location: PropTypes.object,
  children: PropTypes.any,
};

export default withRouter(RouterWatcher);

import React from 'react';

class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      isListOpen: false,
      isDesktop: false,
    };

    this.updatePredicate = this.updatePredicate.bind(this);
  }

  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 768 });
  }


  toggleList = () => {
    this.setState(prevState => ({
      isListOpen: !prevState.isListOpen
    }))
  };

  handleChange = e => {
    this.setState({ value: e.target.value });
  };

  isChecked = (item) => {
    if (this.props.activeList.indexOf(item) >= 0) {
      return true;
    }
    return false
  };

  handleSubmit = (item) => {
    this.setState({
      value: item,
      isListOpen: false,
    }, () => this.props.handleSubmit(this.state.value, this.props.filter));
    // this.props.handleSubmit(this.state.value, this.props.filter);
    // this.setState({ value: '' });
  };

  render() {
    const { isListOpen, value, isDesktop } = this.state;
    const { list, activeList } = this.props;

    const placeholder = isDesktop ? 'Select Year(s)' : 'When';

    return (
      <div className="filter-box">
        <span className="filter-label base-label-large">{this.props.label.toUpperCase()}</span>
        <div className="form-dropdown">
          <button
            className="btn btn-secondary dropdown-toggle"
            type="button"
            style={ activeList.length > 0 ? { color: '#5b5e60' } : {}}
            onClick={this.toggleList}>
            { activeList.length > 0 ?
              'Years: ' + activeList.join(", ") :
              placeholder
            }
          </button>
          {isListOpen && (
            <div className="dropdown-menu show" aria-labelledby="dropdownMenuButton">
              {list.map((item) => (
                <div
                  type="button"
                  className="dropdown-item"
                  key={item.period}
                  onClick={() => this.handleSubmit(item.period)}
                >
                  <div className="dropdown-item__name">
                    {item.period}
                    <span>{item.count}</span>
                  </div>
                  <label className="checkbox-container">
                    <input
                      type="checkbox"
                      checked={this.isChecked(item.period)}
                      onChange={() => this.handleSubmit(item.period)} />
                      <span className="checkmark"></span>
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default Dropdown;

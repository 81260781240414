import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { geocodeByAddress } from 'react-places-autocomplete';

var SearchEntry = props => {
  var date = props.date ? props.date.split('T')[0] : new Date();
  var dateString = moment(props.date).utc().format('MMMM D YYYY')
  var dateArr = dateString.split(' ');

  var imageUrl = ''
  if(props.images && props.images[0] && props.images[0].url) {
    imageUrl = props.thumbnail_url;
  } else {
    imageUrl = null;
  }

  const [country, setCountry] = useState('');
  const [city, setLocality] = useState('');
  const [state, setState] = useState('');
  const [geoDataReceived, setReceived] = useState(false);

  const ref = useRef();
  const onScreen = useOnScreen(ref, '300px');

  useEffect(() => {
    if (onScreen && !geoDataReceived && props.address) {
      geocodeByAddress(props.address)
      .then((results) => {
        setReceived(true)
        results[0].address_components.forEach((section) => {
          if (section.types.includes('country')) {
            if (section.short_name !== 'US') {
              setCountry(section.long_name);
            }
          } else if (section.types.includes('locality')) {
            setLocality(section.long_name);
          } else if (section.types.includes('administrative_area_level_1')) {
            setState(section.short_name);
          }
        });
      })
    }
  }, [onScreen]);

  const isInViewport = (offset = 0) => {
    if (!this.yourElement) return false;
    const top = this.yourElement.getBoundingClientRect().top;
    return (top + offset) >= 0 && (top - offset) <= window.innerHeight;
  }

  return (
    <div ref={ref} className="search-result-single">
      <Link to={{ pathname: '/markive/' + props.id, }}>
        <div className="row search-result-row">
          <div className="col-xs-3 search-result-col">
            <div className="search-result-box what" style={{backgroundImage: `url('${imageUrl}')`}}>
              <h2 className="search-result-text title">{props.title}</h2>
            </div>
          </div>
          <div className="col-xs-3 search-result-col">
            <div className="search-result-box where">
              <p className="search-result-text location">
                {[city, state, country].filter(Boolean).join(', ')}
              </p>
            </div>
          </div>
          <div className="col-xs-3 search-result-col">
            <div className="search-result-box when">
              <div className="search-result-text-container">
                <p className="search-result-text date">{dateArr[0]}</p>
                <p className="search-result-text date year">{dateArr[2]}</p>
              </div>
            </div>
          </div>
          <div className="col-xs-3 search-result-col">
            <div className="search-result-box who">
              <div className="search-result-text-container">
                {props.people.map((person, idx) => {
                  if(props.people && person && person.name) {
                    return(
                      <p key={idx} className="search-result-text people">{person.name}</p>
                    )
                  } else {
                    return(
                      <p></p>
                    )
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

function useOnScreen(ref, rootMargin = '0px') {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      observer.unobserve(ref.current);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return isIntersecting;
}

export default SearchEntry;

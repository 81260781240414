import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../components/Header';
import SearchEntry from '../../components/SearchEntry';
import SearchEntryCard from '../../components/SearchEntryCard';
import { quickFindChange } from "../../actions/quickFindAction";
import SVGIcon from '../../components/SVGIcon';
import {SHOW_LOAD, HIDE_LOAD} from "../../constants/action-types";
import { locate as locateAction, showLoad, hideLoad } from '../../actions/app';
import {axios} from "../../lib/axios";

import {
  toggleMarkivesCheckboxes,
  addAllSharedMarkives,
  deleteAllSharedMarkives,
} from '../../actions/addSharedMarkivesAction';

class MultipleShare extends Component {
  state = {
    activeSort: '',
    results: [],
    sortDescending: false,
    isSortListOpen: false,
    isViewListOpen: false,
    activeView: 'Cards',
    page: 0,
    stopScroll: false,
    orderby: 'name',
    order: '',
    latitude: '',
    longitude: '',
    token: '',
    prevY: 0,
    loading: false,
    totalLength: 0,
    username: '',
    error:  false
  };

  componentDidMount() {
    const urlQuery = new URLSearchParams(window.location.search);
    this.setState({
      orderby: urlQuery.getAll('orderby') || '',
      order: urlQuery.getAll('order') || '',
      page: 0,
      longitude: urlQuery.getAll('longitude') || '',
      latitude: urlQuery.getAll('latitude') || '',
    }, this.handleSubmit);

    var options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0
    };

    this.observer = new IntersectionObserver(
      this.handleObserver.bind(this),
      options
    );
    this.observer.observe(this.loadingRef);
  }

  handleObserver(entities, observer) {
    const y = entities[0].boundingClientRect.y;

    if (this.state.prevY > y && !this.state.loading) {
      this.setState({ loading: true });
      const page = parseInt(this.state.page) + 1;
      this.getPage(page);
    }
    this.setState({ prevY: y });
  }

  handleChange(event, notEvent=false) {
    if (notEvent) {
      // This accounts for pressing on an autocorrect btn. There's likely a better
      // implementation here.
      this.setState({ category: event });
      return true;
    }
    if(event.target.name) {
      this.setState({ [event.target.name]: event.target.value });
    }
  }

  sortBy = async (category) => {
    this.props.dispatch({
      type: SHOW_LOAD,
    });
    this.props.dispatch(quickFindChange(''));
    const {
      locate
    } = this.props;
    let orderby;

    if(category === "Proximity") {
      if (this.props.latitude) {
        this.setState({
          orderby: 'proximity',
          order: 'ASC',
          latitude: this.props.latitude,
          longitude: this.props.longitude,
          activeSort: 'Proximity',
          sortDescending: false,
          isSortListOpen: false,
          page: 0,
        }, this.handleSubmit);
      } else {
        await locate()
          .then(() => {
            this.setState({
              orderby: 'proximity',
              order: 'ASC',
              latitude: this.props.latitude,
              longitude: this.props.longitude,
              activeSort: 'Proximity',
              sortDescending: false,
              isSortListOpen: false,
              page: 0,
            }, this.handleSubmit);
          });
      }

    } else {
      if(category === "Name") {
        orderby = 'name';
      }
      if(category === "Time") {
        orderby = 'date';
      }
      this.setState({
        orderby: orderby,
        order: 'ASC',
        activeSort: category,
        sortDescending: false,
        isSortListOpen: false,
        page: 0,
      }, this.handleSubmit);
    }

    this.props.dispatch({
      type: HIDE_LOAD,
    });
  };

  reverseSort() {
    this.setState({
      sortDescending: !this.state.sortDescending,
      order: this.state.sortDescending ? 'ASC' : 'DESC',
      page: 0,
    }, this.handleSubmit);
  }

  toggleSortList = () => {
    this.setState(prevState => ({
      isSortListOpen: !prevState.isSortListOpen
    }))
  };

  toggleViewList = () => {
    this.setState(prevState => ({
      isViewListOpen: !prevState.isViewListOpen
    }))
  };

  updateView = (view) => {
    this.setState({
      activeView: view
    });
  };

  toggleAdd = () => {
    this.props.dispatch(toggleMarkivesCheckboxes());
  };

  addAllToShare = () => {
    const ids = this.state.results.map((item) => item.id);
    this.props.dispatch(addAllSharedMarkives(ids));
  }

  deleteAllFromShare = () => {
    this.props.dispatch(deleteAllSharedMarkives());
  }

  addMarkives = async () => {
    // event.preventDefault();
    var data = {
      // Reason for the lat and lng having the same value is because they are tied to the address...
      markives: this.props.cbArray,
    };
    this.props.dispatch(showLoad(true));
    if (window.localStorage.token) {
      axios
        .post(`/api/madd/${this.props.match.params.token}`, data)
        .then(async res => {
          if(res && res.data && res.data.data) {
            this.toggleAdd();
            this.props.dispatch(hideLoad());
            setTimeout(() => {
              alert("The selected Markives have been added to your feed!");
              this.props.history.push('/search?tag=Shared+with+me');
            }, 500);
          }
        })
        .catch(err => {
          alert("There was an issue adding these Markives at this time.")
        });
    } else {
      this.props.history.push(`/google-auth/${this.props.match.params.token ? this.props.match.params.token : ''}`);
    }
  };

  handleSubmit = async (data) => {
    var query = data || {
      page: this.state.page,
      orderby: this.state.orderby,
      order: this.state.order,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
    }

    const urlParams = new URLSearchParams();

    for (const [key, value] of Object.entries(query)) {
      if (value.length) {
        if (Array.isArray(value)) {
          value.forEach((val, i) => {
            if (i === 0) {
              urlParams.set(key, val);
            } else {
              urlParams.append(key, val);
            }
          });
        } else {
          urlParams.set(key, value);
        }
      }
    }
    let urlString = urlParams.toString();
    if (urlString) {
      urlString = `?${urlString}`;
    }

    window.history.replaceState({}, '', `${location.pathname}${urlString}`);

    // Uses new async version of Search
    this.props.dispatch({
      type: SHOW_LOAD,
    });

    var url = window.location.href;
    var arr = url.split("/");
    var token = arr[arr.length - 1];
    var path = ['/api/mshare', token].join('/');

    // var results = await fetchMultipleMarkivesUrl(token);
    axios
      .get(path, { headers: { token: token } })
      .then(resp => {
        var results = resp.data.data;
        let totalLength = 0;
        let username = 'A user';
        if (results.length !== 0) {
          totalLength = results[0].total_length;
          username = results[0].user;
        }

        if(results) {
          this.setState({
            results,
            totalLength,
            username,
          });
        }

        this.props.dispatch({
          type: HIDE_LOAD,
        });
      })
      .catch(err => {
        this.setState({
          error: true,
        });

        this.props.dispatch({
          type: HIDE_LOAD,
        });
        console.log(err);
      });
  }
  getPage = async (page) => {
    var query = {
      page: page,
      orderby: this.state.orderby,
      order: this.state.order,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
    }

    var url = window.location.href;
    var arr = url.split("/");
    var token = arr[arr.length - 1];

    var results = await fetchMultipleMarkivesUrl(token);
    if(results) {
      if (results.length > 0) {
        this.setState({
          results: [...this.state.results, ...results],
          loading: false,
          page: results[0].page
        });
      } else {
        this.setState({
          stopScroll: false,
          loading: false,
        });
      }
    }
  }
  render() {
    const loadingCSS = {
      height: "50px",
      margin: "30px",
      position: "absolute",
      bottom: '0',
      width: '50px',
      right: '0',
    };
    const loadingTextCSS = { display: this.state.loading ? "block" : "none" };
    return (
      <div className="page-search">
        <Header {...this.props} />
        <div className="search-results">
          <div className="search-container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h2 className="search-result-headline">
                  {!!this.state.username && (
                    `${this.state.username} shared ${this.state.totalLength} items`
                  )}
                  { !!this.state.error && ('There was an issue! This share link might not be valid.')}
                </h2>
                <a className="search-result-headline-share" onClick={() => this.toggleAdd()}>Add</a>
              </div>
              <div className="col-sm-6">
                <div className="search-filters">
                  <div>
                    <span className="base-label">SORT BY:</span>
                    <div className="sort-filter-button" onClick={() => {this.toggleSortList()}}>
                      {this.state.activeSort || 'Name'}
                      <svg style={{ transform: this.state.isSortListOpen ? 'rotate(180deg)': ''}}
                           width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.791687 8.375L7.50002 1.66667L14.2084 8.375" stroke="#212429" strokeWidth="2"/>
                      </svg>
                      <div className={`sort-filter-list ${this.state.isSortListOpen ? "show" : "hide"}`}>
                        <a onClick={() => this.sortBy('Time')} className={this.state.activeSort === 'Time' ? 'active' : undefined}>
                          <span>Time</span>
                          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 8.5L10 16.5L6 12.5" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                          </svg>
                        </a>
                        <hr/>
                        <a onClick={() => this.sortBy('Proximity')} className={this.state.activeSort === 'Proximity' ? 'active' : undefined}>
                          <span>Proximity</span>
                          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 8.5L10 16.5L6 12.5" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                          </svg>
                        </a>
                        <hr/>
                        <a onClick={() => this.sortBy('Name')} className={this.state.activeSort === 'Name' ? 'active' : undefined}>
                          <span>Name</span>
                          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 8.5L10 16.5L6 12.5" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                          </svg>
                        </a>
                      </div>
                    </div>
                    <a className="sort-filter-reverse" onClick={() => this.reverseSort()}>
                      <SVGIcon id="up-down" color={this.state.sortDescending ? 'blue' : 'dark-grey'} />
                    </a>
                  </div>
                  <div>
                    <span className="base-label">VIEW:</span>
                    <div className="sort-filter-button" onClick={() => {this.toggleViewList()}}>
                      {this.state.activeView}
                      <svg style={{ transform: this.state.isViewListOpen ? 'rotate(180deg)': ''}}
                           width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.791687 8.375L7.50002 1.66667L14.2084 8.375" stroke="#212429" strokeWidth="2"/>
                      </svg>
                      <div className={`sort-filter-list ${this.state.isViewListOpen ? "show" : "hide"}`}>
                        <a onClick={() => {this.updateView('Cards')}} className={this.state.activeView === 'Cards' ? 'active' : undefined}>
                          <span>Cards</span>
                          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 8.5L10 16.5L6 12.5" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                          </svg>
                        </a>
                        <hr/>
                        <a onClick={() => this.updateView('Rows')} className={this.state.activeView === 'Rows' ? 'active' : undefined}>
                          <span>Rows</span>
                          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 8.5L10 16.5L6 12.5" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                          </svg>
                        </a>
                        <hr/>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className={`row search-result-row search-result-row__header ${this.state.activeView === 'Rows' ? "show" : "hide"}`}>
              <div className="col-xs-3">
                <p className="search-result-labels">What</p>
              </div>
              <div className="col-xs-3">
                <p className="search-result-labels">Where</p>
              </div>
              <div className="col-xs-3">
                <p className="search-result-labels">When</p>
              </div>
              <div className="col-xs-3">
                <p className="search-result-labels">Who</p>
              </div>
            </div>
            <div className="results">
              {this.state.results ?
                (this.state.results.map((result, idx) => {
                  if (this.state.activeView === 'Cards' || window.innerWidth < 768) {
                    return <SearchEntryCard key={'search-result-' + result.id} {...result} row={(window.innerWidth < 768 && this.state.activeView !== 'Cards')}/>
                  }
                  return <SearchEntry key={'search-result-' + result.id} {...result} />
                }))
                :
                (<div>
                  <p>There is nothing to see here...</p>
                </div>)
              }
              { !this.state.stopScroll &&
              (<div
                ref={loadingRef => (this.loadingRef = loadingRef)}
                style={loadingCSS}
              >
                {/*<span style={loadingTextCSS}>Loading...</span>*/}
              </div>)
              }
            </div>
          </div>
        </div>
        {
          (this.props.showMarkivesCheckboxes) &&
          <div className="multiple-share-popup">
            <div className="multiple-share-popup__title">{this.props.cbArray.length} ITEMS TO ADD</div>
            {(this.props.cbArray.length > 0) ?
              (<a className="multiple-share-popup__toggle-button" onClick={() => {this.deleteAllFromShare()}}>Deselect All</a>)
              :
              (<a className="multiple-share-popup__toggle-button" onClick={() => {this.addAllToShare()}}>Select All</a>)
            }


            <a className="multiple-share-popup__share-button" onClick={() => {this.addMarkives()}}>
              <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-3">
                <circle cx="12.2969" cy="12.5" r="12" fill="#4294F4"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M12.3085 5.62012L6.69727 19.0601H8.76131L12.2676 10.3969L15.7241 19.0601H17.7893L12.3085 5.62012Z" fill="white"/>
              </svg>
              Add
            </a>
          </div>
        }
      </div>
    );
  }
}

function mapStateToProperties(state) {
  return {
    longitude: state.app.longitude,
    latitude: state.app.latitude,
    located: state.app.located,
    showMarkivesCheckboxes: state.shareMultipleMarkives.showMarkivesCheckboxes,
    cbArray: state.shareMultipleMarkives.cbArray,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    locate: () => dispatch(locateAction()),
    dispatch
  };
}

export default connect(
  mapStateToProperties,
  mapDispatchToProps,
)(MultipleShare);
